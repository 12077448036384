import React, { useState } from 'react';
import { loginUser } from '../api/securityApi';
import TwoFactVereficantionModal from '../components/modals/TwoFactVereficantionModal';
import ResetPasswordModal from '../components/modals/ResetPasswordModal';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import usePageTitle from '../hooks/usePageTitle';
import BlockedAccountModal from '../components/modals/BlockedAccountModal';
import { AccountType } from '../static/enums/accountTypeEmum';

const LoginPage = () => {
	usePageTitle('Log in to your account');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isResetPasswordModal, setIsResetPasswordModal] = useState(false);
	const [error, setError] = useState('');
	const [requires2FA, setRequires2FA] = useState(false);
	const [isBlockedModal, setIsBlockedModal] = useState(false);
	const [errors, setErrors] = useState({ email: false, password: false });
	const [userData, setUserData] = useState({
		accessToken: '',
		email: '',
		accountType: '',
	});
	const navigate = useNavigate();

	const resetPasswordModalHandler = () => {
		setIsResetPasswordModal(!isResetPasswordModal);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const newErrors = {
			email: !email,
			password: !password,
		};
		setErrors(newErrors);

		if (Object.values(newErrors).some((error) => error)) {
			setError('Please fill in all required fields.');
			return;
		}

		setError('');
		try {
			const result = await loginUser(email, password);

			if (result.successful) {
				const { requires2FA, accessToken, accountId, email, accountType } =
					result.data;

				setUserData({
					accountId,
					email,
					accountType,
				});

				if (requires2FA) {
					setRequires2FA(true);
				} else if (accessToken) {
					Cookies.set('accessToken', accessToken.value, {
						expires: 30,
						path: '/',
					});
					if (accountType === AccountType.Agent) {
						navigate('/agent/dashboard');
					} else if (accountType === AccountType.Admin) {
						navigate('/admin/dashboard');
					} else if (accountType === AccountType.Employee) {
						navigate('/employee/dashboard');
					}  else if (accountType === AccountType.Customer) {
						navigate('/customer/dashboard'); }
				}
			} else {
				const status = result.error?.status;
				if (status === 403) {
					setIsBlockedModal(true)
				} else {
					setError(result.error?.message || 'Login failed');
				}
			}
		} catch (error) {
			const errorMessage = error.response
				? error.response.data.error.message
				: error.message;

			setError(errorMessage || 'An unexpected error occurred.');
		}

		setErrors({ email: false, password: false });
	};

	return (
		<div className="font-poppins md:min-w-80">
			<div className="text-mc_purple text-4xl mb-2">LOGIN</div>
			<form onSubmit={handleSubmit} className="flex flex-col justify-between">
				<div>
					<input
						id="LoginEmail"
						name="email"
						placeholder="Email"
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className={`block w-full h-11 p-3 mb-5 font-normal rounded-[5px] border border-mc_light_grey ${
							errors.email || error
								? 'bg-red-500 bg-opacity-30'
								: 'bg-transparent'
						} outline-none text-base placeholder:text-black placeholder:text-base bg`}
					/>
					<input
						id="password"
						name="password"
						placeholder="Password"
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						className={`block w-full h-11 p-3 mb-24 font-normal rounded-[5px] border border-mc_light_grey ${
							errors.password || error
								? 'bg-red-500 bg-opacity-30'
								: 'bg-transparent'
						} outline-none text-base placeholder:text-black placeholder:text-base`}
					/>
					<div>
						<div className="text-red-500 text-center mb-3">{error}</div>
						<button className="w-full h-auto p-2 font-medium rounded-xl text-lg text-white outline-none bg-opacity-50 bg-mc_blue">
							LOGIN
						</button>
					</div>
				</div>
			</form>

			<TwoFactVereficantionModal
				userData={userData}
				isOpen={requires2FA}
				onClose={() => setRequires2FA(false)}
			/>
			<button
				onClick={resetPasswordModalHandler}
				className="text-red-500 text-sm w-full text-center mt-2"
			>
				Forgot Password
			</button>
			<ResetPasswordModal
				isOpen={isResetPasswordModal}
				onClose={resetPasswordModalHandler}
			/>
			<div className="text-sm w-full mt-4 font-normal text-center">
				Don't have an account?
				<span className="ml-1 text-mc_blue underline">
					<Link to="/auth/register">Create here</Link>
				</span>
			</div>
			<BlockedAccountModal
				isOpen={isBlockedModal}
				onClose={() => setIsBlockedModal(!isBlockedModal)}
			/>
		</div>
	);
};

export default LoginPage;
