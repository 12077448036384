import React, { useState } from 'react';
import Modal from '../../shared/Modal';
import { createRegistrationId } from '../../../api/registrationId';
import { createRegistrationIdByAdmin } from '../../../api/registrationId';
import Cookies from 'js-cookie';
import { AccountType } from '../../../static/enums/accountTypeEmum';

const RegIdCreate = ({ agentId, userType }) => {
  const [registrationCode, setRegistrationCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState('success');
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const copyToClipboard = () => {
    if (registrationCode) {
      navigator.clipboard
        .writeText(registrationCode)
        .then(() => {
          setIsTooltipVisible(true);
          setTimeout(() => {
            setIsTooltipVisible(false);
          }, 1000);
        })
        .catch(() => {
          console.error('Failed to copy!');
        });
    }
  };

  const handleSubmit = async () => {
    const token = Cookies.get('accessToken');
    if (!token) {
      setModalMessage('Unauthorized: No access token found');
      setModalType('error');
      setIsModalOpen(true);
      return;
    }

    let result;
    if (userType === AccountType.Admin && agentId) {
      result = await createRegistrationIdByAdmin(token, agentId);
    } else if (userType === AccountType.Agent) {
      result = await createRegistrationId(token);
    } else {
      setModalMessage('Error: Invalid account type');
      setModalType('error');
      setIsModalOpen(true);
      return;
    }

    if (result.successful && result.data && result.data.code) {
      setRegistrationCode(result.data.code);
      setModalMessage('Registration ID created successfully');
      setModalType('success');
    } else {
      setModalMessage(`Error: ${result.error?.message || 'Unknown error'}`);
      setModalType('error');
    }

    setIsModalOpen(true);
  };

  return (
    <div className="container flex-col items-start bg-grey-main md:py-10 py-5 rounded-lg h-auto md:px-16 px-3">
      <ul className="flex w-full justify-between gap-x-3 md:gap-x-5">
        <div className="flex flex-col w-3/5">
          <li className="li_style before:top-5">
            <div className="relative">
              <input
                value={registrationCode}
                readOnly
                className="p-3 w-full text-xs md:text-base rounded-md border border-grey-main md:mb-5 mb-2"
              />
              <svg
                onClick={copyToClipboard}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="md:size-6 size-4 absolute right-3 top-3 cursor-pointer text-blue-900 -scale-x-100"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 8.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v8.25A2.25 2.25 0 0 0 6 16.5h2.25m8.25-8.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-7.5A2.25 2.25 0 0 1 8.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 0 0-2.25 2.25v6"
                />
              </svg>
              {isTooltipVisible && (
                 <span className="absolute left-1/2 transform -translate-x-1/2 text-base top-[-35px] text-red-600 px-2 py-1 rounded-md font-semibold">
                 Copy success!
               </span>
              )}
            </div>
            <button
              onClick={handleSubmit}
              className="text-[6px] md:text-xs bg-btn-green hover:bg-[#37bd54] font-semibold md:py-3 py-1.5 md:px-4 px-2 rounded-full xl:w-2/3 w-full"
            >
              Create Registration ID
            </button>
          </li>
        </div>
        <li className="w-2/5">
          <div className="flex text-[9px] md:text-xs text-left justify-center text-purple-circle font-medium">
            Note <br />
            1. The registration ID will expire in 14 days.
            <br />
            2. The registration ID can only be used once. <br />
          </div>
        </li>
      </ul>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        message={modalMessage}
        type={modalType}
      />
    </div>
  );
};

export default RegIdCreate;
