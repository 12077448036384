import React, { useEffect, useState } from 'react';
import CodeInputs from '../additional/CodeInputs';
import { requestVerification, verifyCode } from '../../api/securityApi';
import { useNavigate } from 'react-router-dom';
import { AccountType } from '../../static/enums/accountTypeEmum';

const TwoFactVereficantionModal = ({ userData, isOpen, onClose }) => {
	const { accountId, email, accountType } = userData;
	const [code, setCode] = useState(Array(6).fill(''));
	const [message, setMessage] = useState(null);
	const navigate = useNavigate();

	const sendVerificationCode = async () => {
		const result = await requestVerification({ accountId, email });
		if (!result.successful) {
			setMessage({
				type: 'error',
				text: result.error?.message || 'Failed to send verification code',
			});
		}
	};

	useEffect(() => {
		if (isOpen) {
			sendVerificationCode();
		}
	}, [isOpen]);

	const handleResendCode = async () => {
		sendVerificationCode();
	};

	const handleCodeChange = async (newCode) => {
		setCode(newCode);

		if (newCode.length === 6 && !newCode.includes('')) {
			const codeEntered = newCode.join('');
			const result = await verifyCode(codeEntered, accountId);

			if (result && result.successful) {
				setMessage({ type: 'success', text: 'Verification successful!' });
				if (accountType === AccountType.Agent) {
					navigate('/agent/dashboard');
				} else if (accountType === AccountType.Admin) {
					navigate('/admin/dashboard');
				} else if (accountType === AccountType.Employee) {
					navigate('/employee/dashboard');
				}
			} else {
				setMessage({
					type: 'error',
					text: result?.error?.message || 'Verification failed',
				});
			}
		}
	};

	const handleClose = () => {
		setCode(Array(6).fill(''));
		setMessage('');
		onClose();
	};

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 flex items-center justify-center z-50 font-sans bg-black bg-opacity-50">
			<div className="bg-white p-6 rounded-lg shadow-lg w-2/5">
				<div className="w-full flex justify-between items-baseline">
					<span className="font-semibold text-xl font-poppins">
						2FA verification
					</span>

					<button onClick={handleClose}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="#444444"
							className="size-5"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M6 18 18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
				<hr className="mt border-black border-opacity-50" />
				<div className="flex flex-col w-full justify-center items-center">
					<div className="text-base font-normal m-4">
						Enter the code we sent to your email.
					</div>
					<CodeInputs code={code} onCodeChange={handleCodeChange} />
					{message && (
						<div
							className={`text-center mt-2 ${
								message.type === 'error' ? 'text-red-500' : 'text-green-500'
							}`}
						>
							{message.text}
						</div>
					)}
				</div>
				<hr className="mt-8 mb-4 border-black border-opacity-50 " />
				<div className="text-center w-full">
					<button onClick={handleResendCode} className="underline text-mc_blue">
						Resend code
					</button>
				</div>
			</div>
		</div>
	);
};

export default TwoFactVereficantionModal;
