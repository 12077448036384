import React, { useEffect, useRef, useState } from 'react';
import SearchDropdown from './additional/SearchDropdown';
// import { useMask } from '@react-input/mask';
import { useNavigate } from 'react-router-dom';
import CustomRadio from './additional/CustomRadio';
import { getAllCountries } from '../api/countryApi';
import Gender from '../static/enums/genderEnum';

const UserCreateAccount = ({ registrationId, createAccount }) => {
	const [countryDropdown, setCountryDropdown] = useState(false);
	const [country, setCountry] = useState({
		selectedFlag: '',
		selectedCode: '',
		selectedId: '',
	});
	const [searchCountry, setSearchCountry] = useState('');
	const [selectedDirection, setSelectedDirection] = useState(0);
	const [errors, setErrors] = useState({
		firstName: false,
		lastName: false,
		age: false,
		email: false,
		phone: false,
		gender: false,
		country: false,
		password1: false,
		password2: false,
	});
	const [formError, setFormError] = useState(false);
	const [inputValue, setInputValue] = useState(`${country.selectedCode}`);
	const navigate = useNavigate();
	const [serverError, setServerError] = useState('');
	const [countries, setCountries] = useState([]);

	const buttonRef = useRef(null);
	const [totalWidth, setTotalWidth] = useState(0);

	useEffect(() => {
		const fetchCountries = async () => {
			try {
				const data = await getAllCountries(searchCountry);
				setCountries(data);
			} catch (error) {
				console.error('Failed to fetch countries:', error);
			}
		};

		fetchCountries();
	}, [searchCountry]);

	const updateWidth = () => {
		if (buttonRef.current && inputRef.current) {
			const buttonWidth = buttonRef.current.getBoundingClientRect().width;
			const inputWidth = inputRef.current.getBoundingClientRect().width;
			setTotalWidth(buttonWidth + inputWidth);
		}
	};

	useEffect(() => {
		updateWidth();

		window.addEventListener('resize', updateWidth);

		return () => {
			window.removeEventListener('resize', updateWidth);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const inputRef = useRef();

	// const inputRef = useMask({
	// 	mask: `${country.selectedCode} (__) ___-__-__`,
	// 	replacement: { _: /\d/ },
	// });

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.value = country.selectedCode;
		}
	}, [country.selectedCode, inputRef]);

	const handleKeyDown = (e) => {
		const input = inputRef.current;
		const selectionStart = input.selectionStart;

		if (
			selectionStart <= country.selectedCode.length &&
			(e.key === 'Backspace' || e.key === 'Delete')
		) {
			e.preventDefault();
		}

		if (selectionStart < country.selectedCode.length) {
			e.preventDefault();
			input.setSelectionRange(
				country.selectedCode.length,
				country.selectedCode.length
			);
		}
	};

	const openCountryDropdownHandler = (e) => {
		e.preventDefault();
		setCountryDropdown(!countryDropdown);
	};

	const closeCountryDropdownHandler = () => {
		setCountryDropdown(false);
	};

	const handleCountrySelect = (flagUrl, code, id) => {
		setCountry({
			selectedFlag: flagUrl,
			selectedCode: code,
			selectedId: id,
		});
		setErrors((prevErrors) => ({
			...prevErrors,
			country: false,
		}));
	};

	const handleChangeDirection = (newValue) => {
		setSelectedDirection(Number(newValue));
		setErrors((prevErrors) => ({
			...prevErrors,
			gender: false,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const newErrors = {
			firstName: !e.target.firstName.value,
			lastName: !e.target.lastName.value,
			email: !e.target.email.value,
			age: !e.target.age.value,
			phone: inputRef.current.value.length <= country.selectedCode.length,
			gender: selectedDirection === 0,
			country: country.selectedId.length === 0,
			password1: !e.target.password1.value,
			password2:
				e.target.password1.value.length > 0 &&
				e.target.password1.value !== e.target.password2.value,
		};
		setErrors(newErrors);

		if (Object.values(newErrors).some((error) => error)) {
			setFormError(true);
			if (newErrors.password2) {
				e.target.password2.value = '';
			}
			return;
		}

		setFormError(false);

		const formData = {
			registrationId: registrationId,
			firstName: e.target.firstName.value,
			lastName: e.target.lastName.value,
			email: e.target.email.value,
			age: Number(e.target.age.value),
			phone: inputRef.current.value,
			countryId: country.selectedId,
			gender: selectedDirection,
			password: e.target.password1.value,
		};

		try {
			const result = await createAccount(formData);

			if (result.successful) {
				
					navigate('/auth/connect');
					e.target.reset();
					inputRef.current.value = '';
				}
			 else {
				setServerError(result.error.message);
			}
		} catch (error) {
			console.error('Unexpected error in handleSubmit:', error);
			setServerError('An unexpected error occurred. Please try again.');
		}

		setErrors({
			firstName: false,
			lastName: false,
			email: false,
			age: false,
			phone: false,
			country: false,
			password1: false,
			password2: false,
		});
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		if (name === 'phone') {
			const numericValue = value.replace(/(?!^)\D/g, '');
			e.target.value = numericValue;
		}
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: false,
		}));
		setFormError(false);
		setServerError('');
	};

	const handlePrivacyPolice = () => {
		navigate('/privacy');
	};

	const handleTermsOfServes = () => {
		navigate('/terms');
	};

	return (
		<div className="font-poppins flex flex-col items-center p-4 w-3/5">
			<div className="text-mc_purple font-medium md:text-xl lg:text-3xl text-center mb-4">
				Create Your Account
			</div>
			<form
				className="grid grid-cols-2 gap-x-10 gap-y-4 w-full"
				onSubmit={handleSubmit}
			>
				<div className="col-span-1">
					<label
						htmlFor="firstName"
						className="block font-normal text-sm text-black ml-2 mb-1"
					>
						First Name
					</label>
					<div>
						<input
							id="firstName"
							name="firstName"
							type="text"
							onChange={handleInputChange}
							className={`block w-full h-11 p-3 font-normal rounded-[5px] border border-mc_light_grey outline-none ${errors.firstName ? 'bg-red-500 bg-opacity-30' : 'bg-transparent'}`}
						/>
					</div>
				</div>
				<div className="col-span-1">
					<label
						htmlFor="lastName"
						className="block font-normal text-sm text-black ml-2 mb-1"
					>
						Last Name
					</label>
					<div>
						<input
							id="lastName"
							name="lastName"
							type="text"
							onChange={handleInputChange}
							className={`block w-full h-11 p-3 font-normal rounded-[5px] border border-mc_light_grey outline-none ${errors.lastName ? 'bg-red-500 bg-opacity-30' : 'bg-transparent'}`}
						/>
					</div>
				</div>
				<div className="col-span-1">
					<label
						htmlFor="age"
						className="block font-normal text-sm text-black ml-2 mb-1"
					>
						Age
					</label>
					<div>
						<input
							id="age"
							name="age"
							type="number"
							onChange={handleInputChange}
							className={`block w-full h-11 p-3 font-normal rounded-[5px] border border-mc_light_grey outline-none ${errors.age ? 'bg-red-500 bg-opacity-30' : 'bg-transparent'}`}
							min={0}
						/>
					</div>
				</div>
				<div className="col-span-1">
					<CustomRadio
						name="direction"
						error={errors.gender}
						items={Gender}
						value={selectedDirection}
						onChange={handleChangeDirection}
					/>
				</div>
				<div className="col-span-1">
					<label
						htmlFor="email"
						className="block font-normal text-sm text-black ml-2 mb-1"
					>
						Email
					</label>
					<div>
						<input
							id="email"
							name="email"
							type="email"
							onChange={handleInputChange}
							className={`block w-full h-11 p-3 font-normal rounded-[5px] border border-mc_light_grey  outline-none ${errors.email ? 'bg-red-500 bg-opacity-30' : 'bg-transparent'}`}
						/>
					</div>
				</div>
				<div className="col-span-1 ">
					<label
						htmlFor="phone"
						className="block font-normal text-sm text-black ml-2 mb-1"
					>
						Mobile
					</label>
					<div className="flex">
						<div className="w-1/4">
							<button
								onClick={openCountryDropdownHandler}
								className={`h-11 w-full p-3 flex flex-col justify-center items-center rounded-l-[5px] border border-mc_light_grey outline-none ${errors.country ? 'bg-red-500 bg-opacity-30' : 'bg-transparent'}`}
								ref={buttonRef}
							>
								{country.selectedFlag ? (
									<img
										src={country.selectedFlag}
										alt="Selected flag"
										className="h-auto"
									/>
								) : (
									<div className="flex items-center">
										<span className="text-xs">code</span>{' '}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
											fill="currentColor"
											className="size-5"
										>
											<path
												fillRule="evenodd"
												d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
												clipRule="evenodd"
											/>
										</svg>
									</div>
								)}
							</button>
							{countryDropdown && (
								<SearchDropdown
									countries={countries}
									onCountrySelect={handleCountrySelect}
									onClose={closeCountryDropdownHandler}
									value={inputValue}
									searchValue={searchCountry}
									setSearchValue={setSearchCountry}
									onChange={(e) => setInputValue(e.target.value)}
									style={{ width: `${totalWidth}px` }}
								/>
							)}
						</div>
						<input
							id="phone"
							name="phone"
							type="text"
							placeholder="Phone No"
							ref={inputRef}
							onKeyDown={handleKeyDown}
							onChange={handleInputChange}
							className={`block h-11 w-full p-3 font-normal rounded-r-[5px] border border-mc_light_grey outline-none placeholder:text-sm ${errors.phone ? 'bg-red-500 bg-opacity-30' : 'bg-transparent'}`}
						/>
					</div>
				</div>
				<div className="col-span-2 mt-2">
					<input
						id="password1"
						name="password1"
						type="password"
						onChange={handleInputChange}
						placeholder="Set your new Password"
						className={`block w-full text-start h-11 p-3 font-normal rounded-[5px] border border-mc_light_grey  outline-none placeholder:text-sm placeholder:text-black placeholder:text-center focus:placeholder:text-transparent ${errors.password1 ? 'bg-red-500 bg-opacity-30' : 'bg-transparent'}`}
					/>
				</div>

				<div className="col-span-2">
					<input
						id="password2"
						name="password2"
						type="password"
						onChange={handleInputChange}
						placeholder={
							errors.password2
								? 'Passwords do not match! Please re-enter!'
								: 'Re-Enter your Password'
						}
						className={`block w-full h-11 text-start p-3 font-normal rounded-[5px] border border-mc_light_grey outline-none placeholder:text-sm placeholder:text-center focus:placeholder:text-transparent ${
							errors.password2
								? 'placeholder:text-mc_red bg-red-500 bg-opacity-30'
								: 'placeholder:text-black bg-transparent'
						}`}
					/>
				</div>

				<div className="col-span-2 text-xs">
					By creating an account, i agreed to{' '}
					<div
						className="text-red-500 cursor-pointer"
						
					>
						MarsCAPITA’s <button onClick={handleTermsOfServes}>Terms of Use</button> and <button onClick={handlePrivacyPolice}>Privacy Policy</button>
					</div>
				</div>

				<div className="col-span-2">
					{(formError || serverError) && (
						<span className="text-base text-red-500 block w-full text-center">
							{serverError
								? serverError
								: 'Please complete all required fields!'}
						</span>
					)}

					<button className="flex items-center w-full h-11 p-3 font-medium rounded-xl text-sm text-black shadow-md outline-none bg-opacity-50 bg-mc_blue">
						<span className="grow">Next</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="#5C5C5C"
							className="size-4"
						>
							<path
								fillRule="evenodd"
								d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
								clipRule="evenodd"
							/>
						</svg>
					</button>
				</div>
			</form>
		</div>
	);
};

export default UserCreateAccount;
