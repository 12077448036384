import React from 'react';

const ExitOrderHeader = ({ selectAll, handleSelectAll }) => {
    return (
        <div className="flex items-center md:px-6 px-1 py-2 font-bold text-sm bg-white space-x-5">
        <span className="tr_style w-1/7">№</span>
        <span className="tr_style w-1/7">Customer <br /> Name </span>
        <span className="tr_style w-1/7">Transaction ID </span>
        <span className="tr_style w-1/7">Order Type</span>
        <span className="tr_style w-1/7">(Date/Time)</span>
        <span className="tr_style w-1/7">Exit Type</span>
        <span className="tr_style w-1/7">Result</span>
      </div>
    );
};

export default ExitOrderHeader;