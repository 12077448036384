import React from 'react';

const EmployeeTableHeader = ({ selectAll, handleSelectAll }) => {
  return (
    <div className="flex items-center md:px-6 px-1 py-2 font-bold text-sm bg-white">
      <span className="tr_style w-1/6">№</span>
      <span className="tr_style w-1/6">Name</span>
      <span className="tr_style w-1/6">Country</span>
      <span className="tr_style w-1/6">Status</span>
      <span className="tr_style w-1/6">Since</span>
      <span className="tr_style w-1/6">Action</span>
    </div>
  );
};

export default EmployeeTableHeader;
