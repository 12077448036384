import React, { useState } from 'react';
import UserRegistrationId from '../components/UserRegistrationId';
import UserCreateAccount from '../components/UserCreateAccount';
import { verifyRegistrationId } from '../api/securityApi';
import usePageTitle from '../hooks/usePageTitle';

const RegistrationPage = ({ createAccount }) => {
	usePageTitle('Register an account');
	const [errorMessage, setErrorMessage] = useState('');
	const [isIdentified, setIsIdentified] = useState(false);
	const [registrationId, setRegistrationId] = useState('');

	const handleRegistrationIdSubmit = async (id) => {
		const trimmedId = id.trim();
		if (!trimmedId) {
			setErrorMessage('Please, enter Registration ID');
			return;
		}
		try {
			
			const response = await verifyRegistrationId(trimmedId);

			if (response.successful) {
				setRegistrationId(trimmedId);
				setIsIdentified(true);
				setErrorMessage('');
			} else {
				setErrorMessage(response.error.message);
			}
		} catch (error) {
			setErrorMessage('An error occurred while submitting registration ID.');
		}
	};

	return (
		<>
			{!isIdentified ? (
				<UserRegistrationId
					onSubmit={handleRegistrationIdSubmit}
					errorMessage={errorMessage}
				/>
			) : (
				<UserCreateAccount
					createAccount={createAccount}
					registrationId={registrationId}
				/>
			)}
		</>
	);
};

export default RegistrationPage;
