import React, { useEffect, useState } from 'react';
import DropdownStatus from '../../../shared/DropDownStatus';
import ModalConfirmation from '../../../shared/ModalConfirmation';
import Modal from '../../../shared/Modal';
import { getAllCountries } from '../../../../api/countryApi';
import { deleteAgentById, updateAgentById } from '../../../../api/agentApi';
import DropDownCountry from '../../../shared/DropDownCountry';
import Gender from '../../../../static/enums/genderEnum';
import Status from '../../../../static/enums/statusEnum';
import RadioValue from '../../../shared/RadioValue';
import Cookies from 'js-cookie';
import { AccountType } from '../../../../static/enums/accountTypeEmum';
import { useNavigate } from 'react-router-dom';
import ResetPasswordModal from '../../../shared/ResetPasswordModal';
import { changePassword } from '../../../../api/accountApi';
import { updateStatus } from '../../../../api/securityApi';

const AgentForm = ({
	agentData,
	accountType,
	setAgentData,
}) => {
	const navigate = useNavigate();

	const [showModal, setShowModal] = useState(false);
	const [pendingStatus, setPendingStatus] = useState(null);

	const [countryDropdown, setCountryDropdown] = useState(false);
	const [country, setCountry] = useState({
		selectedFlag: agentData?.account?.country?.imageUrl || '',
		selectedName: agentData?.account?.country?.name || '',
		selectedId: agentData?.account?.country?.id || '',
	});
	const [selectedGender, setSelectedGender] = useState(
		agentData?.account?.gender || 1
	);
	const [firstName, setFirstName] = useState(
		agentData?.account?.firstName || ''
	);
	const [lastName, setLastName] = useState(agentData?.account?.lastName || '');
	const [email, setEmail] = useState(agentData?.account?.email || '');
	const [age, setAge] = useState(agentData?.account?.age || '');
	const [phone, setPhone] = useState(agentData?.account?.phone || '');
	const [agentID, setAgentID] = useState(agentData?.id || '');
	const [inputValue, setInputValue] = useState(
		agentData?.account?.country?.name || ''
	);
	const [status, setStatus] = useState(agentData?.account?.status);

	const [countries, setCountries] = useState([]);
	const [showSaveModal, setShowSaveModal] = useState(false);
	const [modalMessage, setModalMessage] = useState('');
	const [modalType, setModalType] = useState('success');
	const [searchCountry, setSearchCountry] = useState('');
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [isAccountDeleted, setIsAccountDeleted] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [errorPassword, setErrorPassword] = useState('');
	const [successPassword, setSuccessPassword] = useState('');
	const [disable, setDisable] = useState(false);
	useEffect(() => {
		setStatus(agentData?.account?.status);
	}, [agentData]);

	useEffect(() => {
		const isChanged =
			firstName !== agentData?.account?.firstName ||
			lastName !== agentData?.account?.lastName ||
			email !== agentData?.account?.email ||
			age !== agentData?.account?.age ||
			phone !== agentData?.account?.phone ||
			(country.selectedId || '') !== (agentData?.account?.country?.id || '') ||
			selectedGender !== agentData?.account?.gender ||
			status !== agentData?.account?.status;

		setIsFormChanged(isChanged);
	}, [
		firstName,
		lastName,
		email,
		age,
		phone,
		country,
		selectedGender,
		status,
		agentData,
	]);

	useEffect(() => {
		const fetchCountries = async () => {
			try {
				const data = await getAllCountries(searchCountry);
				setCountries(data || []);
			} catch (error) {
				console.error('Failed to fetch countries:', error);
			}
		};

		fetchCountries();
	}, [searchCountry]);

	useEffect(() => {
		if (!showSaveModal && isAccountDeleted) {
			navigate('/agents');
		}
	}, [showSaveModal, isAccountDeleted, navigate]);

	const getStatusLabelByValue = (value) => {
		const status = Status.find((status) => status.value === value);
		return status ? status.label : '';
	};

	const getStatusValueByLabel = (label) => {
		const status = Status.find((status) => status.label === label);
		return status ? status.value : '';
	};

	const handleStatusChange = (newStatus) => {
		setPendingStatus(newStatus);
		setShowModal(true);
	};

	const confirmStatusChange = async () => {
		setStatus(pendingStatus);
		if (pendingStatus === 0) {
			try {
				const token = Cookies.get('accessToken');
				if (!token) {
					return { account: null, statusCode: 401 };
				}
				const result = await deleteAgentById(agentData.id, token);
				if (result.successful) {
					setModalMessage('Account delete successfully!');
					setShowSaveModal(true);
					setIsFormChanged(false);
					setIsAccountDeleted(true);
				} else {
					setModalMessage(result.message || 'Failed to delete the account');
					setModalType('error');
					setShowSaveModal(true);
				}
			} catch (error) {
				setModalMessage('An unexpected error occurred');
				setModalType('error');
				setShowSaveModal(true);
			}
		} else {
			try {
				const token = Cookies.get('accessToken');
				const newStatus = await updateStatus(
					token,
					agentData.account.id,
					pendingStatus
				);
			

				if (getStatusLabelByValue(pendingStatus) === 'Active') {
					setModalMessage(['Agent acccount successfully unblocked.']);
				} else {
					setModalMessage(['Agent acccount successfully blocked.']);
				}
				setModalType('success');
				setIsFormChanged(false);
			} catch (error) {
				setModalMessage('Failed to update agent data. Please try again.');
				setModalType('error');
			}

			setShowSaveModal(true);
		}
		setShowModal(false);
	};

	const openCountryDropdownHandler = (e) => {
		e.preventDefault();
		setCountryDropdown(!countryDropdown);
	};

	const closeCountryDropdownHandler = () => {
		setCountryDropdown(false);
	};

	const handleCountrySelect = (flagUrl, name, id) => {
		setCountry({
			selectedFlag: flagUrl,
			selectedName: name,
			selectedId: id,
		});
		setInputValue(name);
		closeCountryDropdownHandler();
	};

	// const handleSubmit = async (event) => {
	// 	event.preventDefault();
	// 	if (status === 0) {
	// 		try {
	// 			const token = Cookies.get('accessToken');
	// 			if (!token) {
	// 				return { account: null, statusCode: 401 };
	// 			}
	// 			const result = await deleteAgentById(agentData.id, token);
	// 			if (result.successful) {
	// 				setModalMessage('Account delete successfully!');
	// 				setShowSaveModal(true);
	// 				setIsFormChanged(false);
	// 				setIsAccountDeleted(true);
	// 			} else {
	// 				setModalMessage(result.message || 'Failed to delete the account');
	// 				setModalType('error');
	// 				setShowSaveModal(true);
	// 			}
	// 		} catch (error) {
	// 			setModalMessage('An unexpected error occurred');
	// 			setModalType('error');
	// 			setShowSaveModal(true);
	// 		}
	// 	} else {
	// 		const formData = {
	// 			firstName,
	// 			lastName,
	// 			email,
	// 			age,
	// 			phone,
	// 			countryId: country.selectedId,
	// 			agentID,
	// 			gender: selectedGender,
	// 			registrationIdsCount,
	// 			status,
	// 		};

	// 		try {
	// 			const token = Cookies.get('accessToken');
	// 			const newAgentData = await updateAgentById(
	// 				agentData.id,
	// 				formData,
	// 				token
	// 			);
	// 			setAgentData(newAgentData);

	// 			setModalMessage(['Agent updated successfully!']);
	// 			setModalType('success');
	// 			setIsFormChanged(false);
	// 		} catch (error) {
	// 			setModalMessage('Failed to update agent data. Please try again.');
	// 			setModalType('error');
	// 		}

	// 		setShowSaveModal(true);
	// 	}
	// };

	const handlePasswordChange = async (password) => {
		const token = Cookies.get('accessToken');

		if (!token) {
			console.error('Authorization token not found');
			return;
		}
		try {
			const response = await changePassword(
				agentData.account.id,
				password,
				token
			);
			if (response.successful) {
				setSuccessPassword('Password reset successful!');
				setDisable(true);
			} else {
				setErrorPassword('Password change failed');
			}
		} catch (error) {
			setErrorPassword('Password change failed');
		}
	};

	return (
		<div className="p-1">
			<form>
				{/* Form Grid */}
				<div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
					{/* First Name */}
					<div>
						<label className="block md:text-sm text-[6px] font-medium text-gray-700">
							First Name
						</label>
						<input
							type="text"
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							disabled
							// ={accountType === AccountType.Employee}
							// required
						/>
					</div>
					{/* Last Name */}
					<div>
						<label className="block md:text-sm text-[6px] font-medium text-gray-700">
							Last Name
						</label>
						<input
							type="text"
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							disabled
							// ={accountType === AccountType.Employee}
							// required
						/>
					</div>
					{/* Email */}
					<div>
						<label className="block md:text-sm text-[6px] font-medium text-gray-700 ">
							Email
						</label>
						<input
							type="email"
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							disabled
							// ={accountType === AccountType.Employee}
							// required
						/>
					</div>
				</div>

				<div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
					{/* Age */}
					<div>
						<label className="block md:text-sm text-[6px] font-medium text-gray-700">
							Age
						</label>
						<input
							type="number"
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
							value={age}
							onChange={(e) => {
								const newValue = parseInt(e.target.value, 10);
								setAge(newValue >= 0 ? newValue : 0);
							}}
							min={0}
							disabled
							// ={accountType === AccountType.Employee}
							// required
						/>
					</div>
					{/* Mobile */}
					<div>
						<label className="block md:text-sm text-[6px] font-medium text-gray-700">
							Mobile
						</label>
						<input
							type="text"
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
							disabled
							// ={accountType === AccountType.Employee}
							// required
						/>
					</div>
					{/* Country */}
					<div>
						<label className="block md:text-sm text-[6px] font-medium text-gray-700">
							Country
						</label>
						<div className="w-full">
							<button
								onClick={openCountryDropdownHandler}
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-left disabled:bg-mc_white"
								disabled
								// ={accountType === AccountType.Employee}
							>
								{country.selectedId ? (
									<span>{country.selectedName}</span>
								) : (
									<span className="text-xs">No selected country</span>
								)}
							</button>
							{countryDropdown && (
								<DropDownCountry
									countries={countries}
									onCountrySelect={handleCountrySelect}
									onClose={closeCountryDropdownHandler}
									value={inputValue}
									searchValue={searchCountry}
									setSearchValue={setSearchCountry}
									onChange={(e) => setInputValue(e.target.value)}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
					<div className="col-start-1">
						<label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-1">
							Password
						</label>
						<div className="relative">
							<input
								type="password"
								className="block mt-1 w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={'********'}
								placeholder="Enter Password here"
								disabled={accountType === AccountType.Employee}
								readOnly
							/>
							<button
								type="button"
								className="absolute inset-y-0 right-0 flex items-center px-3 "
								onClick={() => setIsModalOpen(true)} 
								disabled={accountType === AccountType.Employee}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6 text-gray-500"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
									/>
								</svg>
							</button>
						</div>
					</div>
					{/* Agent ID */}
					<div className="col-start-2">
						<label className="block md:text-sm text-[6px] font-medium text-gray-700">
							Agent ID
						</label>
						<input
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
							value={agentID}
							onChange={(e) => setAgentID(e.target.value)}
							disabled
						/>
					</div>
					{/* Gender */}
					<div className="col-start-3">
						<label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-4">
							Gender
						</label>
						<div className="mt-1 flex-row items-center">
							<RadioValue
								name="gender"
								items={Gender}
								value={selectedGender}
								onChange={setSelectedGender}
								disabled
								// ={accountType === AccountType.Employee}
							/>
						</div>
					</div>
				</div>
				<div>
					{/* Status */}
					<div className="flex justify-between">
						<div className="w-1/3">
							{/* <div className="flex-1">
                <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                  Status
                </label> */}
							<DropdownStatus
								options={[
									{
										label: 'Block',
										value: getStatusValueByLabel('Blocked'),
										disabled: status === getStatusValueByLabel('Blocked'),
									},
									{
										label: 'Unblock',
										value: getStatusValueByLabel('Active'),
										disabled: status === getStatusValueByLabel('Active'),
									},
									{ label: 'Delete Account', value: 0 },
								]}
								selected={{
									value: status,
									label:
										status === 0
											? 'Delete Account'
											: getStatusLabelByValue(status),
								}}
								onSelect={handleStatusChange}
								disabled={accountType === AccountType.Employee}
								className={`w-full p-3 rounded-full md:text-base text-[8px] ${
									getStatusLabelByValue(status) === 'Active'
										? 'bg-green-500 text-white'
										: 'bg-red-500 text-white'
								}`}
							/>
							{/* </div> */}
						</div>

						{/* Submit */}
						{/* <div className="flex w-1/3">
							<button
								type="submit"
								className="w-full text-white p-3.5 rounded-full md:text-base text-[8px] bg-green-500 hover:bg-green-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
								disabled={
									!isFormChanged || accountType === AccountType.Employee
								}
							>
								Save information
							</button>
						</div> */}
					</div>
				</div>
			</form>

			{/* Modal */}
			<ModalConfirmation
				isVisible={showModal}
				onCancel={() => setShowModal(false)}
				onConfirm={confirmStatusChange}
				pendingStatus={
					pendingStatus !== 0
						? `${getStatusLabelByValue(pendingStatus) === 'Blocked' ? 'Blocking confirmation' : 'Confirmation of unblocking'}`
						: 'Confirm delete this account'
				}
				modalType="delete"
			/>

			<Modal
				isOpen={showSaveModal}
				onClose={() => setShowSaveModal(false)}
				message={modalMessage}
				type={modalType}
			/>

			{isModalOpen && (
				<ResetPasswordModal
					onClose={() => {
						setIsModalOpen(false);
						setDisable(false);
					}}
					onPasswordChange={handlePasswordChange}
					error={errorPassword}
					setError={(e) => setErrorPassword(e)}
					success={successPassword}
					setSuccess={(s) => setSuccessPassword(s)}
					disable={disable}
				/>
			)}
		</div>
	);
};

export default AgentForm;
