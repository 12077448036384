import React from 'react';

const RegIdHead = ({ selectAll, handleSelectAll }) => {
  return (
    <div className="flex items-center md:px-6 px-1 py-2 font-bold text-sm bg-white space-x-5">
      <span className="tr_style w-1/5">№</span>
      <span className="tr_style w-1/5">Registration ID</span>
      <span className="tr_style w-1/5">Status</span>
      <span className="tr_style w-1/5">Date of Creation</span>
      <span className="tr_style w-1/5">Register Date</span>
    </div>
  );
};

export default RegIdHead;
