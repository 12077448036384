import React from 'react';

const BlockedAccountModal = ({ isOpen, onClose }) => {
	if (!isOpen) return null;
	return (
		<div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 font-poppins">
			<div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
				<div className="w-full text-end">
					<button onClick={onClose}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="#444444"
							className="size-4"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M6 18 18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
				<div className="w-full flex flex-col justify-center items-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={1.5}
						stroke="#E80307"
						className="size-28"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M15.182 16.318A4.486 4.486 0 0 0 12.016 15a4.486 4.486 0 0 0-3.198 1.318M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z"
						/>
					</svg>
                    <br />
                    <p className='text-center text-xl font-medium'>Your account is currently inactive. 
                    Please contact support for assistance.</p>
				</div>
			</div>
		</div>
	);
};

export default BlockedAccountModal;
