import React, { useState } from "react";

const BarDashboard = () => {
  const [year, setYear] = useState(2024);

  const data = {
    2024: [50, 30, 70, 85, 100, 65, 40, 75, 90, 110, 95, 45],
    2025: [60, 40, 80, 90, 110, 70, 50, 80, 95, 120, 100, 55],
  };

  const handleChange = (event) => {
    setYear(Number(event.target.value)); 
  };

  const hasData = data[year] && data[year].length > 0;
  const maxValue = hasData ? Math.max(...data[year]) : 0;
  const chartHeight = 200;

  return (
    <div className="bg-white rounded-lg shadow-md p-4 m-4 md:p-8 lg:p-16">
      <div className="flex flex-col md:flex-row justify-between items-center mb-8 lg:mb-16">
        <h2 className="text-sm md:text-lg lg:text-xl font-bold z-30 text-purple-700 mb-6 md:mb-0">
          Monthly Income Report
        </h2>
        <select
          className="border z-30 border-[#a828c5] rounded-3xl py-1.5 md:py-2 px-4 md:px-6 text-xs md:text-sm"
          value={year}
          onChange={handleChange}
          title="Sort By Year"
        >
          <option value={2024}>2024</option>
          <option value={2025}>2025</option>
          <option value={2026}>2026</option>
        </select>
      </div>
      <div className="relative h-48 md:h-64">
        {!hasData && (
          <div className="absolute inset-0 flex items-center justify-center text-gray-500 text-lg md:text-2xl font-bold">
            No Data
          </div>
        )}
        {[20, 40, 60, 80, 100, 120, 140].map((lineValue) => (
          <div
            key={lineValue}
            className="absolute left-0 right-0 border-t border-dashed border-gray-200 z-20"
            style={{ bottom: `${(lineValue / 100) * chartHeight}px` }}
          ></div>
        ))}
        {hasData && (
          <div className="flex justify-between items-end h-full">
            {data[year].map((value, index) => (
              <div key={index} className="flex flex-col items-center group">
                <div
                  className="w-2 md:w-3 bg-gradient-to-b from-[#b946f4] to-[#8a2dce] rounded-t-md z-30 relative"
                  style={{ height: `${(value / maxValue) * chartHeight}px` }}
                >
                  <div className="absolute w-4 h-4 md:w-6 md:h-6 bg-purple-500 rounded-full opacity-0 group-hover:opacity-50 transition-opacity duration-300 transform -translate-x-1/2 left-1/2 -top-2"></div>
                </div>
                <span className="text-[8px] md:text-xs lg:text-sm mt-1 md:mt-2 text-gray-500">
                  {index + 1}/{year.toString().slice(-2)}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BarDashboard;
