import React, { useState, useEffect } from 'react';
import Modal from '../../shared/Modal';
import { useParams } from 'react-router-dom';

const WebHook = ({ webhookUrl, clearWebhook }) => {
  const { strategyId } = useParams();
  const [inputValue, setInputValue] = useState(''); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState('success');
  const [isWebhookCreated, setIsWebhookCreated] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isWebhookVisible, setIsWebhookVisible] = useState(false); 

  useEffect(() => {
    setInputValue(''); 
    setIsWebhookCreated(false);
    setIsWebhookVisible(false);
  }, [strategyId]); 


  useEffect(() => {
    if (webhookUrl && isWebhookVisible) {
      setInputValue(webhookUrl); 
    }
  }, [webhookUrl, isWebhookVisible]);

  useEffect(() => {
    setInputValue(''); 
    setIsWebhookCreated(false);
  }, [clearWebhook]);

  const copyToClipboard = () => {
    if (inputValue) {
      navigator.clipboard
        .writeText(inputValue)
        .then(() => {
          setIsTooltipVisible(true);
          setTimeout(() => {
            setIsTooltipVisible(false);
          }, 1000);
        })
        .catch(() => {
          console.error('Failed to copy!');
        });
    }
  };

  const handleShowWebhook = () => {
    if (webhookUrl && webhookUrl.trim()) {
      setIsWebhookVisible(true);  
      setIsWebhookCreated(true);
      setModalMessage(strategyId ? 'Webhook received successfully' : 'Webhook URL set successfully.');
      setModalType('success');
    } else {
      setModalMessage('You must save all settings before creating a webhook!');
      setModalType('error');
    }
    setIsModalOpen(true);
  };

  return (
    <div className="w-full flex-col items-start bg-grey-main md:py-10 py-5 rounded-lg h-auto md:px-16 px-3">
      <ul className="flex w-full justify-between gap-x-3 md:gap-x-0">
        <div className="flex flex-col w-3/5">
          <li className="li_style before:top-5">
            <div className="relative">
              <input
                value={isWebhookCreated ? inputValue : ''} 
                onChange={(e) => setInputValue(e.target.value)} 
                className="p-3 w-full text-[10px] md:text-base rounded-md border border-grey-main md:mb-5 mb-2 md:pr-16"
                placeholder="Enter webhook URL"
              />
              {isWebhookCreated && (
                <svg
                  onClick={copyToClipboard}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="md:size-6 size-4 absolute right-3 top-3 cursor-pointer text-blue-900 -scale-x-100"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 8.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v8.25A2.25 2.25 0 0 0 6 16.5h2.25m8.25-8.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-7.5A2.25 2.25 0 0 1 8.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 0 0-2.25 2.25v6"
                  />
                </svg>
              )}
               {isTooltipVisible && (
                 <span className="absolute left-1/2 transform -translate-x-1/2 text-base top-[-35px] text-red-600 px-2 py-1 rounded-md font-semibold">
                 Copy success!
               </span>
              )}
            </div>
            <button
              onClick={handleShowWebhook}
              className="text-[10px] md:text-base bg-btn-green hover:bg-[#37bd54] font-semibold md:py-3 py-1.5 md:px-4 px-2 rounded-full xl:w-1/2 w-auto"
            >
              {strategyId ? 'Show Webhook' : 'Create Webhook'}
            </button>
          </li>
        </div>
        <li className="w-2/5">
          <div className="flex text-[9px] md:text-lg text-center justify-center text-purple-circle font-bold">
            You must complete all the <br /> information above in order <br />
            to create a webhook.
          </div>
        </li>
      </ul>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        message={modalMessage}
        type={modalType}
      />
    </div>
  );
};

export default WebHook;
