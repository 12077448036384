import React from 'react';

const PasswordUpdatedModal = ({ isOpen, onClose }) => {
	if (!isOpen) return null;
	return (
		<div>
			<div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 font-poppins">
				<div className="relative h-auto w-3/4 md:w-1/2 xl:w-1/3 py-4 px-8 md:p-10 bg-white rounded-lg">
					{/* Close Button */}
					<button
						className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
						onClick={onClose}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6 m-4"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>

					<div className="flex justify-center items-center ">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="#28CE4C"
							className="size-28"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
							/>
						</svg>
					</div>

					{/* Message */}
					<div className="flex flex-col w-full justify-center mt-4">
						<h1 className='text-center text-[11px] md:text-sm xl:text-lg font-bold mb-3'>Password Updated !</h1>
						<p className="text-center text-[9px] md:text-xs xl:text-base">
							Your password has been changed successfully. <br /> Use your new password
							to log in.
						</p>
					</div>

					{/* Close Button */}
					<button
								onClick={onClose}
									className="relative bg-[#9DACFF] mt-8 text-center px-4 py-2 w-full flex justify-between rounded-md border border-mc_light_grey disabled:bg-mc_light_grey"
								
								>
									<p className="flex-1 text-center text-black">
										Log in
									</p>

									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={2}
										stroke="currentColor"
										className="size-6 absolute right-0 "
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="m8.25 4.5 7.5 7.5-7.5 7.5"
										/>
									</svg>
								</button>
				</div>
			</div>
		</div>
	);
};

export default PasswordUpdatedModal;
