import React, { useState } from "react";

const BarChart = () => {
  const [year, setYear] = useState(2024);

  const data = {
    2024: [50, 30, 70, 85, 100, 65, 40, 75, 90, 110, 95, 45],
    2025: [60, 40, 80, 90, 110, 70, 50, 80, 95, 120, 100, 55],
    // Add more years as needed
  };

  const handleChange = (event) => {
    setYear(Number(event.target.value)); // Ensure year is a number
  };

  // Check if there's data for the selected year
  const hasData = data[year] && data[year].length > 0;

  // Calculate the maximum value from the current year's data if data exists
  const maxValue = hasData ? Math.max(...data[year]) : 0;

  // The height of the chart in pixels
  const chartHeight = 200;

  return (
    <div className="p-10 bg-white rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-16">
        <h2 className="md:text-xl text-sm font-bold text-purple-700">
          Monthly Income Report
        </h2>
        <select
          className="border border-[#a828c5] rounded-3xl py-2 px-6"
          value={year}
          onChange={handleChange}
          title="Sort By Year"
        >
          <option value={2024}>2024</option>
          <option value={2025}>2025</option>
          <option value={2026}>2026</option>
          {/* Add more years here */}
        </select>
      </div>
      <div className="relative h-64">
        {/* Display "No Data" message if there's no data */}
        {!hasData && (
          <div className="absolute inset-0 flex items-center z-30 justify-center text-gray-500 text-3xl font-bold">
            No Data
          </div>
        )}
        {/* Horizontal Lines */}
        {[20, 40, 60, 80, 100, 120, 140].map((lineValue) => (
          <div
            key={lineValue}
            className="absolute left-0 right-0 border-t boder-dashed border-gray-200 z-20 "
            style={{ bottom: `${(lineValue / 100) * chartHeight}px` }}
          ></div>
        ))}
        {/* Chart Bars */}
        {hasData && (
          <div className="flex md:justify-between items-end h-full">
            {data[year].map((value, index) => (
              <div key={index} className="flex flex-col items-center group">
                <div
                  className="md:w-3 w-1.5 bg-gradient-to-b from-[#b946f4] to-[#8a2dce] rounded-t-md z-30 relative"
                  style={{ height: `${(value / maxValue) * chartHeight}px` }}
                >
                  {/* Hover Circle */}
                  <div className="absolute w-6 h-6 bg-purple-500 rounded-full opacity-0 group-hover:opacity-50 transition-opacity duration-300 transform -translate-x-1/2 left-1/2 -top-2"></div>
                </div>
                <span className="md:text-sm text-[8px] mt-2 mr-1 text-gray-500">
                  {index + 1}/{year.toString().slice(-2)}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BarChart;
