import api from './index';

export const getErrorsData = async (
  token,
  pageIndex,
  pageSize,
  filters = []
) => {
  try {
    const response = await api.post(
      '/api/error/search',
      {
        filter: filters,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.successful) {
      return response.data.data;
    } else {
      return { data: null, error: response.data.error.message }; 
    }
  } catch (error) {
    return { data: null, error: error.message || 'Request failed' }; 
  }
};

export const getStatisticsError = async (token) => {
  try {
    const response = await api.get(`/api/error/statistics`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error?.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to fetch customer data:',
      error.response ? error.response.data : error.message || error
    );
    throw error;
  }
};


