import React from "react";

const ItemsPerPageDropdown = ({ itemsPerPage, handleItemsPerPageChange, totalItems }) => {
  if (totalItems === 0) {
    return <span></span>
  }

  return (
    <div className="mt-4 flex justify-center items-center">
      <label
        htmlFor="itemsPerPage"
        className="mr-2 flex md:text-base text-[7px]"
      >
        Items per page:
      </label>
      <select
        id="itemsPerPage"
        value={itemsPerPage}
        onChange={handleItemsPerPageChange}
        className="border rounded md:p-2 p-0.5 md:text-base text-[9px]"
      >
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
    </div>
  );
};

export default ItemsPerPageDropdown;
