import api from "./index";

export const getRegIds = async (token, pageIndex, pageSize) => {
  try {
    const response = await api.get("/api/agent/registrationids", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        pageIndex,
        pageSize
      },
    });
    if (response.data.successful) {
      return response.data.data;
    } else {
      console.error("Error fetching data:", response.data.error.message);
      return [];
    }
  } catch (error) {
    return [];
  }
};


export const createRegistrationId = async (token) => {
  if (!token) {
    return { account: null, statusCode: 401 }; 
  }

  try {
    const response = await api.post(
      '/api/registrationid',
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`, 
        },
      }
    );
    return response.data;
  } catch (error) {
    return {
      successful: false,
      error: { message: error.response?.data?.error?.message || error.message || "Something went wrong" },
    };
  }
};

export const createRegistrationIdByAdmin = async (token, agentId) => {
  if (!token) {
    return { account: null, statusCode: 401 };
  }

  if (!agentId) {
    return { successful: false, error: { message: "Agent ID is required" } };
  }

  try {
    const response = await api.post(
      `/api/registrationid/as/${agentId}`,
      { agentId },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return {
      successful: false,
      error: { message: error.response?.data?.error?.message || error.message || "Something went wrong" },
    };
  }
};


export const fetchRegIds = async (
  pageIndex,
  pageSize,
  token,
  status
) => {
  try {
    const filters = [];

    if (status) {
      filters.push({
        name: 'status',
        value: status,
      });
    }

    const response = await api.post(
      '/api/registrationid/search',
      {
        filter: filters,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return {
      successful: false,
      error: {
        message:
          error.response?.data?.error?.message ||
          error.message ||
          'Something went wrong',
      },
    };
  }
};

export const getStatisticsRegId = async (token) => {
  try {
    const response = await api.get(`/api/registrationid/statistics`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error?.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to fetch id data:',
      error.response ? error.response.data : error.message || error
    );
    throw error;
  }
};