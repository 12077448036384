import React, { useState, useRef, useEffect } from "react";

const DropdownStatus = ({ options, selected, onSelect, className, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => setIsOpen(!isOpen);
  const handleSelect = (value) => {
    onSelect(value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <button
        type="button"
        onClick={handleToggle}
        className={`w-full rounded-full flex items-center justify-between px-2 py-0.5 text-md disabled:cursor-not-allowed ${
          isOpen ? "border-indigo-500" : ""
        }`}
        disabled={disabled}
      >
        <span className="flex-1 text-center">
          {selected.label || "Select an option"}
        </span>
        <span
          className={`ml-2 transition-transform ${isOpen ? "rotate-180" : ""}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="md:w-5 md:h-5 w-3 h-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </span>
      </button>
      {isOpen && (
        <ul className="absolute z-10 mt-1 w-full py-1 rounded-md shadow-lg bg-white border border-gray-300">
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => !option.disabled && handleSelect(option.value)} 
              className={`px-4 md:py-1 py-1 cursor-pointer text-center text-black mx-2 rounded-md my-1 ${
                option.value === selected.value ? "bg-mc_light_grey " : "bg-white hover:bg-gray-200"
              } ${option.disabled ? "cursor-not-allowed" : ""}`}
             
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownStatus;
