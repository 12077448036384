import api from './index';

export const getAgentData = async (token, pageIndex, pageSize) => {
  try {
    if (!token) {
      return { account: null, statusCode: 401 };
    }
    const response = await api.get('/api/agent/all', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        pageIndex,
        pageSize,
      },
    });

    if (response.data.successful) {
      return response.data.data;
    } else {
      return { data: null, error: response.data.error.message };
    }
  } catch (error) {
    return { data: null, error: error.message || 'Request failed' };
  }
};

export const fetchAgentsByCountry = async (
  pageIndex,
  pageSize,
  token,
  filters = []
) => {
  try {
    const response = await api.post(
      '/api/agent/search',
      {
        filter: filters,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return {
      successful: false,
      error: {
        message:
          error.response?.data?.error?.message ||
          error.message ||
          'Something went wrong',
      },
    };
  }
};

export const getAgentById = async (agentId, token) => {
  try {
    if (!token) {
      return { account: null, statusCode: 401 };
    }
    if (!agentId) {
      throw new Error('agentId is required');
    }
    const response = await api.get(`/api/agent/${agentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to fetch agent data:',
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const updateAgentById = async (agentId, updateData, token) => {
  try {
    if (!token) {
      return { account: null, statusCode: 401 };
    }

    if (!agentId) {
      throw new Error('agentId is required');
    }

    if (!updateData) {
      throw new Error('updateData is required');
    }

    const response = await api.put(`/api/agent/${agentId}`, updateData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to update agent data:',
      error.response ? error.response.data : error.message
    );
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
    throw error;
  }
};

export const getAgentProfileInfo = async (token) => {
  try {
    if (!token) {
      return { account: null, statusCode: 401 };
    }
    const response = await api.get('/api/agent', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to fetch agent data:',
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const updateAgentProfile = async (updateData, token) => {
  try {
    if (!token) {
      return { account: null, statusCode: 401 };
    }

    if (!updateData) {
      throw new Error('updateData is required');
    }

    const response = await api.put('/api/agent', updateData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to update agent data:',
      error.response ? error.response.data : error.message
    );
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
    throw error;
  }
};

export const createAgent = async (accountId, agentData, token) => {
  try {
    if (!accountId) {
      console.error('No accountId provided');
      return null;
    }

    const response = await api.post(
      '/api/agent',
      {
        accountId,
        ...agentData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.data.successful) {
      return response.data.data;
    } else {
      console.error('Error creating agent:', response.data.error.message);
      return null;
    }
  } catch (error) {
    console.error('Agent creation failed:', error);
    return null;
  }
};

export const deleteAgentById = async (agentId, token) => {
  try {
    if (!agentId) {
      throw new Error('agentId is required');
    }

    const response = await api.delete(`/api/agent/${agentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.successful) {
      return response.data;
    } else {
      return {
        successful: false,
        message: response.data.error.message || 'Unknown error occurred',
      };
    }
  } catch (error) {
    console.error(
      'Failed to delete agent data:',
      error.response ? error.response.data : error.message
    );
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
    return { successful: false, message: 'Failed to delete employee data' };
  }
};

export const getStatisticsAgent = async (token) => {
  try {
    const response = await api.get(`/api/agent/statistics`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error?.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to fetch customer data:',
      error.response ? error.response.data : error.message || error
    );
    throw error;
  }
};

export const getDashboardStatisticsToAgent = async (token) => {
  try {
    if (!token) {
      return { successful: false, message: 'Permission denied' };
    }
    const response = await api.get('api/agent/dashboard/statistics', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.successful) {
      return response.data;
    } else {
      throw new Error(response.data.error?.message || 'Unknown error occurred');
    }
  } catch (error) {
    return {
      successful: false,
      message: error.response ? error.response.data : error.message || error,
    };
  }
};

export const getDashboardStatisticsToAdmin = async (token, agentId) => {
  try {
    if (!token) {
      return { successful: false, message: 'Permission denied' };
    }
    const response = await api.get(`api/agent/dashboard/statistics/${agentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.successful) {
      return response.data;
    } else {
      throw new Error(response.data.error?.message || 'Unknown error occurred');
    }
  } catch (error) {
    return {
      successful: false,
      message: error.response ? error.response.data : error.message || error,
    };
  }
};
