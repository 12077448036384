/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { getAccount } from "../../../api/accountApi";
import { AccountType } from "../../../static/enums/accountTypeEmum";
import LogoMars from '../../../static/img/LogoMars.png'
import MarsCapitaHeader from '../../../static/img/MarsCapitaHeader.png'


const AppHeader = ({ toggleSidebar }) => {
  const [accountData, setAccountData] = useState({});

  useEffect(() => {
  const fetchAccountType = async () => {
    try {
      const { account } = await getAccount();
      if (account) {
        const result = account.data
        setAccountData(result);
      }
    } catch (error) {
      setAccountData(null);
    }
  };

  fetchAccountType();
}, []);



  return (
    <div className="md:h-20 fixed top-0 left-0 right-0 z-50 bg-grey-main border-b-1 border flex items-center justify-between px-1 md:px-5 py-2">
      <div className="flex items-center justify-start">
        <button onClick={toggleSidebar} className="xl:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4 md:w-6 md:h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
        <img
          src={MarsCapitaHeader}
          className="md:w-52 w-24"
          alt="Company_Logo"
        />
      </div>
      <div className="flex md:space-x-10 space-x-5">
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="md:size-8 size-4 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
            />
          </svg>
          <div>
            <p className="text-purple-800 text-[7px] md:text-sm">
              {accountData?.firstName} {accountData?.lastName}
            </p>
            <p className="text-[7px] md:text-sm">
    
            {Object.keys(AccountType).find(
          (key) => AccountType[key] === accountData.role?.accountType
        )}
            </p>
          </div>
        </div>
        <img
          src={LogoMars}
          className="md:w-20 w-8"
          alt="Company_Logo"
        />
      </div>
    </div>
  );
};

export default AppHeader;
