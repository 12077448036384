

let socket;

export const connectWebSocket = (token, setHasErrorType1, setHasErrorType2) => {
    socket = new WebSocket('wss://api.marscapita.com/ws/notifications');

    socket.onopen = () => {
        console.log('WebSocket connected');
        if (token) {
            sendMessage({ token });
        }
    };

    socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log('Message received: ', message);
        
        if (message.data && message.data.type === 1) {
            setHasErrorType1(true);
        } else if (message.data && message.data.type === 2) {
            setHasErrorType2(true);
        }
    };

    socket.onclose = () => {
        console.log('WebSocket closed');
    };

    socket.onerror = (error) => {
        console.error('WebSocket error: ', error);
    };
};


export const closeWebSocket = () => {
    if (socket) {
        socket.close();
    }
};

export const sendMessage = (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(message));
    } else {
        console.error('WebSocket is not open');
    }
};
