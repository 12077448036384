import React, { createContext, useState } from 'react';

export const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
    const [hasErrorType1, setHasErrorType1] = useState(false);
    const [hasErrorType2, setHasErrorType2] = useState(false);

    return (
        <ErrorContext.Provider value={{ 
            hasErrorType1, 
            setHasErrorType1, 
            hasErrorType2, 
            setHasErrorType2 
        }}>
            {children}
        </ErrorContext.Provider>
    );
    
};

