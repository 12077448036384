import React, { useState } from 'react';
import Logo from '../static/img/Logo.png';
import { loginUser } from '../api/securityApi';
import TwoFactVereficantionModal from '../components/modals/TwoFactVereficantionModal';
import { AccountType } from '../static/enums/accountTypeEmum';
import usePageTitle from '../hooks/usePageTitle';
import AdminResetPassword from '../components/modals/AdminResetPassword';
import PasswordUpdatedModal from '../components/modals/PasswordUpdatedModal';
import BlockedAccountModal from '../components/modals/BlockedAccountModal';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';


const AdminLoginPage = () => {
	usePageTitle('Log in to your account');
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [requires2FA, setRequires2FA] = useState(false);
	const [isBlockedModal, setIsBlockedModal] = useState(false);
	const [, setErrors] = useState({ email: false, password: false });
	const [userData, setUserData] = useState({
		accessToken: '',
		email: '',
		accountType: '',
	});
	const [isResetModalOpen, setIsResetModalOpen] = useState(false);
	const [isOpenModal, setIsOpenModal] = useState(false);

	const resetPasswordModalHandler = () => {
		setIsResetModalOpen(!isResetModalOpen);
	};
	const handleSubmit = async (e) => {
		e.preventDefault();

		const newErrors = {
			email: !email,
			password: !password,
		};
		setErrors(newErrors);

		if (Object.values(newErrors).some((error) => error)) {
			setError('Please fill in all required fields.');
			return;
		}

		setError('');
		try {
			const result = await loginUser(email, password);

			if (result.successful) {
				const { requires2FA, accountId, accountType, email, accessToken } = result.data;

				if (accountType === AccountType.Customer) {
					setError('Access restricted to admins only.');
					return;
				}

				setUserData({
					accountId,
					email,
					accountType,
				});

				if (requires2FA) {
                    setRequires2FA(true);
                } else if (accessToken) {
                    Cookies.set('accessToken', accessToken.value, {
                        expires: 30,
                        path: '/',
                    });
                    if (accountType === AccountType.Agent) {
                        navigate('/agent/dashboard');
                    } else if (accountType === AccountType.Admin) {
                        navigate('/admin/dashboard');
                    } else if (accountType === AccountType.Employee) {
                        navigate('/employee/dashboard');
                    } else if (accountType === AccountType.Customer) {
                        navigate('/customer/dashboard');
                    }
                }
			} else {
				const status = result.error?.status;
				if (status === 403) {
					setIsBlockedModal(true)
				} else {
					setError(result.error?.message || 'Login failed');
				}
			}
		} catch (error) {
			setError('An unexpected error occurred.');
		}

		setErrors({ email: false, password: false });
	};

	return (
		<div className="bg-mc_purple min-h-screen grid grid-cols-2 font-sans overflow-hidden">
			<div className="col-span-1 flex justify-center items-center">
				<img
					src={Logo}
					alt=""
					className="-ml-10 translate-x-[-13%] max-h-screen"
				/>
			</div>
			<div className="col-span-1 flex justify-center items-center">
				<div className="w-1/2">
					<div className="text-white font-thin text-4xl mb-2">LOGIN</div>
					<form
						onSubmit={handleSubmit}
						className="flex flex-col justify-between"
					>
						<div>
							<input
								id="LoginEmail"
								name="email"
								placeholder="Email"
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className="block w-full h-11 p-3 mb-5 font-normal rounded-[5px] border border-mc_light_grey bg-transparent outline-none text-base placeholder:text-white placeholder:text-base text-white"
							/>
							<input
								id="password"
								name="password"
								placeholder="Password"
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								className="block w-full h-11 p-3 mb-2 font-normal rounded-[5px] border border-mc_light_grey bg-transparent outline-none text-base placeholder:text-white placeholder:text-base text-white"
							/>
							<div>
								<div className="text-red-500 text-base font-base text-center mb-24">
									{error}
								</div>
								<button
									className="w-full h-auto p-2 font-bold rounded-md text-lg text-white outline-none bg-mc_orange"
									type="submit"
								>
									LOGIN
								</button>
							</div>
						</div>
					</form>
					<TwoFactVereficantionModal
						userData={userData}
						isOpen={requires2FA}
						onClose={() => setRequires2FA(false)}
					/>
					<button
						className="text-white font-thin text-base text-center mt-2 w-full"
						onClick={resetPasswordModalHandler}
					>
						Forgot Password
					</button>
					<AdminResetPassword
						isOpen={isResetModalOpen}
						onClose={resetPasswordModalHandler}
						setIsOpenModal={setIsOpenModal}
					/>
				</div>
			</div>
			<PasswordUpdatedModal
				isOpen={isOpenModal}
				onClose={() => {
					setIsOpenModal(!isOpenModal);
					
				}}
			
			/>
			<BlockedAccountModal
				isOpen={isBlockedModal}
				onClose={() => setIsBlockedModal(!isBlockedModal)}
			/>
		</div>
	);
};

export default AdminLoginPage;
