import React, { useEffect, useState } from "react";
import BarDashboard from "./BarDashboard";
import usePageTitle from "../../../hooks/usePageTitle";
import Cookies from "js-cookie";
import { getEmployeeStatistics } from "../../../api/empoyeeApi";

const Dashboard = () => {
  usePageTitle('Dashboard');
  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('accessToken');
      try {
        const response = await getEmployeeStatistics(token);
        if (response) {
          setStatistics({ agents: response.data.totalAgents, customers: response.data.totalCustomers, amount: response.data.totalAmountEarned }); 
        } else {
          console.error('Unexpected agent data format:', response);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } 
    };
  
    fetchData();
  }, []);
  

  return (
    <div className="bg-grey-light lg:p-16 md:p-10 py-4 min-h-screen flex flex-col">
      <h1 className="text-purple-circle font-bold text-base lg:text-3xl md:text-2xl text-center md:text-left mb-5 md:mb-8 lg:mb-10">
        Performance Overview
      </h1>
      <div className="mb-6 lg:mb-20">
        <div className="grid grid-cols-1 mx-4 gap-4 md:gap-8 xl:gap-12 md:grid-cols-2 xl:grid-cols-3 justify-items-center">
          {/* First Card */}
          <div className="bg-[#450892] w-full mx-auto md:w-full lg:w-full text-white rounded-lg shadow-md flex items-center p-4 md:py-8 lg:py-10 space-x-4">
            <div className="w-14 h-14 md:w-24 lg:w-32 md:h-24 lg:h-32 bg-none border-4 md:border-6 lg:border-8  border-[#FF4949] rounded-full flex items-center justify-center">
              <span className="text-white text-center text-xs md:text-sm lg:text-lg font-bold">
                USDT <br /> {statistics?.amount}
              </span>
            </div>
            <div className="flex flex-col ">
              <div className="mt-2 text-xs md:text-sm text-gray-300 flex items-center">
                <p className="text-base md:text-lg">{statistics?.amount}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  className="w-4 md:w-5 lg:w-6 h-4 md:h-5 lg:h-6 ml-3 md:ml-5 lg:ml-7"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 15.75 7.5-7.5 7.5 7.5"
                  />
                </svg>
              </div>
              <p className="mt-2 text-xs md:text-sm lg:text-base font-bold">
                Total Wallet <br /> Amount
              </p>
            </div>
          </div>

          {/* Second Card */}
          <div className="bg-[#450892] w-full mx-auto  md:w-full lg:w-full text-white rounded-lg shadow-md flex items-center p-4 md:py-8 lg:py-10 space-x-4 ">
            <div className="w-14 h-14 md:w-24 lg:w-32 md:h-24 lg:h-32 bg-none border-4 md:border-6 lg:border-8 border-[#FF4949] rounded-full flex items-center justify-center">
              <span className="text-white text-center text-sm md:text-sm lg:text-lg font-bold">
              {statistics?.customers}
              </span>
            </div>
            <div className="flex flex-col ">
              <p className="text-base md:text-lg">{statistics?.customers}</p>
              <p className="mt-2 text-xs md:text-sm lg:text-base font-bold">
                Total <br /> Customer
              </p>
            </div>
          </div>

          {/* Third Card */}
          <div className="bg-[#450892] mx-auto w-full lg:w-full text-white rounded-lg shadow-md flex items-center p-4 md:py-8 lg:py-10 space-x-4">
            <div className="w-14 h-14 md:w-24 lg:w-32 md:h-24 lg:h-32 bg-none border-4 md:border-6 lg:border-8 border-[#FF4949] rounded-full flex items-center justify-center">
              <span className="text-white text-center text-sm md:text-sm lg:text-lg font-bold">
              {statistics?.agents}
              </span>
            </div>
            <div className="flex flex-col">
              <p className="text-base md:text-lg">{statistics?.agents}</p>
              <p className="mt-2 text-xs md:text-sm lg:text-base font-bold">
                Total <br /> Agent
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <BarDashboard />
      </div>
    </div>
  );
};

export default Dashboard;
