import React, { useState, useEffect, useMemo } from 'react';
import DropdownSort from '../../../shared/DropDownSort';
import CustomerTableHead from './CustomerTableHead';
import ItemsPerPageDropdown from '../../../shared/ItemsPerPageDropdown';
import PaginationControls from '../../../shared/PaginationControls';
import LoadingSkeletonCustomer from './LoadingSkeletonCustomer';
import CustomerTableRow from './CustomerTableRow';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchCustomers, getStatistics } from '../../../../api/customerApi';
import Cookies from 'js-cookie';
import usePageTitle from '../../../../hooks/usePageTitle';
import { AccountType } from '../../../../static/enums/accountTypeEmum';
import { getAllCountries } from '../../../../api/countryApi';
import { getAgentData } from '../../../../api/agentApi';
import Status from '../../../../static/enums/statusEnum';
import { CustomerGroup } from '../../../../static/enums/customerGroupEnum';

const CustomerList = ({ accountType }) => {
  usePageTitle('Customer List');

  const [customers, setCustomers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [agentOptions, setAgentOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [statistic, setStatistic] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [initialCustomers, setInitialCustomers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const pageIndex = parseInt(searchParams.get('pageIndex'), 10) || 1;
    const country = searchParams.get('country');
    const agent = searchParams.get('agent');
    const status = searchParams.get('status');
    const group = searchParams.get('group');
    const month = searchParams.get('month');

    setCurrentPage(pageIndex);

    if (country) {
      const foundCountry = countryOptions.find(
        (option) => option.value === country
      );
      setSelectedCountry(
        foundCountry ? foundCountry : { value: country, label: country }
      );
    } else {
      setSelectedCountry(null);
    }

    if (agent) {
      const foundAgent = agentOptions.find((option) => option.value === agent);
      setSelectedAgent(
        foundAgent ? foundAgent : { value: agent, label: agent }
      );
    } else {
      setSelectedAgent(null);
    }

    if (status) {
      const foundStatus = Status.find(
        (option) => option.value === parseInt(status, 10)
      );
      setSelectedStatus(
        foundStatus ? foundStatus : { value: status, label: status }
      );
    } else {
      setSelectedStatus(null);
    }

    if (group) {
      const foundGroup = Object.keys(CustomerGroup).find(
        (key) => CustomerGroup[key] === parseInt(group, 10)
      );

      setSelectedGroup(
        foundGroup
          ? { value: CustomerGroup[foundGroup], label: foundGroup }
          : { value: group, label: group }
      );
    } else {
      setSelectedGroup(null);
    }

    if (month) {
      const foundMonth = monthOptions.find((option) => option.value === month);
      setSelectedMonth(
        foundMonth
          ? foundMonth
          : { value: foundMonth.value, label: foundMonth.label }
      );
    } else {
      setSelectedMonth('');
    }
  }, [searchParams, agentOptions, countryOptions]);

  const getMonthYearString = (monthYear) => {
    const [month, year] = monthYear.split(/(\d{4})/).filter(Boolean);
    const monthIndex = new Date(Date.parse(month + ' 1, 2020')).getMonth() + 1;
    return `${year}-${monthIndex.toString().padStart(2, '0')}`;
  };

  const generateMonthsForRange = () => {
    const minDate = new Date(2024, 4, 1);
    const maxDate = new Date();

    const startYear = minDate.getFullYear();
    const endYear = maxDate.getFullYear();

    let allMonths = [{ value: 'all', label: 'All month' }];

    for (let year = endYear; year >= startYear; year--) {
      for (let month = 11; month >= 0; month--) {
        const date = new Date(year, month);

        if (year === 2024 && month < 4) continue;

        if (year === endYear && month > maxDate.getMonth()) continue;

        allMonths.push({
          value: `${date.toLocaleString('en-GB', { month: 'short' })}${year}`,
          label: `${date.toLocaleString('en-GB', { month: 'short' })} ${year}`,
        });
      }
    }

    return allMonths;
  };
  const monthOptions = generateMonthsForRange();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }

      const filters = {
        statuses:
          selectedStatus && selectedStatus?.value !== 'all'
            ? [selectedStatus.value]
            : [2, 3],
      };

      if (
        selectedCountry &&
        selectedCountry?.value &&
        selectedCountry?.value !== 'all'
      ) {
        filters.countryIds = [selectedCountry.value];
      }

      if (
        selectedAgent &&
        selectedAgent?.value &&
        selectedAgent?.value !== 'all'
      ) {
        filters.agentIds = [selectedAgent.value];
      }

      if (
        selectedGroup &&
        selectedGroup?.value &&
        selectedGroup?.value !== 'all'
      ) {
        filters.groups = [selectedGroup.value];
      }

      if (
        selectedMonth &&
        selectedMonth?.value !== '' &&
        selectedMonth?.value !== 'all'
      ) {
        filters.datetimesInserted = [getMonthYearString(selectedMonth?.value)];
      }

      try {
        const data = await fetchCustomers(
          currentPage - 1,
          itemsPerPage,
          token,
          filters
        );

        if (data.successful) {
          setCustomers(data.data.data || []);
          setInitialCustomers(data.data.data || []);
          setTotalItems(data.data.paging.totalCount || 0);
        } else {
          console.error('Error fetching data:', data.error.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    currentPage,
    itemsPerPage,
    selectedCountry,
    selectedAgent,
    selectedStatus,
    selectedGroup,
    selectedMonth,
  ]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getAllCountries();
        const countries = response.map((country) => ({
          value: country.id,
          label: country.name,
        }));
        setCountryOptions([
          { value: 'all', label: 'All Countries' },
          ...countries,
        ]);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const token = Cookies.get('accessToken');
        if (!token) {
          console.error('No token found');
          return;
        }
        const response = await getAgentData(token, 0, 100);
        const agents = response.data.map((agent) => ({
          label: `${agent.account.firstName || 'Unknown First Name'} ${
            agent.account.lastName || 'Unknown Last Name'
          }`,
          value: agent.id,
        }));
        setAgentOptions([{ label: 'All Agents', value: 'all' }, ...agents]);
      } catch (error) {
        console.error('Error fetching agents:', error);
      }
    };

    fetchAgents();
  }, []);

  useEffect(() => {
    const fetchStatistics = async () => {
      const token = Cookies.get('accessToken');
      try {
        const result = await getStatistics(token);
        if (result && result.successful) {
          setStatistic({
            active: result.data.active,
            blocked: result.data.blocked,
          });
        } else {
          console.error('Error fetching symbols:', result.error.message);
        }
      } catch (error) {
        console.error('Error fetching symbols:', error);
      }
    };

    fetchStatistics();
  }, []);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const filteredStatus = useMemo(
    () => [
      { value: 'all', label: 'All Statuses' },
      ...Status.filter((status) => [3, 2].includes(status.value)),
    ],
    []
  );

  const customerGroupOptions = useMemo(
    () => [
      { value: 'all', label: 'All Groups' },
      ...Object.entries(CustomerGroup).map(([key, value]) => ({
        value: value,
        label: key,
      })),
    ],
    []
  );

  useEffect(() => {
    if (selectedGroup) {
      if (selectedGroup.value === 'all') {
        if (selectedGroup.label !== 'All Groups') {
          setSelectedGroup({ value: 'all', label: 'All Groups' });
        }
      } else {
        const selectedOption = customerGroupOptions.find(
          (option) => option.value === selectedGroup.value
        );

        if (selectedOption && selectedGroup.label !== selectedOption.label) {
          setSelectedGroup(selectedOption);
        }
      }
    }
  }, [selectedGroup, customerGroupOptions]);

  useEffect(() => {
    if (selectedStatus) {
      const selectedOption = filteredStatus.find(
        (option) => option.value === selectedStatus.value
      );
      if (selectedOption) {
        setSelectedStatus(selectedOption);
      }
    }
  }, [selectedStatus, filteredStatus]);

  const currentData = customers;

  const hasRecords = currentData?.length > 0;

  useEffect(() => {
    if (selectedAgent) {
      const selectedOption = agentOptions.find(
        (option) => option.value === selectedAgent.value
      );
      if (selectedOption) {
        setSelectedAgent(selectedOption);
      }
    }
  }, [selectedAgent, agentOptions]);

  const handleRowSelect = (index) => {
    const pageIndex = (currentPage - 1) * itemsPerPage + index;
    if (selectedRows.includes(pageIndex)) {
      setSelectedRows(selectedRows.filter((i) => i !== pageIndex));
    } else {
      setSelectedRows([...selectedRows, pageIndex]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAll(false);
    } else {
      const allIndexes = currentData.map(
        (_, index) => (currentPage - 1) * itemsPerPage + index
      );
      setSelectedRows(allIndexes);
      setSelectAll(true);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSearchParams({
      pageIndex: newPage,
      country: selectedCountry?.value || '',
      agent: selectedAgent?.label || '',
      status: selectedStatus?.value || '',
      group: selectedGroup?.value || '',
      month: selectedMonth?.value || '',
    });
    setSelectAll(false);
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
    console.log(selectedMonth);
    setSearchParams({
      pageIndex: 1,
      month: selectedOption?.value || '',
      country: selectedCountry?.value || '',
      agent: selectedAgent?.value || '',
      status: selectedStatus?.value || '',
      group: selectedGroup?.value || '',
    });
    setCurrentPage(1);
  };

  const handleItemsPerPageChange = (event) => {
    const newPageSize = Number(event.target.value);
    setItemsPerPage(newPageSize);
    setCurrentPage(1);
  };

  const handleAgentChange = (selectedOption) => {
    setSelectedAgent(selectedOption);
    setSearchParams({
      pageIndex: 1,
      country: selectedCountry?.value || '',
      agent: selectedOption?.value || '',
      status: selectedStatus?.value || '',
      group: selectedGroup?.value || '',
      month: selectedMonth?.value || '',
    });
    setCurrentPage(1);
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSearchParams({
      pageIndex: 1,
      country: selectedOption?.value || '',
      agent: selectedAgent?.value || '',
      status: selectedStatus?.value || '',
      group: selectedGroup?.value || '',
      month: selectedMonth?.value || '',
    });
    setCurrentPage(1);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
    setSearchParams({
      pageIndex: 1,
      country: selectedCountry?.value || '',
      agent: selectedAgent?.value || '',
      status: selectedOption?.value || '',
      group: selectedGroup?.value || '',
      month: selectedMonth?.value || '',
    });
    setCurrentPage(1);
  };

  const handleGroupChange = (selectedOption) => {
    setSelectedGroup(selectedOption);
    setSearchParams({
      pageIndex: 1,
      country: selectedCountry?.value || '',
      agent: selectedAgent?.value || '',
      status: selectedStatus?.value || '',
      group: selectedOption?.value || '',
      month: selectedMonth?.value || '',
    });
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setSelectedCountry(null);
    setSelectedAgent(null);
    setSelectedStatus(null);
    setSelectedGroup(null);
    setSelectedMonth(null);
    setCustomers(initialCustomers); 
    setSortOrder(null);
    setSearchParams({});
    setCurrentPage(1);
  };

  const handleSortByWallet = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);

    const sortedCustomers = [...customers].sort((a, b) =>
      newSortOrder === 'asc'
        ? a.currentWalletAmount - b.currentWalletAmount
        : b.currentWalletAmount - a.currentWalletAmount
    );
    setCustomers(sortedCustomers);
  };


  const handleClick = () => {
    navigate('/customer/add');
  };

  return (
    <div className="bg-grey-light md:p-16 p-4 min-h-screen">
      <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
        Customer Listing
      </h1>
      <div className="flex-col justify-end items-center space-y-5 py-2 px-1 xl:px-12">
        <div className="flex justify-end">
          <button
            className="flex justify-center md:text-xs xl:text-base text-[8px] items-end disabled:bg-mc_light_grey"
            onClick={handleClick}
            disabled={accountType === AccountType.Employee}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="size-7"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="currentColor"
              className="md:size-5 size-3 ml-0.5 rounded-full text-white bg-red-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </button>
        </div>
        <div className="flex justify-end md:space-x-5 w-full space-x-1 items-center">
          <span className="font-semibold md:text-xs text-[6px]">
            Active({statistic?.active})
          </span>
          <span className="font-semibold md:text-xs text-[6px]">
            Blocked({statistic?.blocked})
          </span>
          <div className="xl:w-1/8">
            <DropdownSort
              options={filteredStatus}
              selected={selectedStatus}
              onChange={handleStatusChange}
              title="Status"
            />
          </div>
          <div className="xl:w-1/8">
            <DropdownSort
              options={customerGroupOptions}
              selected={selectedGroup}
              onChange={handleGroupChange}
              title="Group"
            />
          </div>
          <div className="xl:w-1/8">
            <DropdownSort
              options={agentOptions}
              selected={selectedAgent}
              onChange={handleAgentChange}
              title="Agent"
            />
          </div>
          <div className="xl:w-1/8">
            <DropdownSort
              options={countryOptions}
              selected={selectedCountry}
              onChange={handleCountryChange}
              title="Country"
            />
          </div>
          <div className="xl:w-1/8">
            <DropdownSort
              options={monthOptions}
              selected={selectedMonth}
              onChange={handleMonthChange}
              title={'Month'}
            />
          </div>
          <button onClick={handleSortByWallet}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-8 p-2 text-white rounded-full bg-[#4C009B]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
              />
            </svg>
          </button>
          <button onClick={handleResetFilters}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="md:size-6 size-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-8">
        <div className="w-full items-start bg-[#ffffff] md:py-10 py-2 rounded-lg md:px-12 px-1.5">
          <CustomerTableHead
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
          />

          {isLoading ? (
            <LoadingSkeletonCustomer itemsPerPage={itemsPerPage} />
          ) : hasRecords ? (
            currentData.map((item, index) => (
              <CustomerTableRow
                key={item.id}
                item={item}
                index={(currentPage - 1) * itemsPerPage + index}
                isSelected={selectedRows.includes(
                  (currentPage - 1) * itemsPerPage + index
                )}
                handleRowSelect={handleRowSelect}
              />
            ))
          ) : (
            <div className="text-center pt-10">No records available</div>
          )}
          <div className="flex justify-end space-x-5">
            <ItemsPerPageDropdown
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              totalItems={totalItems}
            />
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
