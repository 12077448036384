import React, { useEffect, useMemo, useState } from 'react';
import RegIdHead from './RegIdHead';
import RegIdRow from './RegIdRow';
import {
  fetchRegIds,
  getStatisticsRegId,
} from '../../../../api/registrationId';
import Cookies from 'js-cookie';
import PaginationControls from '../../../shared/PaginationControls';
import ItemsPerPageDropdown from '../../../shared/ItemsPerPageDropdown';
import StatusRegId from '../../../../static/enums/regIdEnum';
import StatusSort from './StatusSort';
import { useSearchParams } from 'react-router-dom';
import LoadingSkeletonRegId from './LoadingSkeletonRegId';
import usePageTitle from '../../../../hooks/usePageTitle';

const RegId = () => {
  usePageTitle('Registration Id List');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [statusAmount, setStatusAmount] = useState(null);

  useEffect(() => {
    const pageIndex = parseInt(searchParams.get('pageIndex'), 10) || 1;
    const status = searchParams.get('status');
    setCurrentPage(pageIndex);

    if (status) {
      const foundStatus = StatusRegId.find(
        (option) => option.value === parseInt(status, 10)
      );
      setSelectedStatus(
        foundStatus ? foundStatus : { value: status, label: status }
      );
    } else {
      setSelectedStatus(null);
    }
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('accessToken');
      setIsLoading(true);
      try {
        const response = await fetchRegIds(
          currentPage - 1,
          itemsPerPage,
          token,
          selectedStatus?.value !== 'all' ? selectedStatus?.value : ''
        );
        if (response.successful && Array.isArray(response.data.data)) {
          setData(response.data.data);
          setTotalItems(response.data.paging?.totalCount || 0);
        } else {
          console.error('Unexpected data format:', response);
          setData([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [itemsPerPage, currentPage, selectedStatus]);

  useEffect(() => {
    const statistics = async () => {
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }
      try {
        const data = await getStatisticsRegId(token);
        setStatusAmount(data);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };
    statistics();
  }, []);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const currentData = data;

  const hasRecords = currentData?.length > 0;

  const statusIds = useMemo(
    () => [{ value: 'all', label: 'All Statuses' }, ...StatusRegId],
    []
  );

  useEffect(() => {
    if (selectedStatus) {
      const selectedOption = statusIds.find(
        (option) => option.value === selectedStatus.value
      );
      if (selectedOption) {
        setSelectedStatus(selectedOption);
      }
    }
  }, [selectedStatus, statusIds]);

  const handleRowSelect = (index) => {
    const actualIndex = (currentPage - 1) * itemsPerPage + index;
    if (selectedRows.includes(actualIndex)) {
      setSelectedRows(selectedRows.filter((i) => i !== actualIndex));
    } else {
      setSelectedRows([...selectedRows, actualIndex]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAll(false);
    } else {
      const allIndexes = currentData.map(
        (_, index) => (currentPage - 1) * itemsPerPage + index
      );
      setSelectedRows(allIndexes);
      setSelectAll(true);
    }
  };

  const handleStatusChange = (selectedOption) => {
    setSearchParams({ pageIndex: 1, status: selectedOption?.value || '' });
    setCurrentPage(1);
    setSelectAll(false);
  };

  const handleResetFilters = () => {
    setSelectedStatus(null);
    setCurrentPage(1);
    setSearchParams({});
    setSelectAll(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSearchParams({
      pageIndex: newPage,
      status: selectedStatus?.value || '',
    });
    setSelectAll(false);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
    setSelectAll(false);
  };

  return (
    <div className="bg-grey-light md:p-16 p-4 min-h-screen">
      <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
        Registration ID Record
      </h1>
      <div className="flex justify-end items-center md:space-x-5 space-x-5 py-2 md:px-12 px-5">
        <span className="font-semibold md:text-xs text-[6px]">
          Registered({statusAmount?.used})
        </span>
        <span className="font-semibold md:text-xs text-[6px]">
          Pending({statusAmount?.pending})
        </span>
        <span className="font-semibold md:text-xs text-[6px]">
          Expired({statusAmount?.expired})
        </span>
        <div className="w-1/6">
          <StatusSort
            options={statusIds}
            selected={selectedStatus}
            onChange={handleStatusChange}
            title={'Status'}
          />
        </div>
        <button onClick={handleResetFilters}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="md:size-6 size-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
            />
          </svg>
        </button>
      </div>
      <div className="flex flex-col justify-center items-center gap-8">
        <div className="w-full flex-col items-start bg-[#ffffff] md:py-10 py-2 rounded-lg md:px-12 px-1.5">
          <RegIdHead selectAll={selectAll} handleSelectAll={handleSelectAll} />

          {isLoading ? (
            <LoadingSkeletonRegId itemsPerPage={itemsPerPage} />
          ) : hasRecords ? (
            currentData.map((item, index) => (
              <RegIdRow
                key={index}
                item={item}
                index={(currentPage - 1) * itemsPerPage + index}
                isSelected={selectedRows.includes(
                  (currentPage - 1) * itemsPerPage + index
                )}
                handleRowSelect={handleRowSelect}
              />
            ))
          ) : (
            <div className="text-center pt-10">No records available</div>
          )}
          <div className="flex justify-end space-x-5">
            <ItemsPerPageDropdown
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              totalItems={totalItems}
            />
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegId;
