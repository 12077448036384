import React, { useState } from 'react';
import { EntryType, StrategyStatus } from '../../../static/enums/orderEnums';
import ToggleSwitch from '../../additional/ToggleSwitch';
import { updateOrder } from '../../../api/orderApi';
import Cookies from 'js-cookie';
import StatusChangeModal from './StatusChangeModal';
import Modal from '../../shared/Modal';
import { AccountType } from '../../../static/enums/accountTypeEmum';
import { useNavigate } from 'react-router-dom';

const OrderTableRow = ({
  item,
  index,
  isSelected,
  handleRowSelect,
  accountType,
}) => {
  const [status, setStatus] = useState(
    item.status === StrategyStatus.Active ? true : false
  );

  const [isStatusChangeModal, setIsStatusChangeModal] = useState(false);
  <span
    className="tr_style_order cursor-pointer"
    onClick={() => navigate(`/strategy/${item.strategyId}`)}
  >
    {item.strategyId}
  </span>;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState('success');
  const navigate = useNavigate();

  const handleToggle = () => {
    if (status && accountType === AccountType.Admin) {
      setIsStatusChangeModal(true);
    }
  };

  const handleConfirm = async () => {
    const orderId = item.id;
    const token = Cookies.get('accessToken');

    const result = await updateOrder(token, orderId);

    if (result.successful) {
      setStatus(false);
      setModalMessage('Position closed successfully!');
      setIsModalOpen(true);
    } else {
      setModalMessage(result.error.message);
      setModalType('error');
      setIsModalOpen(true);
    }

    setIsStatusChangeModal(false);
  };

  const handleCancel = () => {
    setIsStatusChangeModal(false);
  };

  return (
    <div
      className={`flex justify-between items-center md:px-6 md:py-4 px-1 py-2 md:my-4 my-2  border border-grey-light rounded-full ${
        isSelected ? 'bg-gray-100' : ''
      }`}
    >
      <div className=""></div>
      <span className="tr_style w-1/7 text-gray-600 font-semibold">{index + 1}</span>
      <span className="tr_style w-1/7">{item.id}</span>
      <span className="tr_style w-1/7">{item.strategy.symbol}</span>
      <span
        className="tr_style w-1/7 cursor-pointer"
        onClick={() => navigate(`/strategy/${item.strategy.id}`)}
      >
        {item.strategy.id}
      </span>
      <span className="tr_style w-1/7">
        {item.datetimeInserted ? (
          <>
            {new Date(item.datetimeInserted).toLocaleDateString('en-GB', {
              year: '2-digit',
              month: 'numeric',
              day: 'numeric',
            })}
            <br />
            {new Date(item.datetimeInserted)
              .toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              })
              .slice(0, 5)}
          </>
        ) : (
          '-'
        )}
      </span>
      <span className="tr_style w-1/7">
        {item.datetimeUpdated && item.entryType === EntryType.API ? (
          <>
            {new Date(item.datetimeUpdated).toLocaleDateString('en-GB', {
              year: '2-digit',
              month: 'numeric',
              day: 'numeric',
            })}
            <br />
            {new Date(item.datetimeUpdated)
              .toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              })
              .slice(0, 5)}
          </>
        ) : (
          '-'
        )}
      </span>
      <span className="tr_style flex items-center w-1/7">
        <ToggleSwitch
          enabled={status}
          setEnabled={handleToggle}
          disabled={accountType === AccountType.Employee}
        />
      </span>
      <StatusChangeModal
        isOpen={isStatusChangeModal}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(!isModalOpen)}
        message={modalMessage}
        type={modalType}
      />
    </div>
  );
};

export default OrderTableRow;
