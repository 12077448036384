import React from 'react';
import Logo from '../static/img/Logo.png';
import CompanyName from '../static/img/MarsCapita.png';
import { Outlet } from 'react-router-dom'; 
import { useLocation } from 'react-router-dom';


const CompanyLogo = () => {
	const location = useLocation();
	let chooseClass=location.pathname.includes('/connect');

	return (
		<div className= {`grid bg-mc_white ${chooseClass ? 'grid-cols-5' : 'grid-cols-3'}` }>
			<div className="bg-mc_purple min-h-screen flex flex-col justify-center items-center col-span-1 ">
				<div>
					<div className="h-auto flex flex-col items-center">
						<img src={Logo} alt="Logo" className="w-4/5" />
						<img
							src={CompanyName}
							alt="MarsCapita"
							className="z-10 w-3/5 -mt-7"
						/>
					</div>
					<div className={`px-5 font-poppins text-center font-medium text-white ${chooseClass ? "text-sm md:text-lg": "text-[10px] md:text-xl"}`}>
						Your Fund | Your Account <br /> Your Profit{' '}
					</div>
				</div>
			</div>
			<div className={`${chooseClass ? 'col-span-4' : 'col-span-2'} flex justify-center items-center`}>
            <Outlet />
			</div>
		</div>
	);
};

export default CompanyLogo;
