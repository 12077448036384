import React from 'react';
import usePageTitle from '../hooks/usePageTitle';

const TermsOfServes = () => {
	usePageTitle('Terms of Service');
    return (
        <div className='font-poppins p-5 text-sm space-y-5'>
            <div>
				<h1 className="font-bold text-xl mb-4">
					Terms of Service (MarsCAPITA)
				</h1>
				<ul className="list-inside">
					<li>
						<h2 className="privacy_h2">1. Introduction</h2>
						<p>
							Welcome to MarsCAPITA, a platform providing secure, automated
							algorithmic trading solutions across multiple financial markets.
							By accessing or using our services, you agree to comply with and
							be bound by these Terms of Use. Please review the following terms
							carefully.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">2. Eligibility</h2>
						<p>To use our services, you must:</p>
						<ul className="privacy_ul">
							<li>Be at least 18 years of age.</li>
							<li>
								Ensure all information provided to MarsCAPITA is accurate and
								current.
							</li>
						</ul>
					</li>
					<li>
						<h2 className="privacy_h2">3. Services </h2>
						<p>
							MarsCAPITA offers algorithmic trading services through API
							integration with supported trading platforms. Our services
							include:
						</p>
						<ul className="privacy_ul">
							<li>
								Automated trading based on predefined strategies across various
								markets (e.g., cryptocurrencies, forex, commodities, indices,
								and digital assets).
							</li>
						</ul>
						<br />
						<p>
							You acknowledge that our algorithms execute trades based on
							predefined strategies, and the outcomes of these trades may vary
							based on market conditions.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">4. Account Setup and API Integration</h2>
						<p>To use MarsCAPITA’s services, you will need to:</p>
						<ul className="privacy_ul">
							<li>
								Provide your trading account credentials or API keys for
								supported platforms, or authorize our platform to extract API
								keys from your exchange trading account.
							</li>
							<li>
								Authorize our platform to execute trades on your behalf using
								these credentials.
							</li>
						</ul>
						<br />
						<p>
							<span className="font-semibold">Note:</span> MarsCAPITA does not
							have direct access to your funds; the platform only facilitates
							trades through the provided API keys or account permissions.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">5. Profit Sharing and Fees</h2>
						<p>MarsCAPITA operates on a performance-based fee structure:</p>
						<ul className="privacy_ul">
							<li>
								We retain a transparent profit-sharing fee on any profits
								exceeding a specified monthly ROI.
							</li>
							<li>
								The fee percentage will be agreed upon during the account setup
								process.
							</li>
							<li>
								Fees are automatically deducted from profits once the agreed ROI
								threshold is exceeded.
							</li>
						</ul>
					</li>
					<li>
						<h2 className="privacy_h2">6. No Guarantee of Returns</h2>
						<p>
							While we aim to achieve a high monthly ROI for our clients, we do
							not guarantee specific profit outcomes. Market conditions can
							result in both gains and losses, and you acknowledge the inherent
							risks involved in trading across any financial markets.
						</p>
						<br />
						<h3 className="privacy_h3">No Warranty and Guarantee</h3>
						<p>
							MarsCAPITA makes no representation, warranty, or guarantee that:
						</p>
						<ul className="privacy_ul_dec">
							<li>
								The trades executed on your behalf will be profitable,
								successful, or meet your expectations.
							</li>
							<li>
								The algorithmic systems used to trade on your behalf will be
								free of errors, defects, interruptions, or delays
							</li>
							<li>
								The platform used for trading will be free from bugs, viruses,
								or other harmful components.
							</li>
							<li>
								Any content, information, or materials obtained through the
								trading services will be accurate, reliable, or complete.
							</li>
						</ul>
						<br />
						<p>
							Furthermore, MarsCAPITA does not guarantee or warrant that any
							defects in the algorithmic trading systems or services will be
							corrected in a timely manner, or at all. By using MarsCAPITA’s
							services, you agree that you understand the risks involved with
							algorithmic trading, including market volatility, unexpected
							losses, and other external factors that can impact trades.
						</p>
						<p>
							MarsCAPITA shall not be liable for any trading losses, errors,
							missed opportunities, or negative outcomes arising from trades
							executed on your behalf.
						</p>
						<p>
							By using MarsCAPITA’s algorithmic trading services, you
							acknowledge that you are aware of and accept the inherent risks of
							trading and that the company provides no guarantees or warranties
							of profitability or success.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">7. Risk Acknowledgment</h2>
						<p>
							By using MarsCAPITA's services, you understand and accept the
							following risks:
						</p>
						<ul className="privacy_ul">
							<li>
								Financial markets (e.g., Cryptocurrencies, forex, commodities,
								indices, digital assets) are volatile and may lead to
								significant losses.
							</li>
							<li>
								While MarsCAPITA's algorithms aim to maximize profits, no
								strategy can eliminate the risk of loss.
							</li>
						</ul>
						<br />
						<p>
							You agree that you are solely responsible for understanding these
							risks and for any financial losses incurred from the use of our
							services.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">8. Data Privacy</h2>
						<p>
							We are committed to protecting your privacy. Any personal data or
							API keys provided will be handled securely and used solely for the
							purpose of providing the trading services. We do not share your
							personal information with third parties except as required by law.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">9. Limitation of Liability</h2>
						<p>
							To the fullest extent permitted by law, MarsCAPITA and its
							affiliates, officers, employees, and agents shall not be liable
							for any:
						</p>
						<ul className="privacy_ul">
							<li>
								Direct, indirect, incidental, or consequential damages arising
								from your use of our services, including but not limited to any
								losses resulting from delays, failure of performance,
								interruptions in service, or issues related to third-party
								exchanges and their API integrations.
							</li>
							<li>
								Financial losses, errors, or security breaches associated with
								third-party exchanges, including but not limited to the
								availability, security, or reliability of their API services.
							</li>
						</ul>
						<br />
						<p>You acknowledge that:</p>
						<ul className="privacy_ul_dec">
							<li>
								MarsCAPITA is not responsible for the accuracy of data,
								transaction failures, or security issues related to third-party
								exchanges.
							</li>
							<li>
								Any disputes or issues arising from your use of such API
								services must be resolved directly with the third-party
								exchange.
							</li>
						</ul>
						<br />
						<p>
							You agree to hold MarsCAPITA harmless from any claims related to
							the risks associated with trading in financial markets.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">10. Agents and Representativest</h2>
						<p>
							MarsCAPITA may appoint authorized agents or representatives to
							recruit users and facilitate the onboarding process. If you were
							introduced to MarsCAPITA through an appointed agent, you
							acknowledge and agree to the following:
						</p>
						<ul className="privacy_ul">
							<li>
								Agents act as intermediaries to introduce users to MarsCAPITA’s
								services but do not have authority to modify or interpret the
								Terns of Service or other policies on behalf of MarsCAPITA.
							</li>
							<li>
								Any fees, incentives, or commissions agreed upon between users
								and agents are independent of MarsCAPITA’s profit-sharing
								structure, unless explicitly stated otherwise.
							</li>
							<li>
								MarsCAPITA is not responsible for any claims, representations,
								or promises made by agents that are not outlined in these Terns
								of Service or other official MarsCAPITA communications.
							</li>
						</ul>
						<br />
						<p>
							Users recruited through agents are subject to the same terms and
							conditions as any other user, and their trading accounts will be
							managed under the same profit-sharing and fee structure.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">11. Indemnification</h2>
						<p>
							By using MarsCAPITA’s algorithmic trading services, you agree to
							indemnify, defend, and hold harmless MarsCAPITA, its affiliates,
							officers, directors, employees, and agents from and against any
							and all claims, liabilities, damages, losses, costs, or expenses
							(including reasonable attorney fees) arising out of or related to:
						</p>
						<ul className="privacy_ul_dec">
							<li>
								Your Use of Algorithmic Trading Services: Any claims related to
								the use of the algorithmic trading services provided by
								MarsCAPITA, including trades executed on your behalf, whether
								based on errors, market conditions, or performance outcomes.
							</li>
							<li>
								Breach of Terms: Your violation of these Terms of Use,
								conditions, or applicable regulations, including any
								misrepresentations or false information provided by you in
								connection with your use of MarsCAPITA’s algorithmic trading
								services.
							</li>
							<li>
								Third-Party Claims: Any claims from third parties arising from
								your use of the algorithmic trading services, including but not
								limited to claims of misrepresentation, improper use, or
								violation of intellectual property rights.
							</li>
							<li>
								Regulatory Compliance: Any failure on your part to comply with
								relevant laws, regulations, or trading rules that apply to your
								use of MarsCAPITA’s algorithmic trading services, including tax
								obligations, anti-money laundering (AML) compliance, and
								reporting obligations.
							</li>
							<li>
								Misuse of the Services: Any misuse of MarsCAPITA’s algorithmic
								trading services, including unauthorized use or illegal
								activities conducted through the trading platform on your
								behalf.
							</li>
						</ul>
						<br />
						<p>
							MarsCAPITA reserves the right, at its own expense, to assume the
							exclusive defense and control of any matter otherwise subject to
							indemnification by you. In such cases, you agree to cooperate with
							MarsCAPITA in asserting any available defenses.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">12. Termination</h2>
						<p>
							MarsCAPITA reserves the right to suspend or terminate your account
							at any time if we determine you have violated these terms, engaged
							in fraudulent activity, or if required by law.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">13. Modifications to the Terms</h2>
						<p>
							MarsCAPITA may update these Terns of Service from time to time.
							Your continued use of the services constitutes acceptance of the
							revised terms.
						</p>
						<p>
							We may revise the Terms at any time without notice by updating
							them on our platform. You should periodically review the Terms to
							ensure you are aware of any changes. Continued use of our services
							after such modifications shall constitute your acceptance of the
							updated Terms.
						</p>
						<p>
							Additionally, we reserve the right, at our sole discretion, to
							discontinue providing the services, or any part thereof, at any
							time without notice.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">14. Governing Law</h2>
						<p>
							These Terns of Service are governed by and construed in accordance
							with the laws of [Singapore].
						</p>
					</li>
				</ul>
			</div>
        </div>
    );
};

export default TermsOfServes;