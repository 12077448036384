import React, { useRef, useEffect, useState } from 'react';

const DropDownCountry = ({
	countries,
	onCountrySelect,
	onClose,
	searchValue,
	setSearchValue,
}) => {
	const dropdownRef = useRef(null);
	const [buttonWidth, setButtonWidth] = useState(0);

	const selectCountryHandler = (e, country) => {
		e.preventDefault();
		onCountrySelect(
			country.imageUrl,
			country.name,
			country.id,
			country.countryCode
		);
		onClose();
		setSearchValue('');

	};

	const handleMouseLeave = (event) => {
		if (
			dropdownRef.current &&
			event.relatedTarget instanceof Node &&
			!dropdownRef.current.contains(event.relatedTarget)
		) {
			onClose();
			setSearchValue('');

		}
	};

	useEffect(() => {
		const button = dropdownRef.current.previousElementSibling;
		if (button) {
			setButtonWidth(button.offsetWidth);
		}
	}, []);

	return (
		<div
			ref={dropdownRef}
			onMouseLeave={handleMouseLeave}
			className={`absolute bg-white py-3 z-50 font-poppins overflow-y-scroll max-h-60`}
			style={{ width: buttonWidth }}
		>
			<div className="w-full">
				<input
					placeholder="Search.."
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					className="block w-full h-auto p-1 text-sm font-normal rounded-[5px] border-none border-mc_light_grey outline-none placeholder:text-sm"
				/>
				<hr className="mb-3" />
				{countries.length > 0 ? (
					countries.map((country) => (
						<button
							key={country.id}
							onClick={(e) => selectCountryHandler(e, country)}
							className="p-2 flex justify-between w-full hover:bg-mc_blue hover:bg-opacity-25"
						>
							<div className="flex items-baseline">
								<img src={country.imageUrl} alt="" className="w-6" />
								<div className="ml-2">{country.name}</div>
							</div>
						</button>
					))
				) : (
					<div className="p-2">No results found</div>
				)}
			</div>
		</div>
	);
};

export default DropDownCountry;
