import React, { useState, useRef, useEffect } from 'react';

const DropDownSimple = ({ options, selectedValue, onChange, name, disable }) => {
	const [isOpen, setIsOpen] = useState(false);
	//const [searchTerm, setSearchTerm] = useState('');
	const dropdownRef = useRef(null);

	const handleSelect = (option) => {
		onChange(option);
		setIsOpen(false);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	//   const filteredOptions = options.filter((option) =>
	//     option.label.toLowerCase().startsWith(searchTerm.toLowerCase())
	//   );

	return (
		<div ref={dropdownRef} className="relative inline-block text-left w-full">
			<div>
				<button
					type="button"
					disabled={disable}
					className="inline-flex justify-between w-full items-center pl-3 pr-1 py-2.5 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:bg-mc_white focus:outline-none"
					onClick={() => setIsOpen(!isOpen)}
				>
					{selectedValue
						? options.find((option) => option.value === selectedValue)?.label ||
						  name
						: name}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={3}
						stroke="currentColor"
						className="size-4"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="m19.5 8.25-7.5 7.5-7.5-7.5"
						/>
					</svg>
				</button>
			</div>

			{isOpen && (
				<div className="absolute mt-2 w-full rounded-md bg-white shadow-lg z-10">
					{/* <div className="px-4 py-2">
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md text-sm"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div> */}
					<ul className="max-h-60 overflow-y-auto text-sm text-gray-700">
						{options.length > 0 ? (
							options.map((option) => (
								<li
									key={option.value}
									onClick={() => handleSelect(option.value)}
									className="cursor-pointer hover:bg-gray-100 px-4 py-2"
								>
									{option.label}
								</li>
							))
						) : (
							<li className="px-4 py-2 text-gray-500">No results found</li>
						)}
					</ul>
				</div>
			)}
		</div>
	);
};

export default DropDownSimple;
