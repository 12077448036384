import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AgentForm from './AgentForm';
import RegIdCreate from '../../AgentPages/RegIdCreate';
import { getAgentById, getDashboardStatisticsToAdmin } from '../../../../api/agentApi';
import Cookies from 'js-cookie';
import usePageTitle from '../../../../hooks/usePageTitle';
import { AccountType } from '../../../../static/enums/accountTypeEmum';
import LogoMars from '../../../../static/img/LogoMars.png';

const AgentProfile = ({ accountType }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/agents');
  };

  usePageTitle('Agent Profile');
  const { agentId } = useParams();
  const [agentData, setAgentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    const fetchAgentData = async () => {
      const token = Cookies.get('accessToken');
      try {
        const data = await getAgentById(agentId, token);
        setAgentData(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching agent data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    if (agentId) {
      fetchAgentData();
    }
  }, [agentId]);

  useEffect(() => {
    const getStatistics = async () => {
      const token = Cookies.get('accessToken');
      try {
        const response = await getDashboardStatisticsToAdmin(token, agentId);
        setDashboardData({
          customers: response.data.totalCustomers,
          prevMonth: response.data.prevMonthAmountEarned,
          totalAmount: response.data.totalAmountEarned,
        });
      } catch (error) {
        console.error(error.response ? error.response.data : error.message);
      }
    };
    getStatistics();
  }, [agentId]);

  if (loading) return <div className="bg-[#f5f5f5]"></div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="bg-grey-light md:p-16 p-4 min-h-screen">
      <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-10 md:mb-10 mb-5">
        Agent Profile
      </h1>
      <div className="px-10">
        <div className="md:flex block w-full justify-between space-x-8 mb-10">
          <div className="bg-[#450892] text-white rounded-lg shadow-md flex flex-col justify-center items-start md:pb-8 md:px-5 w-1/3 relative p-4">
            <div className="flex items-start w-full">
              <img src={LogoMars} className="md:w-16 w-8" alt="Company_Logo" />
            </div>
            <div className="text-center mt-4 self-center space-y-4">
              <span className="text-white md:text-2xl font-bold">
                {dashboardData?.customers}
              </span>
              <p className="mt-2 text-sm font-bold">Total Customers</p>
            </div>
          </div>
          <div className="bg-[#450892] text-white rounded-lg shadow-md flex flex-col justify-center items-start md:pb-8 md:px-5 w-1/3 relative p-4">
            <div className="flex items-start w-full">
              <img src={LogoMars} className="md:w-16 w-8" alt="Company_Logo" />
            </div>
            <div className="text-center mt-4 self-center space-y-4">
              <span className="text-white md:text-2xl font-bold">
                USDT {dashboardData?.prevMonth}
              </span>
              <p className="mt-2 text-sm font-bold">Previous Month Earned</p>
            </div>
          </div>
          <div className="bg-[#450892] text-white rounded-lg shadow-md flex flex-col justify-center items-start md:pb-8 md:px-5 w-1/3 relative p-4">
            <div className="flex items-start w-full">
              <img src={LogoMars} className="md:w-16 w-8" alt="Company_Logo" />
            </div>
            <div className="text-center mt-4 self-center space-y-4">
              <span className="text-white md:text-2xl font-bold">
                USDT {dashboardData?.totalAmount}
              </span>
              <p className="mt-2 text-sm font-bold">Total Amount Earned</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-8">
          <div className="w-full flex-col items-start bg-[#ffffff] md:py-10 py-5 rounded-lg md:px-16 px-3 shadow-md space-y-8">
            <AgentForm
              agentData={agentData}
              setAgentData={setAgentData}
              accountType={accountType}
            />
            {accountType === AccountType.Admin && (
              <RegIdCreate agentId={agentId} userType={accountType} />
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-end md:px-10 p-4 md:mt-28 mb-5">
        <button onClick={handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.0}
            stroke="currentColor"
            className="md:size-9 size-4 text-purple-circle"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default AgentProfile;
