/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import RegIdCreate from "./RegIdCreate";
import { getAgentProfileInfo, getDashboardStatisticsToAgent } from '../../../api/agentApi'; 
import Cookies from "js-cookie";
import AgentInfo from "./AgentInfo";
import { getAccount } from "../../../api/accountApi";
import usePageTitle from "../../../hooks/usePageTitle";
import LogoMars from '../../../static/img/LogoMars.png'

const DashboardAgent = () => {
  usePageTitle('Dashboard');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userType, setUserType] = useState(null); 
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
  const fetchAccountType = async () => {
    try {
      const { account } = await getAccount();
      if (account) {
        const type = account.data.role.accountType;
        setUserType(type);
      }
    } catch (error) {
      setUserType(null);
    }
  };

  fetchAccountType();
}, []);

useEffect(() => {
  const getStatistics = async () => {
    const token = Cookies.get('accessToken');
    try {
      const response = await getDashboardStatisticsToAgent(token);
      setDashboardData({
        customers: response.data.totalCustomers,
        prevMonth: response.data.prevMonthAmountEarned,
        totalAmount: response.data.totalAmountEarned,
      });

    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
    }
  };
  getStatistics();
}, []);

  const token = Cookies.get("accessToken");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAgentProfileInfo(token);
        setData(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);


  return (
    <div className="bg-grey-light md:p-16 p-4 min-h-screen">
      <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-10 md:mb-10 mb-5">
        Agent Profile
      </h1>
      <div className="px-10">
        <div className="grid grid-cols-2 gap-2 md:gap-8 xl:gap-12 md:grid-cols-3 xl:grid-cols-3 justify-items-center mb-6 md:mb-12">
          <div className="bg-[#450892] text-white rounded-lg shadow-md flex flex-col justify-center items-start md:pb-8 md:px-5 w-full relative p-4">
            <div className="flex items-start w-full">
              <img
                src={LogoMars}
                className="md:w-16 w-8"
                alt="Company_Logo"
              />
            </div>
            <div className="text-center mt-4 self-center space-y-4">
              <span className="text-white md:text-2xl font-bold ">{dashboardData?.customers}</span>
              <p className="mt-2 text-xs md:text-sm lg:text-base font-bold">Total Customers</p>
            </div>
          </div>
          <div className="bg-[#450892] text-white rounded-lg shadow-md flex flex-col justify-center items-start md:pb-8 md:px-5 w-full relative p-4">
            <div className="flex items-start w-full">
              <img
                src={LogoMars}
                className="md:w-16 w-8"
                alt="Company_Logo"
              />
            </div>
            <div className="text-center mt-4 self-center space-y-4">
              <span className="text-white md:text-2xl font-bold ">USDT {dashboardData?.prevMonth}</span>
              <p className="mt-2 text-xs md:text-sm lg:text-base font-bold">Previous Month Earned</p>
            </div>
          </div>
          <div className="bg-[#450892] text-white rounded-lg shadow-md flex flex-col justify-center items-start md:pb-8 md:px-5 w-full relative p-4">
            <div className="flex items-start w-full">
              <img
                src={LogoMars}
                className="md:w-16 w-8"
                alt="Company_Logo"
              />
            </div>
            <div className="text-center mt-4 self-center space-y-4">
              <span className="text-white md:text-2xl font-bold ">USDT {dashboardData?.totalAmount}</span>
              <p className="mt-2 text-xs md:text-sm lg:text-base font-bold">Total Amount Earned</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-8 ">
          <div className="w-full flex-col items-start bg-[#ffffff] md:py-10 py-5 rounded-lg md:px-16 px-3 shadow-md space-y-8">
            <AgentInfo agentInfo={data}/> 
            <RegIdCreate userType={userType}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAgent;
