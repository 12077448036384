import React from 'react';

const ResetPasswordModal = ({ isOpen, onClose }) => {
	if (!isOpen) return null;

	return (
		<div className="absolute w-80 bg-white rounded-lg z-50 font-poppins px-3 pb-3">
			<div className="relative">
				<h2 className="font-semibold text-mc_purple text-base text-center w-full my-3">
					Reset Password
				</h2>
				<button onClick={onClose} className='absolute top-0 right-0'>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth="1.5"
						stroke="#444444"
						className="size-4"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M6 18 18 6M6 6l12 12"
						/>
					</svg>
				</button>
			</div>
			<div className='text-center text-xs font-base'>
				If you have forgotten your password, please contact your <span className='text-mc_red font-semibold'>“Agent”</span> to
				reset it. We apologize for any inconvenience; this is for security
				purposes.
			</div>
		</div>
	);
};

export default ResetPasswordModal;
