import React from 'react';
import usePageTitle from '../hooks/usePageTitle';

const PrivacyPolice = () => {
	usePageTitle('Privacy Police');
	return (
		<div className="font-poppins p-5 text-sm space-y-5">
			<div>
				<h1 className="font-bold text-xl mb-4">Privacy Policy - MarsCAPITA</h1>
				<p>
					MarsCAPITA ("we," "our," or "us") is committed to protecting and
					respecting your privacy. This Privacy Policy outlines how we collect,
					use, and protect your personal information when you use our services.
					By using MarsCAPITA’s services, you agree to the collection and use of
					your personal information as described in this policy, as well as
					MarsCAPITA’s [Terms of Service]. Any signiﬁcant changes to this policy
					will be posted on MarsCAPITA’s website at https://marscapita.com
					("Website"). This policy applies to all users of our services,
					including those who interact with our platform or visit our website.
				</p>
				<ul className="list-inside">
					<li>
						<h2 className="privacy_h2">1. Information We Collect</h2>
						<p>
							We collect two types of data from you: Personal Data and
							Non-Personal Data.
						</p>
						<ul className="privacy_ul">
							<li>
								<span className="font-semibold">Personal Data:</span>{' '}
								Information that may allow your identiﬁcation with reasonable
								means, such as your name, email address, or contact information.
							</li>
							<li>
								<span className="font-semibold">Non-Personal Data:</span>{' '}
								Information that does not relate to an identiﬁable individual,
								such as aggregated usage data, device details, and technical
								information (e.g., browser type, operating system).
							</li>
						</ul>{' '}
						<br />
						<ul className="privacy_ul">
							<li>
								<span className="font-semibold">
									Trading Account Information:
								</span>{' '}
								We collect trading account API keys, credentials, and any
								related information for supported platforms to enable
								algorithmic trading on your behalf. We do not have direct access
								to your funds, and your API keys are used solely for executing
								trades as authorized by you.
							</li>
							<li>
								<span className="font-semibold">Technical Information:</span>{' '}
								When you interact with our platform, we collect data such as IP
								addresses, geolocation data, unique device identiﬁers (e.g., MAC
								address), operating system, browsing history, and access logs.
							</li>
							<li>
								<span className="font-semibold">Transaction Data:</span> We
								collect data regarding your ﬁnancial transactions, such as
								trading history, for the purpose of providing our services.
							</li>
							<li>
								<span className="font-semibold">Communication Data:</span> When
								you contact us through email or customer support, we collect the
								information you provide, such as your name, email address, and
								any other details relevant to your inquiry.
							</li>
						</ul>
					</li>
					<li>
						<h2 className="privacy_h2">2. How We Use Your Information</h2>
						<p>
							MarsCAPITA uses the information we collect for the following
							purposes:
						</p>
						<ul className="privacy_ul">
							<li>
								To provide, operate, and maintain our algorithmic trading
								services.
							</li>
							<li>
								To execute trades on your behalf using the trading account
								credentials you provide.
							</li>
							<li>
								To process transactions and improve the functionality of our
								platform.
							</li>
							<li>
								To communicate with you regarding account management, updates,
								and support services.
							</li>
							<li>
								To ensure compliance with legal obligations, prevent fraud, and
								enhance security.
							</li>
						</ul>
					</li>
					<li>
						<h2 className="privacy_h2">3. Tracking Technologies</h2>
						<p>
							When you visit or access our services, we use various tracking
							technologies to collect information about your online activities
							and device information. These include:
						</p>
						<ul className="privacy_ul">
							<li>
								<span className="font-semibold"></span> • Strictly Necessary
								Technologies: These tracking technologies are essential for
								navigating and using the features of our services.
							</li>
							<li>
								<span className="font-semibold">
									Tracking and Advertising Technologies:
								</span>{' '}
								These gather data about your browsing habits to deliver relevant
								advertising. The collection of this data may be shared with
								third-party advertisers, governed by their own privacy policies.
							</li>
							<li>
								<span className="font-semibold">
									Functionality Technologies:
								</span>{' '}
								These allow our platform to remember your choices, such as login
								details, to provide enhanced features.
							</li>
							<li>
								<span className="font-semibold">Performance Technologies:</span>{' '}
								These track your activity and engagement on our platform to
								improve performance, conduct analytics, and reﬁne user
								experience.
							</li>
						</ul>
					</li>
					<li>
						<h2 className="privacy_h2">
							4. Sharing and Disclosure of Information
						</h2>
						<p>
							MarsCAPITA does not sell or rent your personal data to third
							parties. However, we may share information in the following
							circumstances:
						</p>
						<ul className="privacy_ul">
							<li>
								<span className="font-semibold">Service Providers:</span> We
								share your data with trusted third-party service providers to
								assist in operating our platform, such as cloud hosting,
								analytics services, or payment processors. These providers are
								bound by conﬁdentiality agreements.
							</li>
							<li>
								<span className="font-semibold">Legal Compliance:</span> We may
								disclose your data to comply with legal obligations, such as in
								response to subpoenas, court orders, or other lawful requests.
							</li>
							<li>
								<span className="font-semibold">Business Transfers:</span> In
								the event of a merger, acquisition, or sale of MarsCAPITA, your
								data may be transferred to the new entity.
							</li>
						</ul>
					</li>
					<li>
						<h2 className="privacy_h2">5. Data Security</h2>
						<p>
							MarsCAPITA implements various measures to protect your personal
							and trading account data from unauthorized access or disclosure,
							including:
						</p>
						<ul className="privacy_ul">
							<li>
								<span className="font-semibold">Encryption: </span> Sensitive
								data such as API keys is encrypted both in transit and at rest.
							</li>
							<li>
								<span className="font-semibold">Access Controls:</span> Only
								authorized personnel can access your data, and we limit access
								to the minimum necessary for service operations.
							</li>
						</ul>
					</li>
					<li>
						<h2 className="privacy_h2">6. Your Rights</h2>
						<p>
							You have the following rights with respect to your personal data:
						</p>
						<ul className="privacy_ul">
							<li>
								<span className="font-semibold">Access:</span> You can request a
								copy of the personal data we hold about you.
							</li>
							<li>
								<span className="font-semibold">Correction:</span> You can
								request the correction of any inaccurate or incomplete data.
							</li>
							<li>
								<span className="font-semibold">Deletion:</span> You may request
								the deletion of your personal data, subject to legal or
								contractual obligations.
							</li>
							<li>
								<span className="font-semibold">Withdraw Consent:</span> You may
								withdraw your consent to data processing at any time, although
								this may aVect our ability to provide certain services.
							</li>
						</ul>
					</li>
					<li>
						<h2 className="privacy_h2">7. Data Retention</h2>
						<p>
							MarsCAPITA retains your personal data for as long as necessary to
							provide services, comply with legal requirements, resolve
							disputes, and enforce agreements.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">
							8. Third-Party services and API Integrations
						</h2>
						<p>
							MarsCAPITA integrates with third-party platforms for executing
							trades. While we take all precautions to protect your API
							credentials, we are not responsible for the security,
							availability, or reliability of these third-party services. Any
							issues arising from these platforms must be addressed directly
							with the third-party providers.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">9. Changes to this Privacy Policy</h2>
						<p>
							We reserve the right to change this Policy at any time. The most
							current version will always be posted on our website (as reﬂected
							in the “Last Updated” heading). You are advised to check for
							updates regularly. By continuing to access or use our Services
							after any revisions become eVective, you agree to be bound by the
							updated Policy.
						</p>
					</li>
					<li>
						<h2 className="privacy_h2">10. Further information</h2>
						<p>
							If you have any questions about this Privacy Policy or how your
							information is handled, please contact us via
							support@marscapita.com
						</p>
					</li>
				</ul>
				<footer className='italic mt-10'>Last modiﬁed: September 10th, 2024</footer>
			</div>
		</div>
	);
};

export default PrivacyPolice;
