import React from 'react';
import AddCustomerForm from './AddCustomerForm';
import { useNavigate } from 'react-router-dom';
import usePageTitle from '../../../../hooks/usePageTitle';

const AddCustomer = ({ accountType }) => {
  usePageTitle('Add Customer');
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/customers');
  };


  return (
    <div className=" bg-grey-light md:p-16 p-4 min-h-screen">
      <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
        Add new Customer (Internal)
      </h1>
      <div className="flex flex-col justify-center items-center gap-8 ">
        <AddCustomerForm accountType={accountType} />
      </div>
      <div className="flex justify-end md:px-10 p-4 md:mt-28 mb-5">
        <button onClick={handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.0}
            stroke="currentColor"
            className="md:size-9 size-4 text-purple-circle"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default AddCustomer;
