import React from 'react';

const ApprovalTableHeader = ({ selectAll, handleSelectAll }) => {
  return (
    <div className="flex items-center xl:px-6 xl:py-4 md:px-3 md:py-2 px-1 py-2 font-bold text-sm bg-white space-x-5 ">
      <span className="tr_style w-1/10">№</span>
      <span className="tr_style w-1/10">User Name</span>
      <span className="tr_style w-1/10">Country</span>
      <span className="tr_style w-1/10">Agent</span>
      <span className="tr_style w-1/10">Registration ID</span>
      <span className="tr_style w-1/10">Status</span>
      <span className="tr_style w-1/10">Group</span>
      <span className="tr_style w-1/10">Application Source</span>
      <span className="tr_style w-1/10">Application Date</span>
      <span className="tr_style w-1/10">Action</span>
    </div>
  );
};

export default ApprovalTableHeader;
