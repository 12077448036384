import { PlatformType } from '../static/enums/platformTypeEnum';
import api from './index';
import Cookies from 'js-cookie';

export const verifyRegistrationId = async (registrationId) => {
    try {
        const response = await api.post('/api/security/registrationid/verify', {
            registrationId,
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return error.response.data;
        } else {
            throw new Error('Error submitting registration ID');
        }
    }
};

export const createUserAccount = async (formData) => {
    try {
        const response = await api.post('/api/security/register', formData);
        if (response.data.successful) {
            return {successful: true, data: response.data};
        } else {
            return {
                successful: false,
                error: {message: response.data.error.message},
            };
        }
    } catch (error) {
        const message = error.response
            ? error.response.data.error.message
            : error.message;
        return {successful: false, error: {message}};
    }
};

export const loginUser = async (email, password) => {
    try {
        const response = await api.post('/api/security/login', {email, password});
        if (response.data.successful) {
            return {successful: true, data: response.data.data};
        } else {
            return {
                successful: false,
                error: {message: response.data.error.message},
            };
        }
    } catch (error) {
        const errorMessage = error.response
            ? error.response.data.error.message
            : error.message;
        const status = error.response ? error.response.status : null;
        
        return {
            successful: false,
            error: {
                status: status,
                message: errorMessage
            }
        };
    }
}
    

export const requestVerification = async (userData) => {
    try {
        const response = await api.post(
            '/api/security/email/requestverification',
            userData
        );
        if (response.data.successful) {
            return {successful: true, data: response.data.data};
        } else {
            return {
                successful: false,
                error: {message: response.data.error.message},
            };
        }
    } catch (error) {
        const message = error.response
            ? error.response.data.error.message
            : error.message;
        return {successful: false, error: {message}};
    }
};

export const verifyCode = async (code, accountId) => {
    try {
        const response = await api.post('/api/security/email/verify', {
            code,
            accountId,
        });

        if (response.data.successful) {
            const result = response.data;
            const {accessToken} = result.data;

            Cookies.set('accessToken', accessToken.value, {
                expires: 30,
                path: '/',
            });
            return result;
        } else {
            return {
                successful: false,
                error: {message: response.data.error?.message || 'Unknown error'},
            };
        }
    } catch (error) {
        const message = error.response
            ? error.response.data.error?.message || 'Unknown error'
            : error.message;
        console.error('Error:', message);
        return {
            successful: false,
            error: {message},
        };
    }
};

export const getOAuthRedirectUrl = async (tempAccountId ,platformType) => {
    try {
       

        const response = await api.get(`${PlatformType.find(e => e.id === platformType).path}${tempAccountId}`);

        if (response.data.successful) {
            return response.data.data.url;
        } else {
            return response.data.error.message;
        }
    } catch (error) {
        console.error('Error fetching URL:', error);
        throw error;
    }
};

export const resetForgottenPasswoord = async (email) => {
    try {
        const response = await api.post('/api/security/password/forgot', {
            email,
        });
        if (response.data.successful) {
            return {successful: true, data: response.data.data};
        } else {
            return {
                successful: false,
                error: {message: response.data.error.message},
            };
        }
    } catch (error) {
        return error.response ? error.response.data : 'An error occurred';
    }
};

export const verifyForgottenPassword = async (code, accountId, password) => {
    try {
        const response = await api.post('/api/security/password/verify', {
            code,
            accountId,
            password,
        });

        if (response.data.successful) {
            return response.data;
        } else {
            return {
                successful: false,
                error: {message: response.data.error?.message || 'Unknown error'},
            };
        }
    } catch (error) {
        const message = error.response
            ? error.response.data.error?.message || 'Unknown error'
            : error.message;
        console.error('Error:', message);
        return {
            successful: false,
            error: {message},
        };
    }
};

export const updateStatus = async (token, accountId, status) => {
    try {
        if (!token) {
            return {account: null, statusCode: 401};
        }
        const response = await api.patch(
            `/api/security/status/${accountId}`,
            {status},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (response.data.successful) {
            return response.data;
        } else {
            return response.data.error.message;
        }
    } catch (error) {
        console.error('Request failed:', error);
        return null;
    }
};
  
