import React from "react";
import StatusRegId from "../../../../static/enums/regIdEnum";

const RegIdRow = ({ item, index, isSelected, handleRowSelect }) => {

  return (
    <div
      className={`flex items-center md:px-6 md:py-4 py-2 border md:my-4 my-2 border-grey-light rounded-full space-x-5 ${
        isSelected ? "bg-gray-100" : ""
      }`}
    >
      <span className="tr_style w-1/5 text-gray-600 font-semibold">{index + 1}</span>
      <span className="tr_style w-1/5">{item.code}</span>
      <span className="tr_style w-1/5">
        <span className="flex items-center w-2/3">
          <span
            className={`md:py-1.5 py-0.5 md:text-xs text-[5px] self-center font-semibold rounded-full flex-grow text-center ${
              Number(item.status) === 1
                ? "bg-[#1296DB]"
                :Number(item.status) === 2
                ? "bg-[#5DF888] text-black"
                : "bg-[#FF4949] text-white"
            }`}
          >
            {StatusRegId.find((status) => status.value === Number(item.status))
              ?.label || "Suspended"}
          </span>
        </span>
      </span>
      <span className="tr_style w-1/5">
        {item.datetimeInserted
          ? new Date(item.datetimeInserted).toLocaleDateString("en-GB")
          : "-"}
      </span>
      <span className="tr_style w-1/5">
        {item.datetimeInserted
          ? new Date(item.datetimeUpdated).toLocaleDateString("en-GB")
          : "-"}
      </span>
    </div>
  );
};

export default RegIdRow;
