import React, { useState, useEffect, useContext } from 'react';
import usePageTitle from '../../../hooks/usePageTitle';
import PaginationControls from '../../shared/PaginationControls';
import ItemsPerPageDropdown from '../../shared/ItemsPerPageDropdown';
import LoadingSkeletonEntry from './LoadingSkeletonEntry';
import EntryOrderHeader from './EntryOrderHeader';
import EntryOrderRow from './EntryOrderRow';
import Cookies from 'js-cookie';
import { getErrorsData, getStatisticsError } from '../../../api/EntryApi';
import DropdownSort from '../../shared/DropDownSort';
import { useSearchParams } from 'react-router-dom';
import { ErrorContext } from '../../ErrorProvider';

const EntryOrderList = () => {
  usePageTitle('Entry Audit');

  const { setHasErrorType1 } = useContext(ErrorContext);
	setHasErrorType1(false);

  const [entryOrders, setEntryOrders] = useState([]);
  const [errorAmount, setErrorAmount] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const pageIndex = parseInt(searchParams.get('pageIndex'), 10) || 1;
    const month = searchParams.get('month');
    setCurrentPage(pageIndex);

    if (month) {
      const foundMonth = monthOptions.find((option) => option.value === month);
      setSelectedMonth(
        foundMonth
          ? foundMonth
          : { value: foundMonth.value, label: foundMonth.label }
      );
    } else {
      setSelectedMonth('');
    }
  }, [searchParams]);

  const getMonthYearString = (monthYear) => {
    const [month, year] = monthYear.split(/(\d{4})/).filter(Boolean);
    const monthIndex = new Date(Date.parse(month + ' 1, 2020')).getMonth() + 1; 
    return `${year}-${monthIndex.toString().padStart(2, '0')}`; 
  };

  const generateMonthsForRange = () => {
    const minDate = new Date(2024, 4, 1);
    const maxDate = new Date();

    const startYear = minDate.getFullYear();
    const endYear = maxDate.getFullYear();

    let allMonths = [{ value: 'all', label: 'All month' }];

    for (let year = endYear; year >= startYear; year--) {
      for (let month = 11; month >= 0; month--) {
        const date = new Date(year, month);

        if (year === 2024 && month < 4) continue;

        if (year === endYear && month > maxDate.getMonth()) continue;

        allMonths.push({
          value: `${date.toLocaleString('en-GB', { month: 'short' })}${year}`,
          label: `${date.toLocaleString('en-GB', { month: 'short' })} ${year}`,
        });
      }
    }

    return allMonths;
  };
  const monthOptions = generateMonthsForRange();

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('accessToken');
      setIsLoading(true);
      try {
        const filters = [
          {
            name: 'id',
            value: '',
          },
        ];
        if (
          selectedMonth &&
          selectedMonth.value !== '' &&
          selectedMonth.value !== 'all'
        ) {
          filters.push({
            name: 'datetimeInserted',
            value: getMonthYearString(selectedMonth.value),
          });
        }
        const data = await getErrorsData(
          token,
          currentPage - 1,
          itemsPerPage,
          filters
        );
        if (data) {
          setEntryOrders(data.data);
          setTotalItems(data.paging.totalCount);
        } else {
          console.error('Server Error:', data.error.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [itemsPerPage, currentPage, selectedMonth]);

  useEffect(() => {
    const statisticsError = async () => {
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }
      try {
        const data = await getStatisticsError(token);
        setErrorAmount(data);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };
    statisticsError();
  }, []);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const hasRecords = entryOrders?.length > 0;

  const handleRowSelect = (index) => {
    const pageIndex = (currentPage - 1) * itemsPerPage + index;
    if (selectedRows.includes(pageIndex)) {
      setSelectedRows(selectedRows.filter((i) => i !== pageIndex));
    } else {
      setSelectedRows([...selectedRows, pageIndex]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAll(false);
    } else {
      const allIndexes = entryOrders.map(
        (_, index) => (currentPage - 1) * itemsPerPage + index
      );
      setSelectedRows(allIndexes);
      setSelectAll(true);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSearchParams({
      pageIndex: newPage,
      month: selectedMonth?.value || '',
    });
    setSelectAll(false);
  };

  const handleItemsPerPageChange = (event) => {
    const newPageSize = Number(event.target.value);
    setItemsPerPage(newPageSize);
    setCurrentPage(1);
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
    setSearchParams({
      pageIndex: 1,
      month: selectedOption?.value || '',
    });
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setSelectedMonth(null);
    setCurrentPage(1);
    setSearchParams({});
  };

  return (
    <div className="bg-grey-light md:p-16 p-4 min-h-screen">
      <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
        Entry Order Audit
      </h1>
      <div className="flex justify-end items-center md:space-x-5 space-x-1 py-2 md:px-12 px-5">
        <span className="font-semibold md:text-sm text-[6px]">
          Total Position({errorAmount?.total})
        </span>
        <span className="font-semibold md:text-sm text-[6px]">
          Error case({errorAmount?.errors || '0'})
        </span>
        <div className="xl:w-1/7">
          <DropdownSort
            options={monthOptions}
            selected={selectedMonth}
            onChange={handleMonthChange}
            title={'Month'}
          />
        </div>
        <button onClick={handleResetFilters}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="md:size-6 size-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
            />
          </svg>
        </button>
      </div>
      <div className="flex flex-col justify-center items-center gap-8">
        <div className="w-full items-start bg-[#ffffff] md:py-10 py-2 rounded-lg md:px-12 px-1.5">
          <EntryOrderHeader
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
          />
          {isLoading ? (
            <LoadingSkeletonEntry itemsPerPage={itemsPerPage} />
          ) : hasRecords ? (
            entryOrders.map((item, index) => (
              <EntryOrderRow
                key={item.id}
                item={item}
                index={(currentPage - 1) * itemsPerPage + index}
                isSelected={selectedRows.includes(
                  (currentPage - 1) * itemsPerPage + index
                )}
                handleRowSelect={handleRowSelect}
              />
            ))
          ) : (
            <div className="text-center pt-10">No records available</div>
          )}

          <div className="flex justify-end space-x-5">
            <ItemsPerPageDropdown
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              totalItems={totalItems}
            />
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntryOrderList;
