import React from 'react';

const CustomerTableHead = ({ selectAll, handleSelectAll }) => {
  return (
    <div className="flex items-center md:px-6 px-1 py-2 font-bold text-sm bg-white space-x-5">
      <span className="tr_style w-1/9">№</span>
      <span className="tr_style w-1/9">Name</span>
      <span className="tr_style w-1/9">Cust. ID</span>
      <span className="tr_style w-1/9">Country</span>
      <span className="tr_style w-1/9">Status</span>
      <span className="tr_style w-1/9">Agent</span>
      <span className="tr_style w-1/9">Group</span>
      <span className="tr_style w-1/9">Member Since</span>
      <span className="tr_style w-1/9">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-8 text-purple-600"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
          />
        </svg>
      </span>
    </div>
  );
};

export default CustomerTableHead;
