import React from 'react';
import { useNavigate } from 'react-router-dom';
import Status from '../../../../static/enums/statusEnum';
import { CustomerGroup } from '../../../../static/enums/customerGroupEnum';

const CustomerTableRow = ({ item, index, isSelected, handleRowSelect }) => {
  const navigate = useNavigate();

  const groupTypeLabel =
    Object.keys(CustomerGroup).find(
      (key) => CustomerGroup[key] === item.group
    ) || '-';

  return (
    <div
      className={`flex items-center md:px-6 md:py-4 px-1 py-2 border md:my-4 my-2 border-grey-light rounded-full space-x-5 ${
        isSelected ? 'bg-gray-100' : ''
      }`}
    >
         <span className="tr_style w-1/9 text-gray-600 font-semibold">{index + 1}</span>
      {/* <input
        type="checkbox"
        checked={isSelected}
        onChange={() => handleRowSelect(index)}
      /> */}
      <span className="tr_style w-1/9 cursor-pointer" onClick={() => navigate(`/customer/${item.id}`)}>
        {item.account ? (
          <>
            {item.account.firstName} <br /> {item.account.lastName}
          </>
        ) : (
          '-'
        )}
      </span>
      <span className="tr_style w-1/9">{item.id}</span>
      <span className="tr_style w-1/9">
        {item.account.country?.name || '-'}
      </span>
      <span className="tr_style w-1/9">
        <span className="flex items-center w-full">
          <span
            className={`md:py-1.5 py-0.5 md:text-xs text-[5px] self-center font-semibold rounded-full flex-grow text-center ${
              Number(item.account.status) === 3
                ? 'bg-[#5DF888] text-black'
                : Number(item.account.status) === 2
                ? 'bg-[#FF4949] text-white'
                : 'bg-[#716dc0]'
            }`}
          >
            {Status.find(
              (status) => status.value === Number(item.account.status)
            )?.label || 'Suspended'}
          </span>
        </span>
      </span>
      <span className="tr_style w-1/9">
        {item.agent ? (
          <a href={`/agent/${item.agent.id}`} className="tr_style w-1/9">
            {item.agent.firstName ? (
              <>
                {item.agent.firstName} <br /> {item.agent.lastName}
              </>
            ) : (
              '-'
            )}
          </a>
        ) : (
          <span>No agent</span>
        )}
      </span>
      <span className="tr_style w-1/9">{groupTypeLabel}</span>

      <span className="tr_style w-1/9">
        {item.account.datetimeInserted
          ? new Date(item.account.datetimeInserted).toLocaleDateString('en-GB')
          : '-'}
      </span>
      <span className={`tr_style w-1/9 ${item?.currentWalletAmount > 0 ? "text-black" : "text-red-500"}`}>
        {item?.currentWalletAmount}
      </span>
    </div>
  );
};

export default CustomerTableRow;
