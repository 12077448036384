import React from "react";

const AgentTableHead = ({ selectAll, handleSelectAll }) => {
  return (
    <div className="flex items-center md:px-6 px-1 py-2 font-bold text-sm bg-white">
      <span className="tr_style w-1/7">№</span>
      <span className="tr_style w-1/7">Agent</span>
      <span className="tr_style w-1/7">Country</span>
      <span className="tr_style w-1/7">Status</span>
      <span className="tr_style w-1/7">Total Clientele</span>
      <span className="tr_style w-1/7">Since</span>
      <span className="tr_style w-1/7">Action</span>
    </div>
  );
};

export default AgentTableHead;
