import api from './index';
import Cookies from 'js-cookie';

export const getAccount = async () => {
  try {
    const token = Cookies.get('accessToken');
    if (!token) {
      return { account: null, statusCode: 401 };
    }

    const response = await api.get('/api/account', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 403) {
      return { account: null, statusCode: 403 };
    }

    if (response.data.successful) {
      const account = response.data;
      return { account: account, statusCode: response.status };
    } else {
      throw new Error(response.data.error.message);
    }
  } catch (error) {
    console.error('Error fetching account type:', error);
    return { account: null, statusCode: null };
  }
};

export const createAccount = async (accountData, token) => {
  try {
    const response = await api.post('/api/account', accountData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.data.successful) {
      return response.data.data.id;
    } else {
      console.error('Error creating account:', response.data.error.message);
      return { error: response.data.error.message };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.error?.message || error.message || 'Request failed';
    console.error('Account creation failed:', errorMessage);
    return { error: errorMessage };
  }
};

export const changePassword = async (accountId, password, token) => {
  try {
    const response = await api.post(
      `/api/security/password/reset/${accountId}`,
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.successful) {
      return response.data;
    } else {
      console.error('Error change password:', response.data.error.message);
      return response.data.error.message;
    }
  } catch (error) {
    console.error('Change password failed:', error);
    return error;
  }
};
export const changeOwnPassword = async (password) => {
  try {
    const token = Cookies.get('accessToken');
    if (!token) {
      return 'Error get account';
    }

    const response = await api.post(
      `/api/security/password/reset`,
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.successful) {
      return response.data;
    } else {
      console.error('Error change password:', response.data.error.message);
      return response.data.error.message;
    }
  } catch (error) {
    console.error('Change password failed:', error);
    return error;
  }
};
