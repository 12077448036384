import React, { useState, useEffect } from 'react';
import DropdownSort from '../../../shared/DropDownSort';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AgentTableHead from './AgentTableHead';
import AgentTableRow from './AgentTableRow';
import LoadingSkeleton from '../../StrategyRecord/LoadingSkeleton';
import ItemsPerPageDropdown from '../../../shared/ItemsPerPageDropdown';
import PaginationControls from '../../../shared/PaginationControls';
import {
  fetchAgentsByCountry,
  getStatisticsAgent,
} from '../../../../api/agentApi';
import Cookies from 'js-cookie';
import { AccountType } from '../../../../static/enums/accountTypeEmum';
import { getAllCountries } from '../../../../api/countryApi';
import usePageTitle from '../../../../hooks/usePageTitle';

const AgentList = ({ accountType }) => {
  usePageTitle('Agent List');
  const [agents, setAgents] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [countryOptions, setCountryOptions] = useState([]);
  const [statusAmount, setStatusAmount] = useState(null);

  useEffect(() => {
    const pageIndex = parseInt(searchParams.get('pageIndex'), 10) || 1;
    const country = searchParams.get('country');

    setCurrentPage(pageIndex);

    if (country) {
      const foundCountry = countryOptions.find(
        (option) => option.value === country
      );
      setSelectedCountry(
        foundCountry ? foundCountry : { value: country, label: country }
      );
    } else {
      setSelectedCountry(null);
    }
  }, [searchParams, countryOptions]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }
      try {
        const filters = [
					{
						name: 'id',
						value: '',
					},
				];
        if (selectedCountry) {
					filters.push({
						name: 'account.country.id',
						value: selectedCountry.value === 'all'? '': selectedCountry.value,
					});
				}
        const data = await fetchAgentsByCountry(
          currentPage - 1,
          itemsPerPage,
          token,
          filters
        );
        if (data.successful) {
          setAgents(data.data.data);
          setTotalItems(data.data.paging.totalCount);
        } else {
          console.error('Unexpected data format:', data.error.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [itemsPerPage, currentPage, selectedCountry]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getAllCountries();
        const countries = response.map((country) => ({
          value: country.id,
          label: country.name,
        }));
          setCountryOptions([
          { value: 'all', label: 'All Countries' },
          ...countries,
        ]);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const selectedOption = countryOptions.find(
        (option) => option.value === selectedCountry.value
      );
      if (selectedOption) {
        setSelectedCountry(selectedOption);
      }
    }
  }, [selectedCountry, countryOptions]);

  useEffect(() => {
    const statistics = async () => {
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }
      try {
        const data = await getStatisticsAgent(token);
        setStatusAmount(data);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };
    statistics();
  }, []);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const currentData = agents;

  const hasRecords = currentData?.length > 0;

  const handleRowSelect = (index) => {
    const pageIndex = (currentPage - 1) * itemsPerPage + index;
    const newSelectedRows = selectedRows.includes(pageIndex)
      ? selectedRows.filter((i) => i !== pageIndex)
      : [...selectedRows, pageIndex];

    setSelectedRows(newSelectedRows);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allIndexes = currentData.map(
        (_, index) => (currentPage - 1) * itemsPerPage + index
      );
      setSelectedRows([...selectedRows, ...allIndexes]);
    }
    setSelectAll(!selectAll);
  };

  const handleItemsPerPageChange = (event) => {
    const newPageSize = Number(event.target.value);
    setItemsPerPage(newPageSize);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSearchParams({
      pageIndex: newPage,
      country: selectedCountry?.value || '',
    });
    setSelectAll(false);
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSearchParams({
      pageIndex: 1,
      country: selectedOption?.value || '',
    });
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setSelectedCountry(null);
    setSearchParams({});
    setCurrentPage(1);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/agent/add');
  };

  return (
    <div className="bg-grey-light md:p-16 p-4 min-h-screen">
      <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
        Agent Listing
      </h1>
      <div className="flex justify-end items-center md:space-x-5 space-x-1 py-2 md:px-12 px-5">
        <span className="font-semibold md:text-xs text-[6px]">
          Active({statusAmount?.active})
        </span>
        <span className="font-semibold md:text-xs text-[6px]">
          Suspended({statusAmount?.blocked})
        </span>
        <div className="xl:w-1/7">
          <DropdownSort
            options={countryOptions}
            selected={selectedCountry}
            onChange={handleCountryChange}
            title="Country"
          />
        </div>
        <button
          className="bg-[#E80345] flex justify-center md:py-3 py-1 md:rounded-3xl rounded-lg xl:w-1/7 md:w-full md:text-xs xl:text-base text-[8px] shadow-sm items-center disabled:bg-mc_light_grey"
          onClick={handleClick}
          disabled={accountType === AccountType.Employee}
        >
          Agent
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="white"
            className="md:size-6 size-3 md:ml-2 ml-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </button>
        <button onClick={handleResetFilters}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="md:size-6 size-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
            />
          </svg>
        </button>
      </div>
      <div className="flex flex-col justify-center items-center gap-8">
        <div className="w-full items-start bg-[#ffffff] md:py-10 py-2 rounded-lg md:px-12 px-1.5">
          <AgentTableHead
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
          />

          {isLoading ? (
            <LoadingSkeleton itemsPerPage={itemsPerPage} />
          ) : hasRecords ? (
            currentData.map((item, index) => (
              <AgentTableRow
                key={item.id}
                item={item}
                index={(currentPage - 1) * itemsPerPage + index}
                isSelected={selectedRows.includes(
                  (currentPage - 1) * itemsPerPage + index
                )}
                handleRowSelect={handleRowSelect}
              />
            ))
          ) : (
            <div className="text-center pt-10">No records available</div>
          )}

          <div className="flex justify-end space-x-5">
            <ItemsPerPageDropdown
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              totalItems={totalItems}
            />
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentList;
