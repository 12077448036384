import React from "react";

const DropDown = ({ options, selectedOption, onSelect, placeholder,disabled }) => {
  const handleChange = (e) => {
    const value = e.target.value;
    onSelect(value === "" ? "" : Number(value));
  };

  return (
    <select
      className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
      value={selectedOption}
      onChange={handleChange}
      disabled={disabled} 
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};


export default DropDown;
