import React from 'react';
import PropTypes from 'prop-types';

export function CustomRadio({ label, items, value, onChange, error }) {
	return (
		<div className="flex justify-between h-full items-center">
			{items.map((item) => (
				<div key={item.value} className="flex items-center">
					<input
						name={label}
						type="radio"
						value={item.value}
						id={item.value}
						checked={value === item.value}
						onChange={() => onChange(item.value)}
						className="hidden"
					/>
					<label
						htmlFor={item.value}
						className="flex items-center cursor-pointer"
					>
						<span className="mr-2 text-sm font-normal text-black">
							{item.label}
						</span>
						<div
							className={`w-4 h-4 rounded-full border-2 ${
								error
									? 'border-red-500'
									: value === item.value
										? 'border-mc_green'
										: 'border-mc_light_grey'
							}`}
						>
							<div className="w-full h-full bg-grey-light rounded-full flex items-center justify-center">
								<div
									className={`w-2 h-2 rounded-full ${
										error
											? 'bg-red-500'
											: value === item.value
												? 'bg-mc_green'
												: 'bg-mc_light_grey'
									}`}
								></div>
							</div>
						</div>
					</label>
				</div>
			))}
		</div>
	);
}

CustomRadio.propTypes = {
	name: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired,
		})
	).isRequired,
	value: PropTypes.number,
	onChange: PropTypes.func.isRequired,
};

export default CustomRadio;
