import BybitLogo from '../img/ByBitLogo.png'
import BitgetLogo from '../img/BitgetLogo.png'
import BinanceLogo from '../img/BinanceLogo.png'
import OKXLogo from '../img/OKXLogo.png'

export const PlatformType = [
	{
		name: 'Bitget',
        id: 1,
		logo: BitgetLogo,
		status: true,
		path: `/api/security/bitget/oauth/`
	},
	{
		name: 'Bybit',
        id: 2,
		logo: BybitLogo,
		status: true,
		path: `/api/security/bybit/oauth/`
	},
	{
		name: 'Binance',
        id: 3,
		logo: BinanceLogo,
		status: false,
	},
	{
		name: 'OKX',
        id: 4,
		logo: OKXLogo,
		status: false,
	},
];