import React from "react";

const PaginationControls = ({
  currentPage,
  totalPages,
  handlePageChange,
  itemsPerPage,
  totalItems,
}) => {
  const getPageNumbers = () => {
    const maxPagesToShow = 5;
    const pages = [];

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) {
        pages.push(1);
        if (startPage > 2) pages.push("...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) pages.push("...");
        pages.push(totalPages);
      }
    }

    return pages;
  };

  const renderItemsInfo = () => {
    if (totalItems === 0) {
      return <span></span>;
    }
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);
    return (
      <span>
        {startItem}-{endItem} of {totalItems}
      </span>
    );
  };

  if (totalItems === 0) {
    return <span></span>;
  }
  return (
    <div className="pagination-controls flex justify-center items-center mt-4">
      <div className="items-count-info mr-4"></div>
      <div className="page-info mr-4 md:text-base text-[7px]">
        {renderItemsInfo()}
      </div>
      <div className="pagination-buttons flex items-center">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-2 mx-1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="md:w-4 md:h-4 w-2 h-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.25 19.25L8.75 12.75L15.25 6.25"
            />
          </svg>
        </button>

        {getPageNumbers().map((page, index) => (
          <button
            key={index}
            className={`${
              page === currentPage
                ? "active bg-purple-600 text-white md:text-base text-[7px] rounded-md md:rounded-lg"
                : ""
            } md:px-3 md:py-1 px-1.5 py-0.5 text-center md:text-base text-[7px]`}
            onClick={() => typeof page === "number" && handlePageChange(page)}
            disabled={typeof page !== "number"}
          >
            {page}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-2 mx-1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="md:w-4 md:h-4 w-2 h-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.75 19.25L15.25 12.75L8.75 6.25"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default PaginationControls;
