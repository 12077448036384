import React, { useState, useEffect, useRef, useCallback } from 'react';

const DropdownSort = ({ options, selected, onChange, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
    if (isOpen) {
      setSearchTerm('');
      setFilteredOptions(options);
    }
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
    setSearchTerm('');
    setFilteredOptions(options);
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setSearchTerm('');
        setFilteredOptions(options);
      }
    },
    [options]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    setFilteredOptions(
      options.filter((option) =>
        option.label.toLowerCase().startsWith(searchValue)
      )
    );
  };

  const displayValue = selected ? selected.label : title;

  return (
    <div className="relative w-full xl:w-full" ref={dropdownRef}>
      <button
        onClick={handleToggle}
        className="bg-grey-light text-gray-600 border truncate border-[#a828c5] text-center md:py-3 py-1 md:rounded-3xl rounded-lg xl:text-sm md:text-[10px] text-[5px] shadow-sm w-full"
      >
        <div className='flex items-center justify-center'>
          {displayValue}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="md:size-4 ml-1 size-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="absolute z-10 mt-2 w-full max-h-48 overflow-y-auto md:text-sm text-[5px] bg-grey-light border border-grey-light rounded shadow-lg">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search..."
            className={`w-full p-2 border-b border-[#a828c5] bg-transparent outline-none`}
          />
          <div>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  key={option.value}
                  value={option.value}
                  onClick={() => handleOptionClick(option)}
                  className="p-2 hover:bg-gray-200 cursor-pointer"
                >
                  {option.label}
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-500">No results</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownSort;
