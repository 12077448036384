import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TradeControls from './TradeControls';
import Settings from './Settings';
import WebHook from './WebHook';
import { getStrategyById } from '../../../api/parametersApi';
import Cookies from 'js-cookie';
import DeleteStrategyButton from './DeleteStrategyButton';
import usePageTitle from '../../../hooks/usePageTitle';
import { AccountType } from '../../../static/enums/accountTypeEmum';

const PreParameters = ({ accountType }) => {
  usePageTitle('Presetting Parameters');
  const navigate = useNavigate();
  const { strategyId } = useParams();
  const [strategyRecordData, setStrategyRecordData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [webhookUrl, setWebhookUrl] = useState('');

  const handleClick = () => {
    navigate('/admin/strategies');
  }

  useEffect(() => {
    const fetchStrategyRecordData = async () => {
      const token = Cookies.get('accessToken');
      if (!token) {
        setError('Needs to be authenticated');
        setLoading(false);
        return;
      }

      if (strategyId) {
        try {
          const data = await getStrategyById(strategyId, token);
          if (!data) {
            setError("Strategy doesn't exists.");
            navigate('/admin/strategies');
          } else {
            setStrategyRecordData(data);
            if (data.webhookUrl) {
              setWebhookUrl(data.webhookUrl);
            }
          }
        } catch (err) {
          setError(err.message);
        }
      } else {
        setStrategyRecordData({
          id: '',
          symbol: '',
          groups: [],
          entryDirection: 1,
          leverage: '',
          positionMode: '',
          takeProfitType: 1,
          takeProfitMode: 1,
          tp1: {
            targetLevel: '',
            closeVolume: 0,
          },
          tp2: {
            targetLevel: '',
            closeVolume: 0,
          },
          tp3: {
            targetLevel: '',
            closeVolume: 0,
          },
          stopLoss: {
            type: 1,
            amount: 0,
          },
          advanceStopLoss: false,
          trailingStop: false,
          positionActivation: {
            type: 1,
            amount: 0,
          },
          positionClose: {
            type: 1,
            amount: 0,
          },
          status: 1,
        });
      }
      setLoading(false);
    };

    fetchStrategyRecordData();
  }, [strategyId, navigate]);

  const handleWebhookUrlChange = (url) => {
    setWebhookUrl(url);
  };

  const handleClearForm = () => {
    setStrategyRecordData({
      id: '',
      symbol: '',
      groups: [],
      entryDirection: 1,
      leverage: '',
      positionMode: '',
      takeProfitType: 1,
      takeProfitMode: 1,
      tp1: {
        targetLevel: '',
        closeVolume: 0,
      },
      tp2: {
        targetLevel: '',
        closeVolume: 0,
      },
      tp3: {
        targetLevel: '',
        closeVolume: 0,
      },
      stopLoss: {
        type: 1,
        amount: '',
      },
      advanceStopLoss: false,
      trailingStop: false,
      positionActivation: {
        type: 1,
        amount: '',
      },
      positionClose: {
        type: 1,
        amount: '',
      },
      status: 1,
    });
    setWebhookUrl('');
  };

  useEffect(() => {
    handleClearForm();
  }, [strategyId]);

  if (loading) return <div className="bg-grey-light"></div>;
  if (error) return <div className="bg-grey-light">{error}</div>;

  return (
    <div className="bg-grey-light md:p-16 p-4">
      <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
        Presetting Parameters
      </h1>
      <div className="flex flex-col justify-center items-center gap-8">
        <Settings
          strategyRecordData={strategyRecordData}
          onWebhookUrlChange={handleWebhookUrlChange}
          accountType={accountType}
        />
        <WebHook
          webhookUrl={webhookUrl}
          clearWebhook={handleClearForm}
        />
        {accountType === AccountType.Admin && <TradeControls strategyId={strategyId}  />}
        {accountType === AccountType.Admin && (
          <DeleteStrategyButton
            strategyId={strategyId}
            onClearForm={handleClearForm}
          />
        )}
      </div>
      <div className="flex justify-end md:px-10 md:py-6 p-4 md:mb-10 mb-5">
        <button onClick={handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.0}
            stroke="currentColor"
            className="md:size-9 size-4 text-purple-circle"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default PreParameters;
