import React from "react";
import ErrorModal from '../../static/img/ErrorModal.png'
import SuccessModal from '../../static/img/SuccessModal.png'

const Modal = ({ isOpen, onClose, message, type }) => {
  if (!isOpen) return null;

  const messageColor = type === "success" ? "text-btn-green" : "text-btn-red";

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="relative h-auto w-3/4 md:w-1/2 xl:w-1/3 py-4 px-8 md:p-10 bg-white rounded-lg">
        {/* Close Button */}
        <button
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 m-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        {/* Success or Error Icon */}
        <div className="flex justify-center items-center p-7 md:p-10">
          {type === "success" ? (
            <img src={SuccessModal} alt="Success" />
          ) : (
            <img src={ErrorModal} alt="Error" />
          )}
        </div>

        {/* Message */}
        <div className="flex justify-center mt-4">
          <p className={`text-center text-[11px] md:text-sm xl:text-lg font-bold ${messageColor}`}>
            {message}
          </p>
        </div>

        {/* Close Button */}
        <div className="md:mt-6 mt-1 text-center">
          <button
            onClick={onClose}
            className="bg-gradient-to-r from-[#bb47f6] to-[#8028c5] text-white md:px-4 md:py-3 px-2 py-1.5 rounded-full w-3/5 text-sm mt-5 md:mt-10"
          >
            Closed
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
