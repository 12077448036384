import React, { useEffect, useState } from 'react';
import { deleteStrategyById } from '../../../api/parametersApi';
import Cookies from 'js-cookie';
import Modal from '../../shared/Modal';
import { useNavigate } from 'react-router-dom';
import ModalConfirmation from '../../shared/ModalConfirmation';

const DeleteStrategyButton = ({ strategyId, onClearForm }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [isCleared, setIsCleared] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);

  useEffect(() => {
    if (strategyId) {
      setIsUpdateMode(true);
    } else {
      setIsUpdateMode(false);
    }
  }, [strategyId]);

  const openConfirmationModal = () => {
    setModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    const token = Cookies.get('accessToken');
    if (!token) {
      setModalMessage('You need to be logged in');
      setModalType('error');
      setIsModalOpen(true);
      return;
    }
    if (isUpdateMode && strategyId) {
      try {
        const response = await deleteStrategyById(strategyId, token);

        if (response === null || response.successful === false) {
          setModalMessage('Strategy successfully deleted');
          setModalType('success');
          setIsDeleted(true);
        } else {
          setModalMessage('Error deleting strategy');
          setModalType('error');
        }
      } finally {
        setIsModalOpen(true);
        setModalVisible(false);
      }
    } else {
      onClearForm();
      setModalMessage('Strategy data cleared successfully');
      setModalType('success');
      setIsModalOpen(true);
      setModalVisible(false);
      setIsCleared(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (isDeleted) {
      navigate('/admin/strategies');
    } else if (isCleared) {
      navigate('/admin/parameters/preset');
      window.location.reload();
    }
  };

  return (
    <>
      <button
        className="md:w-1/3 w-1/2 md:text-base text-[10px] bg-btn-red hover:bg-red-600 md:py-5 py-2 font-semibold text-[#FFFFFF] rounded-3xl mb-4"
        onClick={openConfirmationModal}
      >
        Delete Strategy
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        message={modalMessage}
        type={modalType}
      />
      <ModalConfirmation
        isVisible={modalVisible}
        onConfirm={handleConfirmDelete}
        onCancel={() => setModalVisible(false)}
        pendingStatus="Confirm delete this strategy?"
        modalType="delete"
      />
    </>
  );
};

export default DeleteStrategyButton;
