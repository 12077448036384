import React, { useState, useEffect, useRef } from "react";

const StatusSort = ({ options, selected, onChange, title }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
  
    const handleToggle = () => setIsOpen(!isOpen);
  
    const handleOptionClick = (option) => {
      onChange(option);
      setIsOpen(false);
    };
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    return (
      <div className="relative w-full xl:w-full" ref={dropdownRef}>
        <button
          onClick={handleToggle}
          className="bg-grey-light text-gray-600 border truncate border-[#a828c5] text-center md:py-3 py-1 md:rounded-3xl rounded-lg xl:text-base md:text-[10px] text-[5px] shadow-sm flex items-center justify-center w-full"
        >
          {selected ? selected.label : title}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="md:size-4 md:ml-4 ml-1 size-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </button>
        {isOpen && (
          <div className="absolute z-10 mt-2 w-full md:text-sm text-[5px] bg-grey-light border border-grey-light rounded shadow-lg truncate">
            {options.map((option) => (
              <div
                key={option.value}
                onClick={() => handleOptionClick(option)}
                className="p-2 hover:bg-gray-200 cursor-pointer"
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
export default StatusSort;
