import React from 'react';

const RefferalCodeModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="absolute w-[420px] bg-white p-4 rounded-lg z-50 font-poppins -bottom-8 right-0">
            <div className='flex justify-between items-center'>
           <h1 className='text-mc_purple text-sm font-semibold'>Why you need to use our Exchange Referral Code?</h1>
           <button onClick={onClose}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth="1.5"
						stroke="#444444"
						className="size-4"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M6 18 18 6M6 6l12 12"
						/>
					</svg>
				</button>
                </div>
           <ul className='list-outside list-decimal text-[11px] font-normal p-2 space-y-3'>
            <li>If you don't have an exchange account, please register with the following exchange before signing up for <span className='text-mc_purple font-semibold'>MarsCAPITA</span>.</li>
            <li>Please follow the link below and <span className='font-semibold text-mc_red'>“MAKE SURE TO ENTER”</span> the referral code provided.</li>
            <li>For us to recognize your exchange account, you <span className='font-semibold text-mc_red'>“MUST”</span> use our Referral Code below.</li>
           </ul>
        </div>
    );
};

export default RefferalCodeModal;
