import React, { useEffect } from 'react';

const CodeInputs = ({ code, onCodeChange }) => {
	useEffect(() => {
        const firstInput = document.getElementById('code-input-0');
        if (firstInput) {
            firstInput.focus();
        }
    }, []);

	const handleChange = (e, index) => {
		const value = e.target.value.replace(/[^0-9]/g, '');
		if (value.length === 1) {
			const newCode = [...code];
			newCode[index] = value;
			onCodeChange(newCode);

			if (index < 5) {
				const nextInput = document.getElementById(`code-input-${index + 1}`);
				if (nextInput) {
					nextInput.focus();
				}
			}
		}
	};

	const handleKeyDown = (e, index) => {
		if (e.key === 'Backspace') {
			e.preventDefault();
	
			const newCode = [...code];
	
			// Если текущее поле уже пустое и не первое поле
			if (newCode[index] === '' && index > 0) {
				const prevInput = document.getElementById(`code-input-${index - 1}`);
				if (prevInput) {
					prevInput.focus();
					newCode[index - 1] = '';  // Также очищаем предыдущее поле
					onCodeChange(newCode);
				}
			} else {
				// Очищаем текущее поле и фокусируемся на предыдущее, если оно есть
				newCode[index] = '';
				onCodeChange(newCode);
				
				if (index > 0) {
					const prevInput = document.getElementById(`code-input-${index - 1}`);
					if (prevInput) {
						prevInput.focus();
					}
				}
			}
		}
	};
	
	

	const handlePaste = (e, index) => {
		e.preventDefault();

		const pastedData = e.clipboardData.getData('text').replace(/[^0-9]/g, '');
		const newCode = [...code];

		for (let i = 0; i < pastedData.length; i++) {
			if (i < newCode.length) {
				newCode[i] = pastedData[i];
			}
		}

		onCodeChange(newCode);

		const nextInput = document.getElementById(
			`code-input-${index + pastedData.length - 1}`
		);
		if (nextInput) {
			nextInput.focus();
		}
	};

	return (
		<div className="flex space-x-1 font-sans">
			{code.map((value, index) => (
				<input
					key={index}
					id={`code-input-${index}`}
					type="text"
					value={value}
					maxLength={1}
					className="h-9 w-7 text-center text-base border border-gray-300 rounded-[5px] focus:outline-none focus:ring-2 focus:ring-mc_blue focus:ring-opacity-50"
					onChange={(e) => handleChange(e, index)}
					onKeyDown={(e) => handleKeyDown(e, index)}
					onPaste={(e) => handlePaste(e, index)}
				/>
			))}
		</div>
	);
};

export default CodeInputs;
