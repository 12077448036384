import React, { useEffect, useState } from 'react';
import CustomerInfo from './CustomerInfo';
import CustomerStatistic from './CustomerStatistic';
import { useNavigate, useParams } from 'react-router-dom';
import { getCustomerById } from '../../../../api/customerApi';
import Cookies from 'js-cookie';
import usePageTitle from '../../../../hooks/usePageTitle';

const CustomerProfile = ({ accountType }) => {
  usePageTitle('Customer Profile');
  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate('/customers');
  };
	const { customerId } = useParams();
	const [customerData, setCustomerData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchCustomerData = async () => {
			const token = Cookies.get('accessToken');
			if (!token) {
				return { account: null, statusCode: 401 };
			}
			try {
				const data = await getCustomerById(customerId, token);
				setCustomerData(data);
				setLoading(false);
			} catch (err) {
				setError(err.message);
				setLoading(false);
			}
		};

		if (customerId) {
			fetchCustomerData();
		}
	}, [customerId]);

	if (loading) return <div className="bg-grey-light"></div>;
	if (error) return <div className="bg-grey-light"></div>;

  return (
    <div className="bg-[#f7f7f7] min-h-screen xl:flex block w-full md:p-10 p-2 gap-x-8">
      <div className="xl:w-7/12 w-full ">
        <h1 className="text-purple-circle font-semibold md:text-3xl text-base text-start ml-8 md:mb-10 mb-5">
          Performance Overview
        </h1>
        <CustomerStatistic customerData={customerData} />
      </div>
      <div className="xl:w-5/12 w-full">
        <h1 className="text-purple-circle font-semibold md:text-3xl text-base text-center md:mb-10 mb-5">
        {customerData.account.firstName} {customerData.account.lastName} Profile
        </h1>
        <div className="flex justify-between md:px-8 mb-2">
          <p className="text-black">Customer ID: {customerId}</p>
          <p>
            Since :{" "}
            {new Date(customerData.account.datetimeInserted).getDate()}/
            {new Date(customerData.account.datetimeInserted).getMonth() + 1}/
            {new Date(customerData.account.datetimeInserted).getFullYear()}
          </p>
        </div>
        <div className="w-full">
          <CustomerInfo customerData={customerData} 
          setCustomerData={setCustomerData}
          accountType={accountType} />
        </div>
        <div className="flex justify-end p-4 md:mt-14 mt-10 mb-5">
          <button onClick={handleClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.0}
              stroke="currentColor"
              className="md:size-9 size-4 text-purple-circle"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerProfile;
