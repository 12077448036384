import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import Cookies from "js-cookie";

const ConnectExchangeRedirect = () => {
        const [searchParams] = useSearchParams();
        const navigate = useNavigate();
        const platformType = searchParams.get('platformType');
        const accessToken = searchParams.get('accessToken');
        useEffect(() => {
                if (accessToken) {
                    Cookies.set('accessToken', accessToken, {
                        expires: 30,
                        path: '/',
                    });
                    navigate(`/customer/dashboard?success=True&platformType=${platformType}`);
                } else {
                    console.error('Access token is missing');
                    navigate('/auth/register');
                }
            }, [navigate, searchParams]
        )
        return (
            <div>
            </div>
        );
    }
;

export default ConnectExchangeRedirect;