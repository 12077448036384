import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getAccount } from '../api/accountApi';

const ProtectedRoute = ({ children, requiredAccountType }) => {
	const [accountType, setAccountType] = useState(null);
	const [status, setStatus] = useState(null);

	useEffect(() => {
		const fetchAccountType = async () => {
			try {
				const { account, statusCode } = await getAccount();
				if (account) {
					const type = account.data.role.accountType;
					setAccountType(type);
				}
				setStatus(statusCode);
			} catch (error) {
				setAccountType(null);
				setStatus(null); 
			}
		};

    fetchAccountType();
  }, []);

  if (status === 403 || status === 401) {
    return <Navigate to="/auth/login" />;
  }

  if (accountType === null && status === null) {
    return <div></div>;
  }

	if (requiredAccountType && !requiredAccountType.includes(accountType)) {
		return <Navigate to="/auth/login" />;
	}

  return React.Children.map(children, child =>
    React.cloneElement(child, { accountType })
    );
};

export default ProtectedRoute;
