import React, { useEffect, useState } from 'react';
import CustomerInfo from './CustomerInfo';
import CustomerStatistic from './CustomerStatistic';
import { getCustomerAccount } from '../../../api/customerApi';
import { useLocation, useNavigate } from 'react-router-dom';
// import { PlatformType } from '../../../static/enums/platformTypeEnum';
// import Modal from '../../shared/Modal';
import ThankYouModal from '../../modals/ThankYouModal';
import usePageTitle from '../../../hooks/usePageTitle';

const CustomerOwnProfile = () => {
	usePageTitle('Profile');
	const [customerData, setCustomerData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	// const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
	// const [successMessage, setSuccessMessage] = useState('');
	const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const success = urlParams.get('success');
		const platformTypeUrl = urlParams.get('platformType');

		if (success || platformTypeUrl) {
			if (success === 'True') {
				// const platform = PlatformType.find(
				//     (platform) => platform.id === Number(platformTypeUrl)
				// );
				// if (platform) {
				//     const message = detail
				//         ? `Success: Connected to ${platform.name} - ${detail}`
				//         : `Success: Connected to ${platform.name}`;
				//     setSuccessMessage(message);
				//     setIsSuccessModalOpen(true);
				// } else {
				//     setSuccessMessage(
				//         'Success: Connection was successful, but the platform could not be identified.'
				//     );
				//     setIsSuccessModalOpen(true);
				// }
				setIsThankYouModalOpen(true);
			}
			navigate(location.pathname, { replace: true });
		}
	}, [location, navigate]);

	useEffect(() => {
		const fetchCustomerData = async () => {
			try {
				const data = await getCustomerAccount();
				if (data.platformType === 0) {
					navigate('/auth/connect');
				} else {
					setCustomerData(data);
					setLoading(false);
				}
			} catch (err) {
				setError(err.message);
				setLoading(false);
			}
		};

		fetchCustomerData();
	}, [navigate]);

	if (loading) return <div className="bg-grey-light"></div>;
	if (error) return <div className="bg-grey-light"></div>;

	// const navigate = useNavigate();

	return (
		<div className="bg-[#f7f7f7] min-h-screen xl:flex block w-full md:p-10 p-2 gap-x-8">
			<div className="xl:w-7/12 w-full ">
				<h1 className="text-purple-circle font-semibold md:text-3xl text-base text-start ml-8 md:mb-10 mb-5">
					Performance Overview
				</h1>
				<CustomerStatistic customerData={customerData}/>
			</div>
			<div className="xl:w-5/12 w-full">
				<h1 className="text-purple-circle font-semibold md:text-3xl text-base text-center md:mb-10 mb-5">
					{customerData.account.firstName} {customerData.account.lastName}{' '}
					Profile
				</h1>
				<div className="flex justify-between md:px-8 mb-2">
					<p className="text-black">Customer ID: {customerData.id}</p>
					<p>
						Since :{' '}
						{new Date(customerData.account.datetimeInserted).getDate()}/
						{new Date(customerData.account.datetimeInserted).getMonth() + 1}/
						{new Date(customerData.account.datetimeInserted).getFullYear()}
					</p>
				</div>
				<div className="w-full">
					<CustomerInfo
						customerData={customerData}
						setCustomerData={setCustomerData}
					/>
				</div>
				{/* <div className="flex justify-end p-4 md:mt-14 mt-10 mb-5">
                    <button>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2.0}
                            stroke="currentColor"
                            className="md:size-9 size-4 text-purple-circle"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                            />
                        </svg>
                    </button>
                </div> */}
			</div>
			{/* <Modal
				isOpen={isSuccessModalOpen}
				onClose={() => setIsSuccessModalOpen(!isSuccessModalOpen)}
				message={successMessage}
				type="success"
			/> */}
			<ThankYouModal
				isOpen={isThankYouModalOpen}
				onClose={() => setIsThankYouModalOpen(!isThankYouModalOpen)}
			/>
		</div>
	);
};

export default CustomerOwnProfile;
