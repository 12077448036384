import React from 'react';
import { useNavigate } from 'react-router-dom';
import Status from '../../../static/enums/statusEnum';
import { ApplicationSource } from '../../../static/enums/ApplicationSourceEnum';
import { CustomerGroup } from '../../../static/enums/customerGroupEnum';

const ApprovalTableRow = ({ item, index, isSelected, handleRowSelect }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`./${item.id}`);
  };

  const statusLabel =
    Status.find((status) => status.value === Number(item.account.status))
      ?.label || '-';

  const applicationSourceLabel =
    Object.keys(ApplicationSource).find(
      (key) => ApplicationSource[key] === item.applicationSource
    ) || '-';

  const groupTypeLabel =
    Object.keys(CustomerGroup).find(
      (key) => CustomerGroup[key] === item.group
    ) || '-';

  return (
    <div
      className={`flex items-center xl:px-6 xl:py-4 md:px-3 md:py-2 px-1 py-2 border md:my-4 my-2 border-grey-light rounded-full space-x-5 ${
        isSelected ? 'bg-gray-100' : ''
      }`}
    >
      <span className="tr_style w-1/10 text-gray-600 font-semibold">{index + 1}</span>
      <span className="tr_style w-1/10">
        {item.account ? (
          <>
            {item.account.firstName} <br /> {item.account.lastName}
          </>
        ) : (
          '-'
        )}
      </span>
      <span className="tr_style w-1/10">
        {item.account.country?.name || '-'}
      </span>
      {item.agent ? (
        <a href={`/agent/${item.agent.id}`} className="tr_style w-1/10">
          <span>
            {item.agent.firstName ? (
              <>
                {item.agent.firstName} <br /> {item.agent.lastName}
              </>
            ) : (
              '-'
            )}
          </span>
        </a>
      ) : (
        <span className="tr_style w-1/10 ">No agent</span>
      )}

      <span className="tr_style w-1/10">{item.registrationId || '-'}</span>
      <span className="tr_style w-1/10 flex items-center">
        <span className="flex items-center w-full">
          <span
            className={`md:py-1.5 py-0.5 md:text-xs text-[5px] self-center font-semibold rounded-full flex-grow text-center ${
              Number(item.account.status) === 1
                ? 'bg-[#5DF888] text-black'
                : Number(item.account.status) === 4
                ? 'bg-[#FFD521] text-black'
                : item.account.status
                ? 'bg-[#FF3C3C] text-black'
                : 'bg-gray-200 text-black'
            }`}
          >
            {statusLabel}
          </span>
        </span>
      </span>
      <span className="tr_style w-1/10">{groupTypeLabel}</span>
      <span className="tr_style w-1/10">{applicationSourceLabel}</span>
      <span className="tr_style w-1/10">
        {item.account.datetimeInserted
          ? new Date(item.account.datetimeInserted).toLocaleDateString('en-GB')
          : '-'}
      </span>
      <button
        className="text-purple-600 hover:text-purple-800 w-1/10 text-center flex justify-center"
        onClick={handleClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="md:w-5 w-2.5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
          />
        </svg>
      </button>
    </div>
  );
};

export default ApprovalTableRow;
