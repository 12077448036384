import React, { useEffect, useState } from 'react';
import ConnectionDropdown from '../components/additional/ConnectionDropdown';
import ExchangeModal from '../components/modals/ExchangeModal';
import usePageTitle from '../hooks/usePageTitle';
import { getOAuthRedirectUrl } from '../api/securityApi';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../components/shared/Modal';
import Cookies from 'js-cookie';

const ConnectExchangePage = ({tempAccountId}) => {
	usePageTitle('Connect your platform');
	const [connectionDropdown, setConnectionDropdown] = useState(false);
	const [selectedExchange, setSelectedExchange] = useState(null);
	const [isExchangeModal, setIsExchangeModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

	// const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (tempAccountId==='') {
			navigate('/auth/register');
		}
	 },[tempAccountId] )

	// useEffect(() => {
	// 	const urlParams = new URLSearchParams(location.search);
	// 	const success = urlParams.get('success');
	// 	const status = urlParams.get('status');
	// 	const detail = urlParams.get('detail');

	// 	if (success === 'false') {
	// 		setErrorMessage(`Error: ${status} - ${detail}`);
	// 		setIsErrorModalOpen(true);
	// 	}
	// 	if (success === 'true') {
	// 		navigate('/customer/dashboard');
	// 	}
	// }, [location.search, navigate]);

	const handleExchangeSelect = (exchange) => {
		if (exchange.status) {
			setSelectedExchange(exchange);
			setConnectionDropdown(false);
		}
	};
	const setConnectionDropdownHandler = () => {
		setConnectionDropdown(!connectionDropdown);
	};
	const ExchangeModalHandler = () => {
		setIsExchangeModal(!isExchangeModal);
	};

	const handleConnectClick = async () => {
		
		if (tempAccountId) {
		try {
			const url = await getOAuthRedirectUrl(tempAccountId, selectedExchange.id);
			console.log(url);
			window.location.href = url;
		} catch (err) {
			console.error(err);
		}
	} else {
		
			navigate('/auth/register');
	
	}
	};
	return (
		<div className="w-full grid grid-cols-2 font-poppins">
			<div className="col-span-1 p-10 w-full">
				<div className="h-auto p-8 mb-4 bg-mc_light_purple rounded-[10px] text-white">
					<h1 className="font-semibold text-sm">Quick Connect an API</h1>
					<ul className="list-outside list-decimal text-xs font-normal p-2 space-y-3">
						<li>Click Connect to the exchange.</li>
						<li>Log in to your account with the exchange.</li>
						<li>
							Click 'Continue' when prompted, or follow the instructions to
							complete the necessary requirements.
						</li>
					</ul>
				</div>
				<div className="h-auto p-8 bg-mc_light_purple rounded-[10px] text-white">
					<h1 className="font-semibold text-sm">
						Why do we need your API Keys?
					</h1>
					<div className="list-outside list-decimal text-xs font-normal p-2 space-y-3">
						API keys are a unique set of identifiers that allow MarsCAPITA to
						programmatically perform actions on your behalf in your crypto
						exchange account (Bybit, Bitget, Binance etc..)
					</div>
				</div>
			</div>
			<div className="col-span-1 px-10 w-full">
				<div className="flex justify-between">
					<div className="flex flex-col items-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="#28CE4C"
							className="size-12"
						>
							<path
								fillRule="evenodd"
								d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
								clipRule="evenodd"
							/>
						</svg>
						<span className="mt-2 text-sm">Connect Exchange</span>
					</div>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						fill="#B9B9B9"
						className="size-12"
					>
						<path
							fillRule="evenodd"
							d="M16.72 7.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H3a.75.75 0 0 1 0-1.5h16.19l-2.47-2.47a.75.75 0 0 1 0-1.06Z"
							clipRule="evenodd"
						/>
					</svg>

					<div className="flex flex-col items-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="#B9B9B9"
							className="size-12"
						>
							<path
								fillRule="evenodd"
								d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
								clipRule="evenodd"
							/>
						</svg>
						<span className="mt-2 text-sm">Connect Exchange</span>
					</div>
				</div>
				<h1 className="font-medium text-3xl m-10 text-mc_purple">
					Connect Your Exchange
				</h1>
				<div className="relative">
					<span className="text-sm">Exchange</span>
					<button
						onClick={setConnectionDropdownHandler}
						className="h-11 w-full py-2 px-5 rounded-[5px] border border-mc_light_grey outline-none bg-transparent"
					>
						<div className="w-full flex justify-between items-center">
							{selectedExchange && (
								<div className="flex justify-center items-center">
									<img src={selectedExchange.logo} alt="" className="w-7" />
									<div className="ml-3 text-sm font-semibold">
										{selectedExchange.name}
									</div>
								</div>
							)}
							<div className="text-mc_grey text-sm flex w-auto items-center  ml-auto">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									className="size-5 mr-2"
								>
									<path
										fillRule="evenodd"
										d="M19.902 4.098a3.75 3.75 0 0 0-5.304 0l-4.5 4.5a3.75 3.75 0 0 0 1.035 6.037.75.75 0 0 1-.646 1.353 5.25 5.25 0 0 1-1.449-8.45l4.5-4.5a5.25 5.25 0 1 1 7.424 7.424l-1.757 1.757a.75.75 0 1 1-1.06-1.06l1.757-1.757a3.75 3.75 0 0 0 0-5.304Zm-7.389 4.267a.75.75 0 0 1 1-.353 5.25 5.25 0 0 1 1.449 8.45l-4.5 4.5a5.25 5.25 0 1 1-7.424-7.424l1.757-1.757a.75.75 0 1 1 1.06 1.06l-1.757 1.757a3.75 3.75 0 1 0 5.304 5.304l4.5-4.5a3.75 3.75 0 0 0-1.035-6.037.75.75 0 0 1-.354-1Z"
										clipRule="evenodd"
									/>
								</svg>
								Quick Connect
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									className="size-5 ml-2"
								>
									<path
										fillRule="evenodd"
										d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
										clipRule="evenodd"
									/>
								</svg>
							</div>
						</div>
					</button>
					{connectionDropdown && (
						<ConnectionDropdown onSelect={handleExchangeSelect} />
					)}
				</div>
				<button
					onClick={ExchangeModalHandler}
					className="text-center w-full h-11 p-3 mt-7 font-normal rounded-xl text-sm text-black shadow-md outline-none bg-opacity-50 bg-mc_blue"
				>
					Connect Exchange
				</button>
				<div className="text-xs my-3">
					Quick connect - Automatically create encrypted and IP-restricted API
					keys when connecting to Bybit
				</div>
				<ExchangeModal
					isOpen={isExchangeModal}
					onClose={ExchangeModalHandler}
					selectedExchange={selectedExchange ? selectedExchange.logo : null}
					connectExchange={handleConnectClick}
				/>
			</div>
			<Modal
				isOpen={isErrorModalOpen}
				onClose={() => setIsErrorModalOpen(!isErrorModalOpen)}
				message={errorMessage}
				type="error"
			/>
		</div>
	);
};

export default ConnectExchangePage;
