import React, { useState } from 'react';
import {
  openStrategyLong,
  openStrategyShort,
} from '../../../api/parametersApi';
import Cookies from 'js-cookie';
import Modal from '../../shared/Modal';

const TradeControls = () => {
  const pathname = window.location.pathname;
  const strategyId = pathname.startsWith('/strategy/')
    ? pathname.split('/strategy/')[1]
    : null;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState('');
  const [isLoadingLong, setIsLoadingLong] = useState(false);
  const [isLoadingShort, setIsLoadingShort] = useState(false);
  const token = Cookies.get('accessToken');

  if (!token) {
    return { account: null, statusCode: 401 };
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenLong = async () => {
    setIsLoadingLong(true);
    try {
      const response = await openStrategyLong(strategyId, token);
  
      if (response.successful) {
        setModalMessage('Long Order Placed Successfully!');
        setModalType('success');
      } else {
        setModalMessage(response.result); 
        setModalType('error');
      }
  
      setModalOpen(true);
    } catch (error) {
      const errorMessage = 'Order Failed';
      setModalMessage(errorMessage);
      setModalType('error');
      setModalOpen(true);
    } finally {
      setIsLoadingLong(false);
    }
  };
  

  const handleOpenShort = async () => {
    setIsLoadingShort(true);
    try {
      const response = await openStrategyShort(strategyId, token);
      
      if (response.successful) {
        setModalMessage('Short Order Placed Successfully!');
        setModalType('success');
      } else {
        setModalMessage(response.result); 
        setModalType('error');
      }
  
      setModalOpen(true);
    } catch (error) {
      const errorMessage = error.message || 'Order Failed';
      setModalMessage(errorMessage);
      setModalType('error');
      setModalOpen(true);
    } finally {
      setIsLoadingShort(false);
    }
  };

  const renderSpinner = () => {
    return (
      <div
        className={`w-7 h-7 border-x-4 border-green-700 rounded-full animate-spin border-dotted`}
      ></div>
    );
  };

  return (
    <div className="w-full flex-col items-start bg-grey-main md:py-10 py-5 rounded-lg h-auto md:px-16 px-3">
      <ul className="flex w-full justify-between md:gap-x-20 gap-x-2 ">
        <li className="li_style before:top-3 w-1/2">
          <div className="flex flex-col">
            <h2 className="md:text-lg text-[10px] font-bold text-center">
              Enter Trade Manually
            </h2>
            <span className="text-gray-700 md:text-xs text-[7px] text-center mb-5 font-semibold">
              (Market Price)
            </span>
            <button
              className={`bg-btn-green hover:bg-[#37bd54] trade_button md:mb-7 mb-3 disabled:bg-green-500 flex items-center justify-center ${
                isLoadingLong ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handleOpenLong}
              disabled={!strategyId || isLoadingLong}
            >
              {isLoadingLong ? renderSpinner() : 'Open Long'}
            </button>
            <button
              className={`bg-btn-red hover:bg-red-600 text-[#FFFFFF] trade_button disabled:bg-red-500 flex items-center justify-center ${
                isLoadingShort ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handleOpenShort}
              disabled={!strategyId || isLoadingShort}
            >
              {isLoadingShort ? renderSpinner() : 'Open Short'}
            </button>
          </div>
        </li>
      </ul>
      <Modal
        isOpen={modalOpen}
        onClose={handleCloseModal}
        message={modalMessage}
        type={modalType}
      />
    </div>
  );
};

export default TradeControls;

// import React, { useState } from "react";
// import { openStrategyLong, openStrategyShort } from "../../../api/parametersApi";
// import Cookies from "js-cookie";
// import Modal from "../../shared/Modal";

// const TradeControls = ({ strategyId }) => {
//   const [modalOpen, setModalOpen] = useState(false);
//   const [modalMessage, setModalMessage] = useState("");
//   const [modalType, setModalType] = useState("");
//   const token = Cookies.get("accessToken");

//   if (!token) {
//     return { account: null, statusCode: 401 };
//   }
//   const handleCloseModal = () => {
//     setModalOpen(false);
//   };

//   const handleOpenLong = async () => {
//     try {
//       await openStrategyLong(strategyId, token);
//       setModalMessage("Long Order Placed Successfully !");
//       setModalType("success");
//       setModalOpen(true);
//     } catch (error) {
//       if (error.response && error.response.status === 400) {
//         setModalMessage(error.response.data.error.message || "Error 400: Bad Request");
//       } else {
//         setModalMessage("Order Failed!");
//       }
//       setModalType("error");
//       setModalOpen(true);
//     }
//   };

//   const handleOpenShort = async () => {
//     try {
//       await openStrategyShort(strategyId, token);
//       setModalMessage("Short Order Placed Successfully !");
//       setModalType("success");
//       setModalOpen(true);
//     } catch (error) {
//       if (error.response && error.response.status === 400) {
//         setModalMessage(error.response.data.error.message || "Error 400: Bad Request");
//       } else {
//         setModalMessage("Order Failed!");
//       }
//       setModalType("error");
//       setModalOpen(true);
//     }
//   };

//   return (
//     <div className="w-full flex-col items-start bg-grey-main md:py-10 py-5 rounded-lg h-auto md:px-16 px-3">
//       <ul className="flex w-full justify-between md:gap-x-20 gap-x-2 ">
//         <li className="li_style before:top-3 w-1/2">
//           <div className="flex flex-col">
//             <h2 className="md:text-lg text-[10px] font-bold text-center">
//               Enter Trade Manually
//             </h2>
//             <span className="text-gray-700 md:text-xs text-[7px] text-center mb-5 font-semibold">
//               (Market Price)
//             </span>
//             <button
//               className="bg-btn-green hover:bg-[#37bd54] trade_button md:mb-7 mb-3 disabled:bg-green-500"
//               onClick={handleOpenLong}
//               disabled = {!strategyId}
//             >
//               Open Long
//             </button>
//             <button
//               className="bg-btn-red hover:bg-red-600 text-[#FFFFFF] trade_button disabled:bg-red-500"
//               onClick={handleOpenShort}
//               disabled = {!strategyId}
//             >
//               Open Short
//             </button>
//           </div>
//         </li>
//       </ul>
//       <Modal
//         isOpen={modalOpen}
//         onClose={handleCloseModal}
//         message={modalMessage}
//         type={modalType}
//       />
//     </div>
//   );
// };

// export default TradeControls;
