import React from "react";

export function CheckBox({ name, items, selectedValues, onChange, disabled  }) {


  const handleCheckboxChange = (itemValue) => {
    if (selectedValues.includes(itemValue)) {
      onChange(selectedValues.filter((value) => value !== itemValue));
    } else {
      onChange([...selectedValues, itemValue]);
    }
  };

  return (
    <div className="flex flex-col">
      {items.map((item) => (
        <div key={item.value} className="flex items-center mb-2">
          <input
            type="checkbox"
            value={item.value}
            id={name + item.value}
            checked={selectedValues.includes(item.value)}
            onChange={() => handleCheckboxChange(item.value)}
            className="hidden"
            disabled={disabled}
          />
          <label
            htmlFor={name + item.value}
            className="flex items-center cursor-pointer"
          >
            <div
              className={`md:w-4 md:h-4 w-2 h-2 rounded-full border-2 ${
                selectedValues.includes(item.value)
                  ? "bg-btn-green border-btn-green"
                  : "bg-grey-light border-grey-main"
              }`}
            >
              <div className="w-full h-full bg-grey-light rounded-full flex items-center justify-center">
                <div
                  className={`md:w-2 md:h-2 w-2/3 h-2/3  rounded ${
                    selectedValues.includes(item.value) ? "bg-btn-green" : "bg-grey-main"
                  }`}
                ></div>
              </div>
            </div>
            <span className="md:ml-2 ml-1.5 md:text-sm text-[8px] font-bold text-black">
              {item.label}
            </span>
          </label>
        </div>
      ))}
    </div>
  );
}


export default CheckBox;
