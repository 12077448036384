const AmountType = Object.freeze([
  { value: 1, label: "$" },
  { value: 2, label: "Quantity" },
  { value: 3, label: "%" },
]);

const EntryDirection = Object.freeze([
  { value: 1, label: "Long" },
  { value: 2, label: "Short" },
]);

const PositionMode = Object.freeze([
  { value: 1, label: "One-Way Mode" },
  { value: 2, label: "Hedge" },
]);

const TakeProfitMode = Object.freeze([
  { value: 1, label: "Full" },
  { value: 2, label: "Partial" },
]);

const StrategyStatus = Object.freeze([
  { value: 1, label: "Active" },
  { value: 2, label: "Inactive" },
]);

export {
  StrategyStatus,
  TakeProfitMode,
  AmountType,
  PositionMode,
  EntryDirection,
};
