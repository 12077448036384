import React from "react";

const LoadingSkeletonRegId = ({ itemsPerPage }) => {
  return (
    <>
      {Array(itemsPerPage)
        .fill({})
        .map((_, index) => (
          <div
            key={index}
            className="flex md:space-x-2 space-x-0.5 justify-between items-center md:px-3 px-1  md:py-4 py-2 md:my-4 border border-grey-light rounded-full animate-pulse"
          >
            <div className="md:w-6 w-3 md:h-5 h-2.5 bg-gray-300 rounded-full "></div>
            <div className="w-1/4 md:h-5 h-2.5 bg-gray-300 rounded"></div>
            <div className="w-1/4 md:h-5 h-2.5 bg-gray-300 rounded"></div>
            <div className="w-1/4 md:h-5 h-2.5 bg-gray-300 rounded"></div>
            <div className="w-1/4 md:h-5 h-2.5 bg-gray-300 rounded"></div>
          </div>
        ))}
    </>
  );
};

export default LoadingSkeletonRegId;
