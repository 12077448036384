import React, { useState } from 'react';
import ResetPasswordModal from '../../shared/ResetPasswordModal';
//import DropDownCountry from '../../shared/DropDownCountry';
//import { getAllCountries } from '../../../api/countryApi';
import Gender from '../../../static/enums/genderEnum';
import RadioValue from '../../shared/RadioValue';
import Modal from '../../shared/Modal';
//import { updateCustomerAccount } from '../../../api/customerApi';
import { PlatformType } from '../../../static/enums/platformTypeEnum';
import Status from '../../../static/enums/statusEnum';
import { changeOwnPassword } from '../../../api/accountApi';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

export const referralCode = [
	{ value: 'Yes', label: 'Yes' },
	{ value: 'No', label: 'No' },
];

const CustomerInfo = ({ customerData, setCustomerData }) => {
	const [selectedGender, setSelectedGender] = useState(
		customerData?.account?.gender || 1
	);
	const [firstName, setfirstName] = useState(
		customerData?.account?.firstName || ''
	);
	const [lastName, setLastName] = useState(
		customerData?.account?.lastName || ''
	);
	// const [selectedReferralCode, setSelectedReferralCode] = useState(
	// 	customerData?.account?.referralCode || 'No'
	// );
	const [email, setEmail] = useState(customerData?.account?.email || '');
	const [age, setAge] = useState(customerData?.account?.age || '');
	const [mobile, setMobile] = useState(customerData?.account?.phone || '');
	//const [countryDropdown, setCountryDropdown] = useState(false);
	const [country, setCountry] = useState({
		selectedFlag: customerData?.account?.country?.imageUrl || '',
		selectedName: customerData?.account?.country?.name || '',
		selectedId: customerData?.account?.country?.id || '',
	});
	const [agent, setAgent] = useState(
		`${customerData?.agent?.firstName || ''} ${customerData?.agent?.lastName || ''}`
	);
	// eslint-disable-next-line no-unused-vars
	const [password, setPassword] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [status, setStatus] = useState(customerData?.account?.status);
	// eslint-disable-next-line no-unused-vars
	const [exchange, setExchange] = useState(customerData?.platformType || '');
	const [exchangeAccountUUID, setExchangeAccountUUID] = useState(
		customerData?.exchangeAccountUUID || ''
	);
	const [walletValue, setWalletValue] = useState(customerData?.currentWalletAmount);
	const [apiKey, setApiKey] = useState(customerData?.apiKey || '');
	const [apiSecretKey, setApiSecretKey] = useState(
		customerData?.apiSecret || ''
	);
	//const [inputValue, setInputValue] = useState(`${country.selectedName}`);
	//const [countries, setCountries] = useState([]);
	//const [searchCountry, setSearchCountry] = useState('');
	const [showSaveModal, setShowSaveModal] = useState(false);
	// const [isFormChanged, setIsFormChanged] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [errorPassword, setErrorPassword] = useState('');
	const [successPassword, setSuccessPassword] = useState('');
	const [disable, setDisable] = useState(false);
	const navigate = useNavigate();

	// useEffect(() => {
	// 	const isChanged =
	// 		selectedGender !== customerData?.account?.gender ||
	// 		firstName !== customerData?.account?.firstName ||
	// 		lastName !== customerData?.account?.lastName ||
	// 		email !== customerData?.account?.email ||
	// 		age !== customerData?.account?.age ||
	// 		mobile !== customerData?.account?.phone ||
	// 		(country.selectedId || '') !==
	// 			(customerData?.account?.country?.id || '') ||
	// 		exchangeAccountUUID !== customerData?.exchangeAccountUUID;
	// 	// selectedReferralCode !== customerData?.account?.referralCode
	// 	setIsFormChanged(isChanged);
	// }, [
	// 	selectedGender,
	// 	firstName,
	// 	lastName,
	// 	email,
	// 	age,
	// 	mobile,
	// 	country,
	// 	exchangeAccountUUID,
	// 	customerData,
	// ]);

	// useEffect(() => {
	// 	const fetchCountries = async () => {
	// 		try {
	// 			const data = await getAllCountries(searchCountry);
	// 			setCountries(data);
	// 		} catch (error) {
	// 			console.error('Failed to fetch countries:', error);
	// 		}
	// 	};
	// 	fetchCountries();
	// }, [searchCountry]);

	// const openCountryDropdownHandler = (e) => {
	// 	e.preventDefault();
	// 	setCountryDropdown(!countryDropdown);
	// };

	// const closeCountryDropdownHandler = () => {
	// 	setCountryDropdown(false);
	// };

	// const handleCountrySelect = (flagUrl, name, id) => {
	// 	setCountry({
	// 		selectedFlag: flagUrl,
	// 		selectedName: name,
	// 		selectedId: id,
	// 	});
	// 	// setInputValue(name);
	// 	// closeCountryDropdownHandler();
	// };

	// const handleSubmit = async (event) => {
	// 	event.preventDefault();

	// 	const formData = {
	// 		firstName,
	// 		lastName,
	// 		email,
	// 		age: Number(age),
	// 		phone: mobile,
	// 		countryId: country.selectedId,
	// 		gender: selectedGender,
	// 		agentId: customerData.agent.id,
	// 		platformType: parseInt(exchange),
	// 		status,
	// 		apiKey,
	// 		apiSecret: apiSecretKey,
	// 		passphrase: '',
	// 		exchangeAccountUUID: exchangeAccountUUID,
	// 	};

	// 	try {
	// 		const updatedCustomer = await updateCustomerAccount(formData);
	// 		setCustomerData(updatedCustomer);

	// 		setShowSaveModal(true);
	// 		setIsFormChanged(false);
	// 	} catch (error) {
	// 		console.error('Failed to update customer:', error);
	// 	}
	// };

	//onSubmit={handleSubmit}

	const handlePasswordChange = async (password) => {
		try {
			const response = await changeOwnPassword(password);
			if (response.successful) {
				setSuccessPassword('Password reset successful!');
				setDisable(true);
			} else {
				setErrorPassword('Password change failed');
			}
		} catch (error) {
			setErrorPassword('Password change failed');
		}
	};

	const handleLogOut = () => {
		Cookies.remove('accessToken');
		navigate('/auth/login', { replace: true });
	  };
	  

	return (
		<div className="p-1">
			<form className="container flex-col items-start ">
				<div className="form-container bg-white  md:px-12 py-2 px-2 rounded-md">
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								First Name
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={firstName}
								onChange={(e) => setfirstName(e.target.value)}
								disabled
								required
							/>
						</div>
						{/* Last Name */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700 ">
								Last Name
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
								disabled
								required
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						{/* Age */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700 ">
								Age
							</label>
							<input
								type="number"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={age}
								onChange={(e) => {
									const newValue = parseInt(e.target.value, 10);
									setAge(newValue >= 0 ? newValue : 0);
								}}
								min={0}
								disabled
								required
							/>
						</div>
						{/* Gender */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-4">
								Gender
							</label>
							<div className="mt-1 flex-row items-center">
								<RadioValue
									name="gender"
									items={Gender}
									value={selectedGender}
									onChange={setSelectedGender}
									disabled={true}
								/>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-10 mb-6 ">
						{/* Email */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Email
							</label>
							<input
								type="email"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								disabled
								required
							/>
						</div>
						{/* Mobile */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Mobile
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={mobile}
								onChange={(e) => setMobile(e.target.value)}
								disabled
								required
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						{/* Country */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Country
							</label>
							<div>
								<button
									//onClick={openCountryDropdownHandler}
									className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-left disabled:bg-mc_white"
									disabled
								>
									{country.selectedFlag ? (
										<span>{country.selectedName}</span>
									) : (
										<span className="text-xs">No selected country</span>
									)}
								</button>
								{/* {countryDropdown && (
									<DropDownCountry
										countries={countries}
										onCountrySelect={handleCountrySelect}
										onClose={closeCountryDropdownHandler}
										value={inputValue}
										searchValue={searchCountry}
										setSearchValue={setSearchCountry}
										onChange={(e) => setInputValue(e.target.value)}
									/>
								)} */}
							</div>
						</div>

						{/* Agent */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Agent
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
								value={agent}
								onChange={(e) => setAgent(e.target.value)}
								disabled
								required
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						<div className="relative">
							<label className="block text-sm font-medium text-gray-700 mb-1">
								Password
							</label>
							<div className="relative">
								<input
									type="password"
									className="block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
									value="********"
									readOnly
									// disabled={
									// 	Status.find((s) => s.value === status)?.label === 'Pending'
									// }
									onChange={(e) => setPassword(e.target.value)}
									placeholder="Enter Password here"
								/>
								<button
									type="button"
									className="absolute inset-y-0 right-0 flex items-center px-3"
									onClick={() => setIsModalOpen(true)} // Open modal on click
									disabled={
										Status.find((s) => s.value === status)?.label === 'Pending'
									}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={1.5}
										stroke="currentColor"
										className="w-6 h-6 text-gray-500"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
										/>
									</svg>
								</button>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						{/* Referral Code */}
						{/* <div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-4">
								MS. Referral Code (Used)
							</label>
							<div className="mt-1 flex-row items-center ">
								<CustomRadio
									name="referalCode"
									items={referralCode}
									value={selectedReferralCode}
									onChange={setSelectedReferralCode}
									required
								/>
							</div>
						</div> */}
					</div>
					{/* <span className="blocked mt-3 flex text-btn-red text-center justify-end font-semibold mb-1 md:text-[10px] text-[5px]">
						MarsCAPITA Referral Code from Exchanges
					</span> */}
					<hr className="text-black mb-8" />
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						{/* Exchange */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Exchange
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
								value={
									PlatformType.find((p) => p.id === customerData?.platformType)
										?.name || ''
								}
								disabled
							/>
							{/* <ExchangeDrop
								exchanges={PlatformType}
								selectedExchange={exchange}
								onSelect={setExchange} //
							/> */}
						</div>
						{/* Exchange Account UID */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Exchange Account UID
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={exchangeAccountUUID}
								onChange={(e) => setExchangeAccountUUID(e.target.value)}
								// disabled={Status.find((s) => s.value === status)?.label === 'Pending'}
								disabled
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						{/* API Key */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								API Key
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={apiKey}
								onChange={(e) => setApiKey(e.target.value)}
								disabled
							/>
						</div>
						{/* API Secret Key */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								API Secret Key
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={apiSecretKey}
								onChange={(e) => setApiSecretKey(e.target.value)}
								disabled
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						<div className="col-start-1">
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Wallet Value
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={walletValue}
								onChange={(e) => setWalletValue(e.target.value)}
								disabled={
									Status.find((s) => s.value === status)?.label === 'Pending'
								}
							/>
						</div>
					</div>
				</div>
				<div className="grid col-span-3 mt-10 bg-grey-light px-12">
					{/* <div className="col-start-1 w-full">
							<button
								type="submit"
								className={`w-full text-white p-3.5 rounded-full md:text-base text-[8px] ${
									isFormChanged
										? 'bg-green-500 hover:bg-green-600'
										: 'bg-gray-300 cursor-not-allowed'
								}`}
								disabled={!isFormChanged}
							>
								Save information
							</button>
						</div> */}
					<div className="col-start-1 w-full">
						<div
							className={`w-full px-5 py-3 rounded-full md:text-base text-[8px] text-center text-black font-semibold drop-shadow-md	 ${
								Status.find((s) => s.value === status)?.label === 'Active'
									? 'bg-green-500 text-black'
									: Status.find((s) => s.value === status)?.label === 'Pending'
										? 'bg-[#FFD521] text-black'
										: 'bg-red-500 text-black'
							}`}
						>
							{Status.find((s) => s.value === status)?.label === 'Pending'
								? 'Pending Approval'
								: Status.find((s) => s.value === status)?.label || ''}
						</div>
					</div>
					<button
						className="col-start-2 flex justify-end"
						onClick={handleLogOut}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="size-12"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
							/>
						</svg>
					</button>
				</div>
			</form>

			<Modal
				isOpen={showSaveModal}
				onClose={() => setShowSaveModal(false)}
				message="Information saved successfully!"
				type="success"
			/>
			{/* Modal Rendering */}
			{isModalOpen && (
				<ResetPasswordModal
					onClose={() => {
						setIsModalOpen(false);
						setDisable(false);
					}}
					onPasswordChange={handlePasswordChange}
					error={errorPassword}
					setError={(e) => setErrorPassword(e)}
					success={successPassword}
					setSuccess={(s) => setSuccessPassword(s)}
					disable={disable}
				/>
			)}
		</div>
	);
};

export default CustomerInfo;
