import React, { useState, useEffect, useMemo } from 'react';
import PaginationControls from '../../shared/PaginationControls';
import ItemsPerPageDropdown from '../../shared/ItemsPerPageDropdown';
import ApprovalTableHeader from './ApprovalTableHeader';
import ApprovalTableRow from './ApprovalTableRow';
import LoadingSkeletonApp from './LoadingSkeletonApp';
import DropdownSort from '../../shared/DropDownSort';
import { fetchPendingCustomers, getStatistics } from '../../../api/customerApi';
import Cookies from 'js-cookie';
import usePageTitle from '../../../hooks/usePageTitle';
import { useSearchParams } from 'react-router-dom';
import { getAllCountries } from '../../../api/countryApi';
import { getAgentData } from '../../../api/agentApi';
import Status from '../../../static/enums/statusEnum';
import { CustomerGroup } from '../../../static/enums/customerGroupEnum';

const ApprovalStatus = () => {
  usePageTitle('Approval status');

  const [customers, setCustomers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [agentOptions, setAgentOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [statusAmount, setStatusAmount] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    const pageIndex = parseInt(searchParams.get('pageIndex'), 10) || 1;
    const country = searchParams.get('country');
    const agent = searchParams.get('agent');
    const status = searchParams.get('status');
    const group = searchParams.get('group');

    setCurrentPage(pageIndex);

    if (country) {
      const foundCountry = countryOptions.find(
        (option) => option.value === country
      );
      setSelectedCountry(
        foundCountry ? foundCountry : { value: country, label: country }
      );
    } else {
      setSelectedCountry(null);
    }

    if (agent) {
      const foundAgent = agentOptions.find((option) => option.value === agent);
      setSelectedAgent(
        foundAgent ? foundAgent : { value: agent, label: agent }
      );
    } else {
      setSelectedAgent(null);
    }

    if (status) {
      const foundStatus = Status.find(
        (option) => option.value === parseInt(status, 10)
      );
      setSelectedStatus(
        foundStatus ? foundStatus : { value: status, label: status }
      );
    } else {
      setSelectedStatus(null);
    }

    if (group) {
      const foundGroup = Object.keys(CustomerGroup).find(
        (key) => CustomerGroup[key] === parseInt(group, 10)
      );

      setSelectedGroup(
        foundGroup
          ? { value: CustomerGroup[foundGroup], label: foundGroup }
          : { value: group, label: group }
      );
    } else {
      setSelectedGroup(null);
    }

  }, [searchParams, agentOptions, countryOptions]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const data = await fetchPendingCustomers(
          currentPage - 1,
          itemsPerPage,
          token,
          selectedCountry?.value !== 'all' ? selectedCountry?.value : '',
          selectedAgent?.value !== 'all' ? selectedAgent?.value : '',
          selectedStatus?.value !== 'all' ? selectedStatus?.value : '',
          selectedGroup?.value !== 'all' ? selectedGroup?.value : ''
        );
        if (data.successful) {
          setCustomers(data.data.data || []);
          setTotalItems(data.data.paging.totalCount || 0);
        } else {
          console.error('Error fetching data:', data.error.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    currentPage,
    itemsPerPage,
    selectedCountry,
    selectedAgent,
    selectedStatus,
    selectedGroup
  ]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getAllCountries();
        const countries = response.map((country) => ({
          value: country.id,
          label: country.name,
        }));
        const countryOptions = [
          { value: 'all', label: 'All Countries' },
          ...countries,
        ];
        setCountryOptions(countryOptions);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const token = Cookies.get('accessToken');
        if (!token) {
          console.error('No token found');
          return;
        }
        const response = await getAgentData(token, 0, 100);
        const agents = response.data.map((agent) => ({
          label: `${agent.account.firstName || 'Unknown First Name'} ${
            agent.account.lastName || 'Unknown Last Name'
          }`,
          value: agent.id,
        }));
        setAgentOptions([{ label: 'All Agents', value: 'all' }, ...agents]);
      } catch (error) {
        console.error('Error fetching agents:', error);
      }
    };

    fetchAgents();
  }, []);

  useEffect(() => {
    const statistics = async () => {
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }
      try {
        const status = await getStatistics(token);
        setStatusAmount({pending: status.data.pending, rejected: status.data.rejected });
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };
    statistics();
  }, []);

  const customerGroupOptions = useMemo(
    () => [
      { value: 'all', label: 'All Groups' },
      ...Object.entries(CustomerGroup).map(([key, value]) => ({
        value: value,
        label: key,
      })),
    ],
    []
  );

  useEffect(() => {
    if (selectedGroup) {
      if (selectedGroup.value === 'all') {
        if (selectedGroup.label !== 'All Groups') {
          setSelectedGroup({ value: 'all', label: 'All Groups' });
        }
      } else {
        const selectedOption = customerGroupOptions.find(
          (option) => option.value === selectedGroup.value
        );

        if (selectedOption && selectedGroup.label !== selectedOption.label) {
          setSelectedGroup(selectedOption);
        }
      }
    }
  }, [selectedGroup, customerGroupOptions]);

  useEffect(() => {
    if (selectedAgent) {
      const selectedOption = agentOptions.find(
        (option) => option.value === selectedAgent.value
      );
      if (selectedOption) {
        setSelectedAgent(selectedOption);
      }
    }
  }, [selectedAgent, agentOptions]);

  useEffect(() => {
    if (selectedCountry) {
      const selectedOption = countryOptions.find(
        (option) => option.value === selectedCountry.value
      );
      if (selectedOption) {
        setSelectedCountry(selectedOption);
      }
    }
  }, [selectedCountry, countryOptions]);

  const filteredStatus = useMemo(
    () => [
      { value: 'all', label: 'All Statuses' },
      ...Status.filter((status) => [1, 5].includes(status.value)),
    ],
    []
  );

  useEffect(() => {
    if (selectedStatus) {
      const selectedOption = filteredStatus.find(
        (option) => option.value === selectedStatus.value
      );
      if (selectedOption) {
        setSelectedStatus(selectedOption);
      }
    }
  }, [selectedStatus, filteredStatus]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const currentData = customers;

  const hasRecords = currentData?.length > 0;

  const handleRowSelect = (index) => {
    const pageIndex = (currentPage - 1) * itemsPerPage + index;
    if (selectedRows.includes(pageIndex)) {
      setSelectedRows(selectedRows.filter((i) => i !== pageIndex));
    } else {
      setSelectedRows([...selectedRows, pageIndex]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAll(false);
    } else {
      const allIndexes = currentData.map(
        (_, index) => (currentPage - 1) * itemsPerPage + index
      );
      setSelectedRows(allIndexes);
      setSelectAll(true);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSearchParams({
      pageIndex: newPage,
      country: selectedCountry?.value || '',
      agent: selectedAgent?.label || '',
      status: selectedStatus?.value || '',
      group: selectedGroup?.value || '',
    });
    setSelectAll(false);
  };

  const handleItemsPerPageChange = (event) => {
    const newPageSize = Number(event.target.value);
    setItemsPerPage(newPageSize);
    setCurrentPage(1);
  };

  const handleAgentChange = (selectedOption) => {
    setSelectedAgent(selectedOption);
    setSearchParams({
      pageIndex: 1,
      country: selectedCountry?.value || '',
      agent: selectedOption?.value || '',
      status: selectedStatus?.value || '',
      group: selectedGroup?.value || '',
    });
    setCurrentPage(1);
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSearchParams({
      pageIndex: 1,
      country: selectedOption?.value || '',
      agent: selectedAgent?.value || '',
      status: selectedStatus?.value || '',
      group: selectedGroup?.value || '',
    });
    setCurrentPage(1);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
    setSearchParams({
      pageIndex: 1,
      country: selectedCountry?.value || '',
      agent: selectedAgent?.value || '',
      status: selectedOption?.value || '',
      group: selectedGroup?.value || '',
    });
    setCurrentPage(1);
  };

  const handleGroupChange = (selectedOption) => {
    setSelectedGroup(selectedOption);
    setSearchParams({
      pageIndex: 1,
      country: selectedCountry?.value || '',
      agent: selectedAgent?.value || '',
      status: selectedStatus?.value || '',
      group: selectedOption?.value || '',
    });
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setSelectedCountry(null);
    setSelectedAgent(null);
    setSelectedStatus(null);
    setSelectedGroup(null);
    setSearchParams({});
    setCurrentPage(1);
  };

  return (
    <div className="bg-grey-light md:p-16 p-4 min-h-screen">
      <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
        Pending Approval Customer
      </h1>
      <div className="flex justify-end items-center md:space-x-5 space-x-1 py-2 md:px-12 px-5">
        <span className="font-semibold md:text-xs text-[6px]">
          Pending ({statusAmount?.pending || 0})
        </span>
        <span className="font-semibold md:text-xs text-[6px]">
          Rejected ({statusAmount?.rejected || 0})
        </span>
        <div className="xl:w-1/7">
          <DropdownSort
            options={agentOptions}
            selected={selectedAgent}
            onChange={handleAgentChange}
            title={'Agent'}
          />
        </div>
        <div className="xl:w-1/7">
          <DropdownSort
            options={countryOptions}
            selected={selectedCountry}
            onChange={handleCountryChange}
            title="Country"
          />
        </div>
        <div className="xl:w-1/7">
          <DropdownSort
            options={filteredStatus}
            selected={selectedStatus}
            onChange={handleStatusChange}
            title="Status"
          />
        </div>
        <div className="xl:w-1/7">
            <DropdownSort
              options={customerGroupOptions}
              selected={selectedGroup}
              onChange={handleGroupChange}
              title="Group"
            />
          </div>
        <button onClick={handleResetFilters}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="md:size-6 size-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
            />
          </svg>
        </button>
      </div>
      <div className="flex justify-center items-center gap-8">
        <div className="w-full flex-col items-start bg-[#ffffff] md:py-10 py-2 rounded-lg md:px-12 px-1.5">
          <ApprovalTableHeader
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
          />

          {isLoading ? (
            <LoadingSkeletonApp itemsPerPage={itemsPerPage} />
          ) : hasRecords ? (
            currentData.map((item, index) => (
              <ApprovalTableRow
                key={item.id}
                item={item}
                index={(currentPage - 1) * itemsPerPage + index}
                isSelected={selectedRows.includes(
                  (currentPage - 1) * itemsPerPage + index
                )}
                handleRowSelect={handleRowSelect}
              />
            ))
          ) : (
            <div className="text-center pt-10">No records available</div>
          )}
          <div className="flex justify-end space-x-5">
            <ItemsPerPageDropdown
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              totalItems={totalItems}
            />
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovalStatus;
