import api from './index';

export const createEmployee = async (accountId, token) => {
  try {
    if (!accountId) {
      throw new Error('accountId is required');
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await api.post('/api/employee', { accountId }, config);

    if (response.data.successful) {
      return response.data;
    } else {
      throw new Error(response.data.error?.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error('Error creating employee:', error.message);
    throw error;
  }
};

export const getEmployeeData = async (token, pageIndex, pageSize) => {
  try {
    const response = await api.get('/api/employee/all', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        pageIndex,
        pageSize,
      },
    });

    if (response.data.successful) {
      return response.data.data;
    } else {
      console.error('Error fetching data:', response.data.error.message);
      return null;
    }
  } catch (error) {
    console.error('Request failed:', error);
    return null;
  }
};

export const getEmployeeById = async (employeeId, token) => {
  try {
    if (!employeeId) {
      throw new Error('employeeId is required');
    }
    const response = await api.get(`/api/employee/${employeeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to fetch employee data:',
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const updateEmployeeById = async (employeeId, updateData, token) => {
  try {
    if (!employeeId) {
      throw new Error('employeeId is required');
    }

    if (!updateData) {
      throw new Error('updateData is required');
    }

    const response = await api.put(`/api/employee/${employeeId}`, updateData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to update employee data:',
      error.response ? error.response.data : error.message
    );
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
    throw error;
  }
};

export const deleteEmployeeById = async (employeeId, token) => {
  try {
    if (!employeeId) {
      return { successful: false, message: 'Employee ID is required' };
    }

    const response = await api.delete(`/api/employee/${employeeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.successful) {
      return response.data;
    } else {
      return {
        successful: false,
        message: response.data.error.message || 'Unknown error occurred',
      };
    }
  } catch (error) {
    console.error(
      'Failed to delete employee data:',
      error.response ? error.response.data : error.message
    );
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
    return { successful: false, message: 'Failed to delete employee data' };
  }
};


//Admin dashboard
export const getEmployeeStatistics = async (token) => {
  try {
    if (!token) {
      return { successful: false, message: 'Permission denied' };
    }

    const response = await api.get('/api/employee/dashboard/statistics', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.successful) {
      return response.data;
    } else {
      return {
        successful: false,
        message: response.data.error || 'Unknown error occurred',
      };
    }

  } catch (error) {
    return { successful: false, message: 'Failed to get statistics' };
  }
};
