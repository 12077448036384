import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ItemsPerPageDropdown from '../../../shared/ItemsPerPageDropdown';
import PaginationControls from '../../../shared/PaginationControls';
import EmployeeTableHeader from './EmployeeTableHeader';
import EmployeeTableRow from './EmployeeTableRow';
import LoadingSkeletonEmp from './LoadingSkeletonEmp';
import Cookies from 'js-cookie';
import { getEmployeeData } from '../../../../api/empoyeeApi';
import { AccountType } from '../../../../static/enums/accountTypeEmum';
import usePageTitle from '../../../../hooks/usePageTitle';

const EmployeeList = ({ accountType }) => {
  usePageTitle('Employee List');
  const [employees, setEmployees] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const pageIndex = parseInt(searchParams.get('pageIndex'), 10) || 1;
    setCurrentPage(pageIndex);
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('accessToken');
      setIsLoading(true);
      try {
        const data = await getEmployeeData(
          token,
          currentPage - 1,
          itemsPerPage
        );
        if (data) {
          setEmployees(data.data);
          setTotalItems(data.paging.totalCount);
        } else {
          console.error('Unexpected data format:', data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [itemsPerPage, currentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const hasRecords = employees?.length > 0;

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const handleRowSelect = (index) => {
    const pageIndex = (currentPage - 1) * itemsPerPage + index;
    if (selectedRows.includes(pageIndex)) {
      setSelectedRows(selectedRows.filter((i) => i !== pageIndex));
    } else {
      setSelectedRows([...selectedRows, pageIndex]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAll(false);
    } else {
      const allIndexes = employees.map(
        (_, index) => (currentPage - 1) * itemsPerPage + index
      );
      setSelectedRows(allIndexes);
      setSelectAll(true);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSearchParams({ pageIndex: newPage });
    setSelectAll(false);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/employee/add');
  };

  return (
    <div className="bg-grey-light md:p-16 p-4 min-h-screen">
      <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
        Employee Listing
      </h1>
      <div className="flex justify-end items-center md:space-x-5 space-x-1 py-2 md:px-12 px-5">
        <button
          className="bg-[#E80345] disabled:bg-mc_light_grey md:mb-3 flex justify-center md:py-3 py-1 md:rounded-3xl rounded-lg w-1/7 md:text-xs xl:text-base text-[8px] shadow-sm items-center"
          onClick={handleClick}
          disabled={accountType === AccountType.Employee}
        >
          Staff
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="white"
            className="md:size-6 size-3 md:ml-2 ml-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </button>
      </div>
      <div className="flex flex-col justify-center items-center gap-8">
        <div className="w-full flex-col items-start bg-[#ffffff] md:py-10 py-2 rounded-lg md:px-12 px-1.5">
          <EmployeeTableHeader
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
          />

          {isLoading ? (
            <LoadingSkeletonEmp itemsPerPage={itemsPerPage} />
          ) : hasRecords? (
            employees.map((item, index) => (
              <EmployeeTableRow
                key={index}
                item={item}
                index={(currentPage - 1) * itemsPerPage + index}
                isSelected={selectedRows.includes(
                  (currentPage - 1) * itemsPerPage + index
                )}
                handleRowSelect={handleRowSelect}
                accountType={accountType}
              />
            ))
          ) : (
            <div className="text-center pt-10">No records available</div>
          )}
          <div className="flex justify-end space-x-5">
            <ItemsPerPageDropdown
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              totalItems={totalItems}
            />
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeList;
