import React from 'react';

const ModalConfirmation = ({
	isVisible,
	onConfirm,
	onCancel,
	pendingStatus,
	modalType,
}) => {
	if (!isVisible) return null;

	const getTextColor = () => {
		if (modalType === 'reject' || modalType === 'delete') {
			return 'text-red-500';
		} else if (modalType === 'approve') {
			return 'text-blue-500';
		}
		return 'text-black';
	};

	return (
		<div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
			<div className="bg-white p-5 rounded-md w-1/3">
				<div className="flex justify-end">
					<button onClick={onCancel} className="text-black">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="size-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M6 18 18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
				<h2
					className={`text-xl mb-10 px-10 text-center font-bold ${getTextColor()}`}
				>
					{pendingStatus}
				</h2>
				
				<div className="mt-4 flex justify-end space-x-4">
					<button
						onClick={onConfirm}
						className="bg-[#9DACFF] text-gray-600 flex justify-between px-4 py-2 w-full rounded-md hover:bg-[#828fdc]"
					>
						<p className="flex-1 text-center text-gray-800">Confirm</p>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={2}
							stroke="currentColor"
							className="size-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="m8.25 4.5 7.5 7.5-7.5 7.5"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ModalConfirmation;
