import api from './index';

export const getAllCountries = async (
	filter = '',
	pageSize = 32,
	pageIndex = 0
) => {
	try {
		const response = await api.post('/api/country/search', {
			filter: [
				{
					name: 'name',
					value: filter,
				},
			],
			pageSize,
			pageIndex,
		});
		return response.data.data.data;
	} catch (error) {
		throw new Error('Error fetching countries');
	}
};