import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../SideBar/SideBar";
import AppHeader from "../consts/AppHeader/AppHeader";

export default function Layout({accountType}) {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="font-poppins flex flex-col h-screen w-screen relative overflow-x-hidden">
      <AppHeader toggleSidebar={toggleSidebar} />

      <div className="flex flex-row flex-grow relative pt-0">
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10 xl:hidden"
            onClick={closeSidebar}
          ></div>
        )}

        <div
          className={`fixed md:top-20 left-0 h-full md:h-[calc(100vh-5rem)] bg-grey-main w-2/3 z-40 transform ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out xl:w-1/5 md:w-1/2 lg:w-1/3 xl:translate-x-0 lg:top-20 overflow-y-auto`}
        >
          <SideBar userType={accountType} />
        </div>

        <div className="flex-grow xl:ml-[20%] pt-10 md:pt-20">
          {" "}
          <Outlet />
        </div>
      </div>
    </div>
  );
}
