import React, { useState } from 'react';

const ResetPasswordModal = ({
	onClose,
	onPasswordChange,
	error,
	setError,
	success,
	setSuccess,
	disable,
}) => {
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [changeColor, setChangeColor] = useState(false);
	const [emptyFields, setEmptyFields] = useState(false);
	const handlePasswordChange = (e) => {
		setEmptyFields(false)
		setPassword(e.target.value);
		setError('');
		setSuccess('');
	};

	const handleConfirmPasswordChange = (e) => {
		setEmptyFields(false)
		setConfirmPassword(e.target.value);
		setChangeColor(false);
		setError('');
		setSuccess('');
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!password || !confirmPassword) {
			setError('All fields are required');
			setSuccess('');
			setEmptyFields(true);
		} else if (password !== confirmPassword) {
			setError('Passwords don’t match!');
			setSuccess('');
			setChangeColor(true);
			setConfirmPassword('');
		} else {
			onPasswordChange(password);
			setError('');
			setPassword('');
			setConfirmPassword('');
		}
	};

	const handleClose = () => {
		setError('');
		setSuccess('');
		setChangeColor(false);
		onClose();
	};

	return (
		<div className="fixed z-40 inset-0 flex items-center justify-center bg-black bg-opacity-50">
			<div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
				<div className="flex justify-end">
					<button onClick={handleClose} className="text-black">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="size-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
				<h2 className="text-2xl font-bold mb-14 text-center text-purple-900">
					Reset Password
				</h2>
				<form onSubmit={handleSubmit} className="relative">
					<div className="mb-4">
						<input
							type="password"
							placeholder="Enter Password here"
							className={`w-full px-4 py-2 border text-start rounded-lg outline-none placeholder:text-black placeholder:text-center focus:placeholder:text-transparent ${emptyFields ? 'bg-red-300' : ''}`}
							value={password}
							onChange={handlePasswordChange}
						/>
					</div>
					<div className="mb-4">
						<input
							type="password"
							placeholder="Re-enter your new Password"
							className={`w-full px-4 py-2 text-start border outline-none rounded-lg focus:placeholder:text-transparent placeholder:text-center ${changeColor ? 'bg-red-300 placeholder:text-red-500' : ' placeholder:text-black'} ${emptyFields ? 'bg-red-300' : ''}`}
							value={confirmPassword}
							onChange={handleConfirmPasswordChange}
						/>
					</div>
					{error && (
						<p className="text-red-500 text-sm text-center mb-4 absolute w-full">
							{error}
						</p>
					)}
					{success && (
						<p className="text-blue-900 text-sm text-center mb-4 absolute w-full">
							{success}
						</p>
					)}
					<button
						type="submit"
						className="bg-[#9DACFF] mt-16 text-gray-600 flex justify-between px-4 py-2 w-full rounded-md hover:bg-[#828fdc] disabled:bg-mc_light_grey"
						disabled={disable}
					>
						<p className="flex-1 text-center text-white">
							Confirm new password
						</p>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={2}
							stroke="currentColor"
							className="size-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="m8.25 4.5 7.5 7.5-7.5 7.5"
							/>
						</svg>
					</button>
				</form>
			</div>
		</div>
	);
};

export default ResetPasswordModal;
