import api from './index';

export const getStrategyRecords = async (token, pageIndex, pageSize) => {
  try {
    const response = await api.get('/api/strategy/all', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        pageIndex,
        pageSize,
      },
    });

    if (response.data.successful) {
      return response.data.data;
    } else {
      console.error('Error fetching data:', response.data.error.message);
      return null;
    }
  } catch (error) {
    console.error('Request failed:', error);
    return null;
  }
};

export const getStrategyById = async (strategyId, token) => {
  try {
    if (!strategyId) {
      throw new Error('strategyId is required');
    }
    const response = await api.get(`/api/strategy/${strategyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to fetch customer data:',
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const deleteStrategyById = async (strategyId, token) => {
  try {
    if (!strategyId) {
      throw new Error('strategyId is required');
    }

    const response = await api.delete(`/api/strategy/${strategyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to delete strategy:',
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const createStrategy = async (strategyData, token) => {
  try {
    const response = await api.post('/api/strategy', strategyData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to create strategy:',
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const updateStrategy = async (strategyId, strategyData, token) => {
  try {
    if (!strategyId) {
      throw new Error('strategyId is required');
    }

    const response = await api.put(
      `/api/strategy/${strategyId}`,
      strategyData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to update strategy:',
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const openStrategyLong = async (strategyId, token) => {
  try {
    if (!strategyId) {
      throw new Error('strategyId is required');
    }
    const response = await api.post(
      `/api/strategy/${strategyId}/long`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.successful) {
      return { successful: true, result: response.data.data };
    } else {
      return {
        successful: false,
        result: response.data.error?.message || 'Unknown server error',
      };
    }
  } catch (error) {
    const errorMessage = error.response?.data?.error?.message || error.message || 'Request failed';
    return { successful: false, result: errorMessage };
  }
};

export const openStrategyShort = async (strategyId, token) => {
  try {
    if (!strategyId) {
      throw new Error('strategyId is required');
    }
    const response = await api.post(
      `/api/strategy/${strategyId}/short`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.successful) {
      return { successful: true, result: response.data.data };
    } else {
      return {
        successful: false,
        result: response.data.error?.message || 'Unknown server error',
      };
    }
  } catch (error) {
    const errorMessage = error.response?.data?.error?.message || error.message || 'Request failed';
    return { successful: false, result: errorMessage };
  }
};
