import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    CUSTOMER_SIDEBAR_LINKS,
    AGENT_SIDEBAR_LINKS,
    ADMIN_SIDEBAR_LINKS,
    EMPLOYEE_SIDEBAR_LINKS,
} from "../consts/navigation";
import {AccountType} from "../../static/enums/accountTypeEmum";
import Cookies from "js-cookie";
import { ErrorContext } from "../ErrorProvider";

const SideBar = ({userType}) => {
    useEffect(() => {
    }, [userType]);
    let links;
    const navigate = useNavigate();

    if (userType === AccountType.Agent) {
        links = AGENT_SIDEBAR_LINKS;
    } else if (userType === AccountType.Customer) {
        links = CUSTOMER_SIDEBAR_LINKS;
    } else if (userType === AccountType.Admin) {
        links = ADMIN_SIDEBAR_LINKS;
    } else if (userType === AccountType.Employee) {
        links = EMPLOYEE_SIDEBAR_LINKS;
    } else {
        links = [];
    }

    function handleLogout() {
			Cookies.remove('accessToken', {
				path: '/',
			});
			navigate('/admin/login');
		}

    return (
        <div className="flex flex-col text-black py-10 gap-10">
            <div className="flex-1 flex flex-col px-10 justify-center">
                {links.map((item) => (
                    <SidebarLink key={item.key} item={item}/>
                ))}
            </div>
            <hr className="border-white "/>
            <div className="px-6">
                <button className="text-white w-full bg-gradient-to-r from-[#ed59fa] to-[#8028c5] py-2 rounded-lg"
                        onClick={handleLogout}>
                    Log out
                </button>
            </div>
        </div>
    );
};

function SidebarLink({item}) {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const handleToggle = () => {
        if (item.children) {
            setIsOpen(!isOpen);
        }
    };

    const isActive = location.pathname === item.path;

    const { hasErrorType1, hasErrorType2 } = useContext(ErrorContext);

    return (
        <div>
            <button
                onClick={handleToggle}
                className={`flex items-center justify-between w-full py-4 text-xs font-medium ${
                    item.children ? "hover:text-purple-circle" : ""
                }`}
            >
                <Link
                    to={item.path}
                    className={`flex items-center w-full text-start hover:text-purple-circle xl:text-[13px] text-[11px] font-medium ${
                        isActive ? "text-purple-circle font-bold" : "text-black"
                    }`}
                >
                    {item.icon}
                    <span className="ml-2">{item.label}</span>
                </Link>
                {(hasErrorType1 || hasErrorType2 ) && item.error}
                {item.arrow && (
                    <div
                        className={`flex items-center ml-2 transition-transform duration-300 ${
                            isOpen ? "rotate-90" : ""
                        }`}
                    >
                        {item.arrow}
                    </div>
                )} 
            </button>
            {item.children && isOpen && (
                <div className="mx-7 mt-2">
                    {item.children.map((child) => (
                        <Link
                            key={child.key}
                            to={child.path}
                            className={`flex items-center justify-between py-2 xl:text-[13px] text-[11px] font-medium hover:text-purple-circle ${
                                location.pathname === child.path
                                    ? "text-purple-500"
                                    : "text-black"
                            }`}
                        >
                            {child.label}
                            {hasErrorType1 && child.errorType1}
                            {hasErrorType2 && child.errorType2}

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-4"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                />
                            </svg>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
}

export default SideBar;
