import React from 'react';

const StrategyTableHeader = ({ selectAll, handleSelectAll }) => {
  return (
    <div className="flex justify-center items-center md:px-6 px-1 py-2 font-bold text-sm">
      <span className="tr_style w-1/7">№</span>
      <span className="tr_style w-1/7">Symbol</span>
      <span className="tr_style w-1/7">Strategy ID</span>
      <span className="tr_style w-1/7">Status</span>
      <span className="tr_style w-1/7">Result (Win/Loss)</span>
      <span className="tr_style w-1/7">Creation Date</span>
      <span className="tr_style w-1/7">Action</span>
    </div>
  );
};

export default StrategyTableHeader;
