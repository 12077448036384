// AuthRoutes.js
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import RegistrationPage from '../pages/RegistrationPage';
import ConnectExchangePage from '../pages/ConnectExchangePage';
import { createUserAccount } from '../api/securityApi';
import ConnectExchangeRedirect from "../pages/ConnectExchangeRedirect";

const AuthRoutes = () => {
    const [tempAccountID,  setTempAccountID]=useState('')
	const createAccount = async (formData) => {
		try {
			const result = await createUserAccount(formData);

			if (result.successful) {
				const tempAccountId = result.data.data.tempAccountId;
                setTempAccountID(tempAccountId);
				return result;
			} else {
				
                return result
            }
		} catch (error) {
			console.error('Error creating account:', error);
		}
	};
	return (
		<Routes>
			<Route path="/login" element={<LoginPage />} />
			<Route
				path="/register"
				element={<RegistrationPage createAccount={createAccount} />}
			/>
			<Route path="/connect" element={<ConnectExchangePage tempAccountId={tempAccountID} />} />
			<Route path="/exchange/success" element={<ConnectExchangeRedirect/>} />
		</Routes>
	);
};

export default AuthRoutes;
