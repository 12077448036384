import React, {useEffect, useState} from 'react';
import {getAllCountries} from '../../../api/countryApi';
import DropDownCountry from '../../shared/DropDownCountry';
//import DropdownStatus from '../../shared/DropDownStatus';
import RadioValue from '../../shared/RadioValue';
import Gender from '../../../static/enums/genderEnum';
import Status from '../../../static/enums/statusEnum';
import Cookies from 'js-cookie';
import Modal from '../../shared/Modal';
import {deleteAgentById, updateAgentProfile} from '../../../api/agentApi';
import {changeOwnPassword} from '../../../api/accountApi';
import {useNavigate} from 'react-router-dom';
import ResetPasswordModal from '../../shared/ResetPasswordModal';

const AgentInfo = ({agentInfo}) => {
    const navigate = useNavigate();
    const [status, setStatus] = useState(agentInfo?.account?.status);
    const [countryDropdown, setCountryDropdown] = useState(false);
    const [country, setCountry] = useState({
        selectedFlag: '',
        selectedName: '',
        selectedId: '',
    });
    const [selectedGender, setSelectedGender] = useState(1);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [age, setAge] = useState('');
    const [phone, setPhone] = useState('');
    const [agentID, setAgentID] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [countries, setCountries] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalType, setModalType] = useState('success');
    // eslint-disable-next-line no-unused-vars
    const [password, setPassword] = useState('');
    const [searchCountry, setSearchCountry] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [isAccountDeleted, setIsAccountDeleted] = useState(false);
    const [successPassword, setSuccessPassword] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        setStatus(agentInfo?.account?.status);
    }, [agentInfo]);

    useEffect(() => {
        const isChanged =
            firstName !== agentInfo?.account?.firstName ||
            lastName !== agentInfo?.account?.lastName ||
            email !== agentInfo?.account?.email ||
            age !== agentInfo?.account?.age ||
            phone !== agentInfo?.account?.phone ||
            (country.selectedId || '') !== (agentInfo?.account?.country?.id || '') ||
            selectedGender !== agentInfo?.account?.gender ||
            status !== agentInfo?.account?.status;

        setIsFormChanged(isChanged);
    }, [
        firstName,
        lastName,
        email,
        age,
        phone,
        country,
        selectedGender,
        status,
        agentInfo,
    ]);

    useEffect(() => {
        if (agentInfo) {
            setFirstName(agentInfo.account.firstName || '');
            setLastName(agentInfo.account.lastName || '');
            setEmail(agentInfo.account.email || '');
            setAge(agentInfo.account.age || '');
            setPhone(agentInfo.account.phone || '');
            setAgentID(agentInfo.id || '');
            setSelectedGender(agentInfo.account.gender || 1);

            setCountry({
                selectedFlag: agentInfo.account.country?.flagUrl || '',
                selectedName: agentInfo.account.country?.name || '',
                selectedId: agentInfo.account.country?.id || '',
            });

            setInputValue(agentInfo.account.country?.name || '');
            setStatus(agentInfo.account.status || 3);
        }
    }, [agentInfo]);

    useEffect(() => {
        if (!showSaveModal && isAccountDeleted) {
            navigate('/admin/login');
        }
    }, [showSaveModal, isAccountDeleted, navigate]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const data = await getAllCountries(searchCountry);
                setCountries(data || []);
            } catch (error) {
                console.error('Failed to fetch countries:', error);
            }
        };
        fetchCountries();
    }, [searchCountry]);

    const openCountryDropdownHandler = (e) => {
        e.preventDefault();
        setCountryDropdown(!countryDropdown);
    };

    const closeCountryDropdownHandler = () => {
        setCountryDropdown(false);
    };

    const handleCountrySelect = (flagUrl, name, id) => {
        setCountry({
            selectedFlag: flagUrl,
            selectedName: name,
            selectedId: id,
        });
        setInputValue(name);
        closeCountryDropdownHandler();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (status === 0) {
            try {
                const token = Cookies.get('accessToken');
                if (!token) {
                    return {account: null, statusCode: 401};
                }
                const result = await deleteAgentById(agentInfo.id, token);
                if (result.successful) {
                    setModalMessage('Account delete successfully!');
                    setShowSaveModal(true);
                    setIsFormChanged(false);
                    setIsAccountDeleted(true);
                } else {
                    setModalMessage(result.message || 'Failed to delete the account');
                    setModalType('error');
                    setShowSaveModal(true);
                }
            } catch (error) {
                setModalMessage('An unexpected error occurred');
                setModalType('error');
                setShowSaveModal(true);
            }
        } else {
            const formData = {
                firstName,
                lastName,
                email,
                age,
                phone,
                countryId: country.selectedId,
                agentID,
                gender: selectedGender,
                status,
                passphrase: '',
            };

            try {
                const token = Cookies.get('accessToken');
                await updateAgentProfile(formData, token);
                setModalMessage(['Agent updated successfully!']);
                setModalType('success');
                setIsFormChanged(false);
            } catch (error) {
                setModalMessage('Failed to update agent data. Please try again.');
                setModalType('error');
            }

            setIsModalOpen(true);
        }
    };

    const handlePasswordChange = async (password) => {
        try {
            const response = await changeOwnPassword(password);
            if (response.successful) {
                setSuccessPassword('Password reset successful!');
                setDisable(true);
            } else {
                setErrorPassword('Password change failed');
            }
        } catch (error) {
            setErrorPassword('Password change failed');
        }
    };

    return (
        <div className="p-1">
            <form onSubmit={handleSubmit}>
                {/* Form Grid */}
                <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
                    {/* Full Name */}
                    <div>
                        <label className="block md:text-sm text-[6px] font-medium text-gray-700 disabled:bg-mc_white">
                            First Name
                        </label>
                        <input
                            type="text"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            disabled
                        />
                    </div>
                    {/* Last Name */}
                    <div>
                        <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                            Last Name
                        </label>
                        <input
                            type="text"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            disabled
                        />
                    </div>
                    {/* Email */}
                    <div>
                        <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                            Email
                        </label>
                        <input
                            type="email"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled
                        />
                    </div>
                </div>
                <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
                    {/* Age */}
                    <div>
                        <label className="block md:text-sm text-[6px] font-medium text-gray-700 disabled:bg-mc_white">
                            Age
                        </label>
                        <input
                            type="number"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            value={age}
                            onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                setAge(newValue >= 0 ? newValue : 0);
                            }}
                            min={0}
                            disabled
                        />
                    </div>
                    {/* Mobile */}
                    <div>
                        <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                            Mobile
                        </label>
                        <input
                            type="text"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            disabled
                        />
                    </div>
                    {/* Country */}
                    <div>
                        <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                            Country
                        </label>
                        <div>
                            <button
                                onClick={openCountryDropdownHandler}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-left disabled:bg-mc_white"
                                disabled
                            >
                                {country.selectedId ? (
                                    <span>{country.selectedName}</span>
                                ) : (
                                    <span className="text-xs">No selected country</span>
                                )}
                            </button>
                            {countryDropdown && (
                                <DropDownCountry
                                    countries={countries}
                                    onCountrySelect={handleCountrySelect}
                                    onClose={closeCountryDropdownHandler}
                                    value={inputValue}
                                    searchValue={searchCountry}
                                    setSearchValue={setSearchCountry}
                                    onChange={(e) => setInputValue(e.target.value)}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
                    {/* Agent ID */}
                    <div>
                        <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                            Agent ID
                        </label>
                        <input
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                            value={agentID}
                            onChange={(e) => setAgentID(e.target.value)}
                            required
                            disabled
                        />
                    </div>
                    <div>
                        <label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-1">
                            Password
                        </label>
                        <div className="relative">
                            <input
                                type="password"
                                className="block w-full p-2 border border-gray-300 rounded-md"
                                value="********"
                                readOnly
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter Password here"
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-0 flex items-center px-3 "
                                onClick={() => setIsModalOpen(true)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6 text-gray-500"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"/>
                                </svg>
                            </button>
                        </div>
                    </div>

                    {/* Gender */}
                    <div>
                        <label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-4">
                            Gender
                        </label>
                        <div className="mt-1 flex-row items-center">
                            <RadioValue
                                name="gender"
                                items={Gender}
                                value={selectedGender}
                                onChange={setSelectedGender}
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <hr className='my-5'/>
                {/* Status */}
                <div className="flex justify-end">
                    <div className="w-1/3">
                        <div className="flex-1">
                            <label className="block md:text-sm mb-2 text-[6px] font-medium text-gray-700">
                                Status
                            </label>
                            <div
                                className={`w-full p-3 rounded-full md:text-base text-[8px] text-center text-white ${
                                    Status.find((s) => s.value === status)?.label === 'Active'
                                        ? 'bg-green-500'
                                        : Status.find((s) => s.value === status)?.label ===
                                        'Blocked'
                                            ? 'bg-[#ed525a]'
                                            : 'bg-blue-500'
                                }`}
                            >
                                {Status.find((s) => s.value === status)?.label || ''}
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <Modal
                isOpen={showSaveModal}
                onClose={() => setShowSaveModal(false)}
                message={modalMessage}
                type={modalType}
            />

            {/* Modal Rendering */}
            {isModalOpen && (
                <ResetPasswordModal
                    onClose={() => {
                        setIsModalOpen(false);
                        setDisable(false);
                    }}
                    onPasswordChange={handlePasswordChange}
                    error={errorPassword}
                    setError={(e) => setErrorPassword(e)}
                    success={successPassword}
                    setSuccess={(s) => setSuccessPassword(s)}
                    disable={disable}
                />
            )}
        </div>
    );
};

export default AgentInfo;