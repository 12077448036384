import React, { useState, useEffect } from 'react';
import StrategyTableHeader from './StrategyTableHeader';
import StrategyTableRow from './StrategyTableRow';
import LoadingSkeleton from './LoadingSkeleton';
import PaginationControls from '../../shared/PaginationControls';
import ItemsPerPageDropdown from '../../shared/ItemsPerPageDropdown';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getStrategyRecords } from '../../../api/parametersApi';
import usePageTitle from '../../../hooks/usePageTitle';

const StrategyRecord = ({ accountType }) => {
  usePageTitle('Strategies');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [records, setRecords] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const pageIndex = parseInt(searchParams.get('pageIndex'), 10) || 1;
    setCurrentPage(pageIndex);
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('accessToken');
      if (!token) {
        return { account: null, statusCode: 401 };
      }
      setIsLoading(true);
      try {
        const data = await getStrategyRecords(
          token,
          currentPage - 1,
          itemsPerPage
        );
        if (data) {
          setRecords(data.data);
          setTotalItems(data.paging.totalCount);
        } else {
          console.error('Unexpected data format:', data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, itemsPerPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const currentData = records;

  const hasRecords = currentData?.length > 0;

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const handleRowSelect = (index) => {
    const pageIndex = (currentPage - 1) * itemsPerPage + index;
    if (selectedRows.includes(pageIndex)) {
      setSelectedRows(selectedRows.filter((i) => i !== pageIndex));
    } else {
      setSelectedRows([...selectedRows, pageIndex]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAll(false);
    } else {
      const allIndexes = currentData.map(
        (_, index) => (currentPage - 1) * itemsPerPage + index
      );
      setSelectedRows(allIndexes);
      setSelectAll(true);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSearchParams({ pageIndex: newPage });
    setSelectAll(false);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  return (
    <div className="bg-grey-light md:p-16 p-4 min-h-screen">
      <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
        Strategy Setting Record
      </h1>
      <div className="flex justify-end items-center py-2 md:px-20 px-5">
        <span className="font-semibold md:text-sm text-[6px]">
          Total({totalItems})
        </span>
      </div>
      <div className="flex flex-col justify-center items-center gap-8">
        <div className="w-full flex-col items-start bg-[#ffffff] md:py-10 py-2 rounded-lg md:px-12 px-1.5">
          <StrategyTableHeader
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
          />

          {isLoading ? (
            <LoadingSkeleton itemsPerPage={itemsPerPage} />
          ) : hasRecords ? (
            currentData.map((item, index) => (
              <StrategyTableRow
                key={index}
                item={item}
                index={(currentPage - 1) * itemsPerPage + index}
                isSelected={selectedRows.includes(
                  (currentPage - 1) * itemsPerPage + index
                )}
                handleRowSelect={handleRowSelect}
                accountType={accountType}
              />
            )) ) : (
              <div className="text-center pt-10">No records available</div>
            )}

          <div className="flex justify-end space-x-5">
            <ItemsPerPageDropdown
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              totalItems={totalItems}
            />
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategyRecord;
