export const StrategySymbol = Object.freeze({
    BTCUSDT: 1,
    ETHUSDT: 2
  });

  export const StrategyStatus = Object.freeze({
    Active: 1,
    Inactive: 2
  });
  
  export const OrderResult = Object.freeze({
    Win: 1,
    Loss: 2
  });

  export const EntryType  = Object.freeze({
    Manual: 1,
    API: 2
  });