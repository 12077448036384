import React, { useState, useEffect } from 'react';
import PaginationControls from '../../shared/PaginationControls';
import ItemsPerPageDropdown from '../../shared/ItemsPerPageDropdown';
import LoadingSkeletonOrder from './LoadingSkeletonOrder';
import OrderTableHead from './OrderTableHead';
import OrderTableRow from './OrderTableRow';
import DropdownSort from '../../shared/DropDownSort';
import Cookies from 'js-cookie';
import {
	getAllSymbols,
	getOrdersData,
	getStatistics,
} from '../../../api/orderApi';
import usePageTitle from '../../../hooks/usePageTitle';
import { useSearchParams } from 'react-router-dom';

const OrderAlert = ({ accountType }) => {
	usePageTitle('Order List');
	const [orders, setOrders] = useState([]);
	const [symbolOptions, setSymbolOptions] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const [itemsPerPage, setItemsPerPage] = useState(50);
	const [totalItems, setTotalItems] = useState(0);
	const [selectedSymbol, setSelectedSymbol] = useState(null);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [statistic, setStatistic] = useState({
		win: 0,
		loss: 0,
	});
	

	const totalPages = Math.ceil(totalItems / itemsPerPage);
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const pageIndex = parseInt(searchParams.get('pageIndex'), 10) || 1;
		const symbol = searchParams.get('symbol');
		const month = searchParams.get('month');

		setCurrentPage(pageIndex);

		if (symbolOptions.length === 0) {
			return;
		}
		
		if (symbol) {
			const foundSymbol = symbolOptions.find(
				(option) => option.value === symbol
			);
			setSelectedSymbol(
				foundSymbol ? foundSymbol : { value: foundSymbol.value, label: foundSymbol.label }
			);
		} else {
			setSelectedSymbol('');
		} 
		

		if (month) {
		  const foundMonth = monthOptions.find((option) => option.value === month);
		  setSelectedMonth(
			foundMonth ? foundMonth : { value: foundMonth.value, label: foundMonth.label }
		  );
		} else {
			setSelectedMonth('');
		}

	}, [searchParams, symbolOptions]);

	useEffect(() => {
		const fetchData = async () => {
			const token = Cookies.get('accessToken');
			setIsLoading(true);
			try {
				const filters = [
					{
						name: 'id',
						value: '',
					},
				];
				if (selectedMonth && selectedMonth.value !== '' && selectedMonth.value !== 'all') {
					filters.push({
						name: 'datetimeInserted',
						value: getMonthYearString(selectedMonth.value),
					});
				}
				if (selectedSymbol) {
					filters.push({
						name: 'strategy.symbol',
						value: selectedSymbol.value === 'all'? '': selectedSymbol.value,
					});
				}
				const data = await getOrdersData(
					token,
					currentPage - 1,
					itemsPerPage,
					filters
				);
				if (data) {
					setOrders(data.data);
					setTotalItems(data.paging.totalCount);
				} else {
					console.error('Unexpected data format:', data);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [itemsPerPage, currentPage, selectedMonth, selectedSymbol]);

	useEffect(() => {
		const fetchSymbols = async () => {
			const token = Cookies.get('accessToken');
			try {
				const result = await getAllSymbols(token);
				if (result && result.successful) {
					const symbols = result.data.symbols;
					setSymbolOptions([
						{ value: 'all', label: 'All Symbols' },
						...symbols.map((name) => ({ value: name, label: name })),
					]);
				} else {
					console.error('Error fetching symbols:', result.error.message);
				}
			} catch (error) {
				console.error('Error fetching symbols:', error);
			}
		};

		fetchSymbols();
	}, []);

	useEffect(() => {
		const fetchStatistics = async () => {
			const token = Cookies.get('accessToken');
			try {
				const result = await getStatistics(token);
				if (result && result.successful) {
					setStatistic({ win: result.data.win, loss: result.data.loss });
				} else {
					console.error('Error fetching symbols:', result.error.message);
				}
			} catch (error) {
				console.error('Error fetching symbols:', error);
			}
		};

		fetchStatistics();
	}, []);

	const getMonthYearString = (monthYear) => {
		const [month, year] = monthYear.split(/(\d{4})/).filter(Boolean);
		const monthIndex = new Date(Date.parse(month + ' 1, 2020')).getMonth() + 1; // Преобразуем сокращенное название месяца в индекс и добавляем 1
		return `${year}-${monthIndex.toString().padStart(2, '0')}`; // Форматируем как YYYY-MM
	};

	const generateMonthsForRange = () => {
		const minDate = new Date(2024, 4, 1);
		const maxDate = new Date();

		const startYear = minDate.getFullYear();
		const endYear = maxDate.getFullYear();

		let allMonths = [{ value: 'all', label: 'All month' }];

		for (let year = endYear; year >= startYear; year--) {
			for (let month = 11; month >= 0; month--) {
				const date = new Date(year, month);

				if (year === 2024 && month < 4) continue;

				if (year === endYear && month > maxDate.getMonth()) continue;

				allMonths.push({
					value: `${date.toLocaleString('en-GB', { month: 'short' })}${year}`,
					label: `${date.toLocaleString('en-GB', { month: 'short' })} ${year}`,
				});
			}
		}

		return allMonths;
	};
	const monthOptions = generateMonthsForRange();

	const handleMonthChange = (selectedOption) => {
		setSelectedMonth(selectedOption);
		setSearchParams({
			pageIndex: 1,
			symbol: selectedSymbol?.value || '',
			month: selectedOption?.value || '',
		  });
		setCurrentPage(1);
	};



	const currentData = orders;

	const hasRecords = currentData?.length > 0;

	const handleRowSelect = (index) => {
		const pageIndex = (currentPage - 1) * itemsPerPage + index;
		if (selectedRows.includes(pageIndex)) {
			setSelectedRows(selectedRows.filter((i) => i !== pageIndex));
		} else {
			setSelectedRows([...selectedRows, pageIndex]);
		}
	};


	const handleSelectAll = () => {
		if (selectAll) {
			setSelectedRows([]);
			setSelectAll(false);
		} else {
			const allIndexes = currentData.map(
				(_, index) => (currentPage - 1) * itemsPerPage + index
			);
			setSelectedRows(allIndexes);
			setSelectAll(true);
		}
	};
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
		setSearchParams({
		  pageIndex: newPage,
		  symbol: selectedSymbol?.value || '',
		  month: selectedMonth?.value || '',
		});
		setSelectAll(false);
	  };
	

	const handleItemsPerPageChange = (event) => {
		const newPageSize = Number(event.target.value);
		setItemsPerPage(newPageSize);
		setCurrentPage(1);	
	};

	const handleSymbolChange = (selectedOption) => {
		setSelectedSymbol(selectedOption);
		setSearchParams({
			pageIndex: 1,
			symbol: selectedOption?.value || '',
			month: selectedMonth?.value || '',
		  });
		setCurrentPage(1);
	};

	const handleResetFilters = () => {
		setSelectedSymbol(null);
		setSelectedMonth(null);
		setSearchParams({});

		setCurrentPage(1);
	};

	return (
		<div className="bg-grey-light md:p-16 p-4 min-h-screen">
			<h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
				Order & Alert
			</h1>
			<div className="flex justify-end items-center md:space-x-5 space-x-1 py-2 px-5">
				<span className="font-semibold md:text-sm text-[6px]">
					TotalDeal({totalItems})
				</span>
				<span className="font-semibold md:text-sm text-[6px]">
					Win({statistic.win})
				</span>
				<span className="font-semibold md:text-sm text-[6px]">
					Loss({statistic.loss})
				</span>
				<div className="xl:w-1/7">
					<DropdownSort
						options={symbolOptions}
						selected={selectedSymbol}
						onChange={handleSymbolChange}
						title={'Symbol'}
					/>
				</div>
				<div className="xl:w-1/7">
					<DropdownSort
						options={monthOptions}
						selected={selectedMonth}
						onChange={handleMonthChange}
						title={'Month'}
					/>
				</div>
				<button onClick={handleResetFilters}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={1.5}
						stroke="currentColor"
						className="md:size-6 size-3"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
						/>
					</svg>
				</button>
			</div>
			<div className="flex flex-col justify-center items-center gap-8">
				<div className="w-full items-start bg-[#ffffff] md:py-10 py-2 rounded-lg md:px-12 px-1.5">
					{/* {selectedMonth && (
						<div className="text-end md:text-base text-[8px] text-gray-700 font-semibold mb-4">
							{selectedMonth.label} ({monthEntriesCount})
						</div>
					)} */}
					<OrderTableHead
						selectAll={selectAll}
						handleSelectAll={handleSelectAll}
					/>
					{isLoading ? (
						<LoadingSkeletonOrder itemsPerPage={itemsPerPage} />
					) : hasRecords ? (
						currentData.map((item, index) => (
							<OrderTableRow
								key={index}
								item={item}
								index={(currentPage - 1) * itemsPerPage + index}
								isSelected={selectedRows.includes(
									(currentPage - 1) * itemsPerPage + index
								)}
								handleRowSelect={handleRowSelect}
								accountType={accountType}
							/>
						))
					) : (
						<div className="text-center pt-10">No records available</div>
					)}
					<div className="flex justify-end space-x-5">
						<ItemsPerPageDropdown
							itemsPerPage={itemsPerPage}
							handleItemsPerPageChange={handleItemsPerPageChange}
							totalItems={totalItems}
						/>
						<PaginationControls
							currentPage={currentPage}
							totalPages={totalPages}
							handlePageChange={handlePageChange}
							itemsPerPage={itemsPerPage}
							totalItems={totalItems}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderAlert;
