import React from 'react';
import { PlatformType } from '../../static/enums/platformTypeEnum';

const ConnectionDropdown = ({ onSelect }) => {
	return (
		<div className="absolute w-full bg-white py-3 z-50 rounded-lg shadow-lg">
			{PlatformType.length > 0 ? (
				PlatformType.map((exchange) => (
					<button
						key={exchange.id}
						onClick={() => onSelect(exchange)}
						className={`py-2 px-5 flex justify-between w-full ${
							exchange.status
								? 'hover:bg-mc_blue hover:bg-opacity-25'
								: 'cursor-not-allowed'
						}`}
						disabled={!exchange.status}
					>
						<div className="flex items-center">
							<img src={exchange.logo} alt="" className="w-7" />
							<div className="ml-3 text-sm font-semibold">{exchange.name}</div>
						</div>
						{exchange.status ? (
							<div className="text-mc_grey text-sm flex w-auto">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									className="size-5 mr-2"
								>
									<path
										fillRule="evenodd"
										d="M19.902 4.098a3.75 3.75 0 0 0-5.304 0l-4.5 4.5a3.75 3.75 0 0 0 1.035 6.037.75.75 0 0 1-.646 1.353 5.25 5.25 0 0 1-1.449-8.45l4.5-4.5a5.25 5.25 0 1 1 7.424 7.424l-1.757 1.757a.75.75 0 1 1-1.06-1.06l1.757-1.757a3.75 3.75 0 0 0 0-5.304Zm-7.389 4.267a.75.75 0 0 1 1-.353 5.25 5.25 0 0 1 1.449 8.45l-4.5 4.5a5.25 5.25 0 1 1-7.424-7.424l1.757-1.757a.75.75 0 1 1 1.06 1.06l-1.757 1.757a3.75 3.75 0 1 0 5.304 5.304l4.5-4.5a3.75 3.75 0 0 0-1.035-6.037.75.75 0 0 1-.354-1Z"
										clipRule="evenodd"
									/>
								</svg>
								Quick Connect
							</div>
						) : (
							<div className="text-mc_grey text-sm">Coming soon</div>
						)}{' '}
					</button>
				))
			) : (
				<div className="p-2">No results found</div>
			)}
		</div>
	);
};

export default ConnectionDropdown;
