import React from 'react';

const NotFound = () => {
  return (
    <div className="bg-gray-950 min-h-screen flex flex-col justify-center items-center font-poppins">
      <span className="text-white text-7xl mb-5 font-semibold">404</span>
      <span className="text-white font-light">
        Sorry, we were unable to find that page
      </span>
    </div>
  );
};

export default NotFound;
