import React from 'react';

const OrderTableHead = ({ selectAll, handleSelectAll }) => {
	return (
		<div className="flex items-center md:px-6 px-1 py-2 font-bold text-sm bg-white">
			<span className="w-1/7 tr_style">№</span>	
			<span className="w-1/7 tr_style">Transaction ID</span>
			<span className="w-1/7 tr_style">Symbol/Token</span>
			<span className="w-1/7 tr_style">Strategy ID</span>
			<span className="w-1/7 tr_style">Transaction Date</span>
			<span className="w-1/7 tr_style">Alert Trigger (Date/Time)</span>
			<span className="w-1/7 text-center flex flex-col justify-center items-center xl:text-sm md:text-[10px] text-[8px] font-bold">
				<span className="">Status</span>
				<span className="leading-tight 2xl:text-sm xl:text-[10px] md:text-[8px] text-[6px] font-semibold">
					Close Position Manually
				</span>
				<span className="leading-tight 2xl:text-sm xl:text-[10px] md:text-[8px] text-[6px] font-semibold">
					(Market Price)
				</span>
			</span>
		</div>
	);
};

export default OrderTableHead;
