import React from 'react';

const ToggleSwitch = ({ enabled, setEnabled, disabled }) => {
	return (
		<div
			className={`${
				enabled ? 'bg-green-500' : 'bg-red-500'
			} relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-300 ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
			onClick={() => setEnabled(!enabled)}
		>
			<span
				className={`${
					enabled ? 'translate-x-6' : 'translate-x-1'
				} inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-300`}
			/>
		</div>
	);
};

export default ToggleSwitch;
