import React, { useEffect, useRef, useState } from 'react';
import CodeInputs from '../additional/CodeInputs';

import {
	requestVerification,
	resetForgottenPasswoord,
	verifyForgottenPassword,
} from '../../api/securityApi';

const AdminResetPassword = ({ isOpen, onClose, setIsOpenModal }) => {
	const [code, setCode] = useState(Array(6).fill(''));
	const [sendCode, setSendCode] = useState('');
	const [message, setMessage] = useState(null);
	const [password, setPassword] = useState(null);
	const [isEmailInputOpen, setIsEmailInputOpen] = useState(true);
	const [disableButton, setDisableButton] = useState(true);
	const [userData, setUserData] = useState({
		email: '',
		accountId: '',
	});

	const [errors, setErrors] = useState({
		email: false,
		code: false,
		newPassword: false,
		confirmPassword: false,
	});
	const [formError, setFormError] = useState(false);
	const [error, setError] = useState('');
	const [verifyCodeForm, setVerifyCodeForm] = useState(false);
	const formRef = useRef();

	const handleEmailSubmit = async (e) => {
		e.preventDefault();
		const newErrors = {
			email: !e.target.email.value,
		};
		setErrors(newErrors);
		if (Object.values(newErrors).some((error) => error)) {
			setFormError(true);
			setError('Enter email');
			return;
		}
		setFormError(false);

		try {
			const result = await resetForgottenPasswoord(e.target.email.value);
			if (result.successful) {
				setUserData({
					email: result.data.email,
					accountId: result.data.accountId,
				});
				setErrors({
					email: false,
				});
				setVerifyCodeForm(true);
				setIsEmailInputOpen(false);
				setError('');
			} else {
				setFormError(true);
				setError(result.error.message);
			}
		} catch (error) {
			setError('An error occurred while sending the reset password request.');
		}
	};

	const handleInputChange = (e) => {
		const { name } = e.target;
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: false,
			confirmPassword:
				name === 'password1' || name === 'password2'
					? false
					: prevErrors.confirmPassword,
		}));
		setFormError(false);
	};

	const sendVerificationCode = async () => {
		const { accountId, email } = userData;

		const result = await requestVerification({ accountId, email });

		if (!result.successful) {
			setMessage({
				type: 'error',
				text: result.error?.message || 'Failed to send verification code',
			});
		}
	};
	useEffect(() => {
		if (verifyCodeForm) {
			sendVerificationCode();
		}
	}, [verifyCodeForm]);

	const handleResendCode = async () => {
		sendVerificationCode();
	};

	const handleCodeChange = async (newCode) => {
		setCode(newCode);

		if (newCode.length === 6 && !newCode.includes('')) {
			const codeEntered = newCode.join('');

			setSendCode(codeEntered);
		}
	};

	useEffect(() => {
		if (formRef.current) {
			const { password1, password2 } = formRef.current.elements;

			const isFormValid = sendCode !== 0 && password1.value && password2.value;
			setDisableButton(!isFormValid);
		}
	}, [sendCode, errors]);

	const handleResetPassword = async (e) => {
		e.preventDefault();
		const newErrors = {
			code: sendCode === '',
			password1: !e.target.password1.value,
			password2: !e.target.password2.value,

			confirmPassword:
				e.target.password2.value.length > 0 &&
				e.target.password1.value !== e.target.password2.value,
		};

		setErrors(newErrors);
		console.log(Error)

		if (Object.values(newErrors).some((error) => error)) {
			if (Error.confirmPassword) {
				e.target.password2.value = '';
				setError('Passwords didn`t match');
			} else {
				setError('All fields are required');
			}
			return;
		} 

		setFormError(false);

		try {
			const { accountId } = userData;
			const result = await verifyForgottenPassword(
				sendCode,
				accountId,
				e.target.password1.value
			);
			if (result && result.successful) {
				setIsOpenModal(true);
				handleClose();
			} else {
				setMessage({
					type: 'error',
					text: result?.error?.message || 'Verification failed',
				});
			}
		} catch (error) {
			setError('An error occurred while sending the reset password request.');
		}
	};

	const handleClose = () => {
		setError('');
		setVerifyCodeForm(false);
		setCode(Array(6).fill(''));
		setMessage('');
		setErrors({
			email: false,
			password1: false,
			password2: false,
		});
		setIsEmailInputOpen(true);
		setSendCode('');
		setPassword(null);
		onClose();
	};

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 flex items-center justify-center z-50 font-sans bg-black bg-opacity-50">
			<div className="bg-white p-6 rounded-lg shadow-lg w-2/5">
				<div className="w-full flex flex-col">
					<div className="w-full flex flex-col justify-center items-center">
						<div className="relative w-full">
							<button onClick={handleClose} className="absolute top-0 right-0">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="#444444"
									className="size-5"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M6 18 18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
						{isEmailInputOpen && (
							<>
								<h2 className="w-full text-2xl font-bold mb-5 text-center text-purple-900">
									Reset Password
								</h2>
								<form
									onSubmit={handleEmailSubmit}
									className="w-full px-6 relative"
								>
									<div className="mb-3">
										<input
											type="email"
											name="email"
											onChange={handleInputChange}
											placeholder="Enter your email"
											className={`w-full px-4 py-2 border-none  outline-none bg-[#F3F5F7] text-left rounded-lg text-black placeholder:text-[#3D3E40] placeholder:text-center focus:placeholder:text-transparent ${
												errors.email
													? 'bg-red-500 bg-opacity-30'
													: 'bg-[#F3F5F7]'
											}`}
										/>
									</div>
									{error && formError && (
										<p className="absolute left-1/2 transform -translate-x-1/2 text-red-500 text-sm text-center w-full mb-4">
											{error}
										</p>
									)}
									<div className="w-full flex justify-end">
										<button
											type="submit"
											className="bg-[#9DACFF] mt-5 text-center px-4 py-1 text-sm rounded-md border border-mc_light_grey"
										>
											Send
										</button>
									</div>
								</form>
							</>
						)}
					</div>
					{verifyCodeForm && (
						<div className="w-full mt-6">
							<div className="font-semibold text-xl font-poppins w-full text-center">
								2FA verification
							</div>

							<div className="flex flex-col w-full justify-center items-center">
								<div className="text-base font-normal m-4">
									Enter the code we sent to your email.
								</div>
								<CodeInputs code={code} onCodeChange={handleCodeChange} />
								<div className="relative w-full text-end">
									<div className="relative w-full">
										{message && (
											<div
												className={`absolute w-full mt-2 text-center ${
													message.type === 'error'
														? 'text-red-500'
														: 'text-green-500'
												}`}
											>
												{message.text}
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="text-center w-full mt-8">
								<button
									onClick={handleResendCode}
									className="underline text-mc_blue"
								>
									Resend code
								</button>
							</div>
							<hr className="mt-2 mb-4 border-black border-opacity-50" />
							<h2 className="w-full text-2xl font-bold mb-5 text-center text-purple-900">
								Reset Password
							</h2>
							<form onSubmit={handleResetPassword} ref={formRef}>
								<div className="mb-3">
									<input
										type="password"
										placeholder="Enter new Password here"
										onChange={handleInputChange}
										className={`w-full px-4 py-2 border-none  outline-none text-left rounded-lg text-black placeholder:text-center focus:placeholder:text-transparent  ${
											errors.password1
												? 'bg-red-500 bg-opacity-30'
												: 'bg-[#F3F5F7] placeholder:text-[#3D3E40]'
										}`}
										name="password1"
									/>
								</div>
								<div className="mb-3">
									<input
										type="password"
										onChange={handleInputChange}
										placeholder={
											errors.confirmPassword
												? 'Passwords do not match! Please re-enter!'
												: 'Re-Enter your Password'
										}
										className={`w-full px-4 py-2 border-none  outline-none text-left rounded-lg text-black placeholder:text-center focus:placeholder:text-transparent  ${
											errors.password2 || errors.confirmPassword
												? 'bg-red-500 bg-opacity-30'
												: 'bg-[#F3F5F7] placeholder:text-[#3D3E40]'
										} ${
											errors.confirmPassword ? 'placeholder:text-red-500' : ''
										}`}
										name="password2"
									/>
								</div>
								<button
									type="submit"
									className="relative bg-[#9DACFF] mt-8 text-center px-4 py-2 w-full flex justify-between rounded-md border border-mc_light_grey disabled:bg-mc_light_grey"
									disabled={disableButton}
								>
									<p className="flex-1 text-center text-black">
										Confirm new password
									</p>

									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={2}
										stroke="currentColor"
										className="size-6 absolute right-0 "
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="m8.25 4.5 7.5 7.5-7.5 7.5"
										/>
									</svg>
								</button>
							</form>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default AdminResetPassword;
