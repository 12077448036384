import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EntryDirection } from '../../../static/enums/strategiesEnums';
import { ExitType } from '../../../static/enums/exitTypeEnum';
import { OrderResult } from '../../../static/enums/orderEnums';

const ExitOrderRow = ({ item, index, isSelected, handleRowSelect }) => {
	const navigate = useNavigate();
	const orderTypeLabel =
		EntryDirection.find((type) => type.value === item.strategy.entryDirection)
			?.label || '-';

	const exitTypeLabel =
	Object.keys(ExitType).find((key) => ExitType[key] === item.exitType) ||
	'-';

  const resultLabel =
	Object.keys(OrderResult).find((key) => OrderResult[key] === item.result) ||
	'-';
	return (
		<div
			className={`flex items-center md:px-6 md:py-4 px-1 py-2 border md:my-4 my-2 border-grey-light rounded-full space-x-5 ${
				isSelected ? 'bg-gray-100' : ''
			}`}
		>
			<span className="tr_style w-1/7 text-gray-600 font-semibold">{index + 1}</span>
			<span
				className="tr_style w-1/7 cursor-pointer"
				onClick={() => navigate(`/customer/${item.customer.id}`)}
			>
				{item.customer.firstName} <br /> {item.customer.lastName}{' '}
			</span>
			<span
				className="tr_style w-1/7"
			>
				{item.transactionId}
			</span>
			<span className="tr_style w-1/7">{orderTypeLabel || '-'}</span>
			<span className="tr_style w-1/7">
				{item.datetimeInserted
					? (() => {
							const dateTimeString = new Date(
								item.datetimeInserted
							).toLocaleString('en-GB', {
								year: 'numeric',
								month: '2-digit',
								day: '2-digit',
								hour: '2-digit',
								minute: '2-digit',
								hour12: false,
							});
							const [date, time] = dateTimeString.split(', ');
							return (
								<>
									{date} <br /> {time}{' '}
								</>
							);
					  })()
					: '-'}
			</span>
			<span className="tr_style w-1/7">{exitTypeLabel}</span>
			<span className="tr_style w-1/7">-</span>
			{/* {resultLabel} */}
		</div>
	);
};

export default ExitOrderRow;
