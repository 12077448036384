import React from 'react';

const StatusChangeModal = ({ isOpen, onConfirm, onCancel }) => {
	if (!isOpen) return null;
	return (
		<div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30 font-poppins">
			<div className="bg-white rounded-lg h-auto w-3/4 md:w-1/2 xl:w-1/3 py-4 px-8 md:px-8">
				<div className="flex flex-col justify-center items-center p-6 md:px-10">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						fill="#D81E06"
						className="size-28"
					>
						<path
							fillRule="evenodd"
							d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
							clipRule="evenodd"
						/>
					</svg>
					<div className="text-center text-[#D81E06] font-semibold text-2xl">
						Are you sure you want to close this position manually? This action
						can’t be undone!
					</div>
					<div className="flex justify-between items-center w-full mt-20">
						<button
							className="px-8 py-2 bg-red-600 text-white font-semibold text-base rounded-lg shadow-lg"
							onClick={onCancel}
						>
							Cancel
						</button>
						<button
							className="px-8 py-2 bg-green-600 text-white font-semibold text-base rounded-lg shadow-lg"
							onClick={onConfirm}
						>
							Confirm
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StatusChangeModal;
